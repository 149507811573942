import { Component, OnInit, Input, ViewChild, AfterViewInit, OnChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person, mkEmptyPerson} from '../../../../../bo9-shared/models/person/person.model';
import { Difference } from '../../../../../bo9-shared/models/person/differences.model';
import { validationMessages } from 'src/helpers/form.helper';

import { EventDBService } from 'src/services/events/eventdb.service';
import { Event, EventCounts, AttendedLog, EventColor} from '../../../../../bo9-shared/models/event/event.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';

import { SearchService } from 'src/services/search/search.service';
import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';

import { TransportNative } from '../../../../../bo9-shared/models/mailCampaign/mailCampaign.model';

import { addDays, DateToYYMMDD, getMySQLDate, showTZ } from '../../../../../bo9-shared/helpers/time.helper';
import { MatDialog } from '@angular/material/dialog';
import { getMyEvents } from 'src/services/events/makeEventLists.service';

import { EmailListDialogComponent } from 'src/displayComponents/dialogs/emailListDialog/emailListDialog.component';
import { Member } from '../../../../../bo9-shared/models/member/member.model';
import { DeviceDetectorService} from 'ngx-device-detector'
@Component({
    selector: 'personal-events-list',
    templateUrl: './personal-events.component.html',
    styleUrls: ['./personal-events.component.scss'],
    standalone: false
})
export class PersonalEventsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() person: Person;
  @Input() registered: boolean;
  @Input() attended: boolean;
  @Input() eventList: Event[];
  @Output() notifyParent: EventEmitter<EventCounts> = new EventEmitter<EventCounts>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  messagesLoading$: Observable<boolean>;
  people$: Observable<Person[]>;
  totalFound: number=0;
  totalFound$: Observable<number>;
  pageNum$: Observable<number>;
  totalPages$: Observable<number>;
  pageSize$: Observable<number>;
  pageNum: number;
  totalPages: number;

  people: Person[];
  currentPane = 'center';
  valid: false;
  noAttendees = [];

  origp: Person;
  newp: Person;

  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  public displayedColumns = [ 'name', 'startDate','timezone','attended','emails'];
  public displayedRegisteredColumns = [ 'name', 'startDate','timezone','link','clientlink'];
  public dataSource = new MatTableDataSource<Event>();
  searchToString: string;
  searchFromString: string;
  currentP$: Observable<Person>;
  currentEvent$: Observable<Event>
  currentPerson: Person;
  RelateForm: FormGroup;
  eventCounts: EventCounts;
  relatingPersonName: string;
  totalEvents: number;
  eventNum = 0;
  event: Event;
  origP: Person;
  loading: boolean;
  newP: Person;
  difference: Difference;
  operation="Merge";
  lastPane: string;
  tagList: TransportNative;
  user:Person;
  validationMessages = validationMessages;
  events: Event[];
  pageSize: number=15;
  updated= false;

  subscriptions: Subscription[] = [];
  constructor(
    private eventdbService: EventDBService,
    private arangoService: ArangoService,
    private activeCampaignService: ActiveCampaignService,
    private searchService: SearchService,
    private auth: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private deviceService: DeviceDetectorService,
    ) 
  {


    this.makeForm();
    this.eventCounts = new EventCounts();
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'PersonalEventsComponent',
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}}));

    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #012');
      this.router.navigate(['login'] );
    }
    if (this.user.preferences && this.user.preferences.eventHistory && this.user.preferences.eventFutureDays) {
      this.searchFromString = DateToYYMMDD(addDays(new Date(), -this.user.preferences.eventHistory));
      this.searchToString = DateToYYMMDD(addDays(new Date(), +this.user.preferences.eventFutureDays));
    } else {
      this.searchFromString = DateToYYMMDD(addDays(new Date(), -60));
      this.searchToString = DateToYYMMDD(addDays(new Date(), +90));
    }
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }

  }

  ngOnDestroy() {
    if (this.subscriptions) this.subscriptions.forEach(s => s.unsubscribe());
  }

  showEmailList(event) {
    const dRef = this.dialog.open(EmailListDialogComponent, {
      width: '75%',
      height: '75%',
      data: {event: event, programs: this.user.programs}
    });

  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;


  }
  isMobile(): boolean {
    return this.deviceService.isMobile()
  }
  makeForm() {

  }

  onChangePage(event) {
    this.pageNum = event.pageIndex
    this.pageSize = event.pageSize;
  }

  ngOnChanges(): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.registered) {
      this.displayedColumns = this.displayedRegisteredColumns;
    }
    if ( this.eventList) {
      for (const e of this.eventList) {
        this.eventCounts.setEventCount(e);
      }
    }

    this.dataSource.data = this.eventList;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.loading = false;

  }

  showKey (key: String) {
	  alert(key);
  }

  loadEvents(all?: boolean) {
    console.log("PEC Load");
    if (this.person) {
      this.loading = true;
      this.dataSource.data = [];
      const eList: Event[] = [];
      console.log("Prefs "+JSON.stringify(this.user.preferences));
      if (this.user.preferences) {
        this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
      }

      const res = getMyEvents(this.person.primaryEmail, this.eventdbService, this.searchService)
      .then ( (eList) => {

          this.dataSource.data = eList;
          this.loading = false;
          this.updated = false;

          console.log('Events loaded');
          this.notifyParent.emit(this.eventCounts);
      }
     )
    }
  } 



  ngOnInit() {


  }

  onRefreshClick() {
    console.log('Refresh click ');
    this.activeCampaignService.tagAndFields.customFields = []; // force reload
    this.subscriptions.push( this.activeCampaignService.getContactTagFieldValues(false)
    .subscribe( (res) => {
        this.tagList = res;

        this.loadEvents(); // refreash

    }))

  }

	showTZ(tz: string){
		return showTZ(tz);
	}


}
