      <form [formGroup]="ResourceForm" (ngSubmit)="submitResourceForm()" class="Pearl-form" novalidate >
        <button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>
        <div >
          <mat-form-field appearance="outline"  >
            <mat-label>Key</mat-label>
            <input matInput  formControlName="_key"  />
           
          </mat-form-field>
        </div>
        <div >
          <mat-form-field appearance="outline" class="full-width" >
            <mat-label>Title</mat-label>
            <input matInput  formControlName="title" required />
           
          </mat-form-field>
        </div>
        <div>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Content</mat-label>
              <qeditor
                formControlName="description"
                placeholder="Type your wisdom here..."
                required
              ></qeditor>
             </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" >
            <mat-label>Natural Numbers?</mat-label>
            <mat-select matNativeControl   multiple formControlName="aboutNN" required>
              <mat-option *ngFor="let nnI of nnKeys" [value]="nnI">
                {{ nnI }}
              </mat-option>
            </mat-select>

            </mat-form-field>
            <mat-form-field appearance="outline" >
              <mat-label>Resource type</mat-label>
              <mat-select matNativeControl   formControlName="resourceType" required multiple>
                <mat-option *ngFor="let nnI of resourceType" [value]="nnI">
                  {{ nnI }}
                </mat-option>
              </mat-select>
  
              </mat-form-field>
              <mat-form-field appearance="outline" >
                <mat-label>Category</mat-label>
                <mat-select matNativeControl   formControlName="resourceCategories" required multiple>
                  <mat-option *ngFor="let nnI of categories" [value]="nnI">
                    {{ nnI }}
                  </mat-option>
                </mat-select>
    
                </mat-form-field>
                <mat-form-field appearance="outline" >
                  <mat-label>For or greater than</mat-label>
                  <mat-select matNativeControl   formControlName="eventCategories" required >
                    <mat-option *ngFor="let nnI of programs" [value]="nnI">
                      {{ nnI }}
                    </mat-option>
                  </mat-select>
      
                  </mat-form-field>
                <mat-form-field appearance="outline" >
                  <mat-label>URL/Location</mat-label>
                  <input matInput  formControlName="url"  />
                 
                </mat-form-field>
                
              </div>
              <span *ngIf="resource?.fileInfo && resource.fileInfo[0]">Current File {{ resource.fileInfo[0].Location }}<br /></span>
              
              Add File: 
              <mat-form-field>
                <ngx-mat-file-input2 [formControl]="fileControl" >
                </ngx-mat-file-input2>
             </mat-form-field>
             <span *ngIf="resource?.fileInfo && resource.fileInfo[0]"> <button mat-stroked-button (click)="delImage()">Delete File</button></span>
             <br />
            <button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>    
            <div>
          </div>
      </form>

   