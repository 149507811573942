import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild,  OnDestroy, AfterViewInit } from '@angular/core';

import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';

import { WooCommerceService } from 'src/services/woocommerce/woocommerce.service';
import { WooCustomer } from '../../../../../bo9-shared/models/woocommerce/woocustomer.model';
import { WooOrder } from '../../../../../bo9-shared/models/woocommerce/wooorder.model';
import { WooDownload, WooDownloadInfo } from '../../../../../bo9-shared/models/woocommerce/woodownload.model';
import { firstValueFrom, Subscription } from 'rxjs';
import { AuthService } from 'src/services/auth/auth.service';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Router } from '@angular/router';

@Component({
    selector: 'order-list',
    templateUrl: './orderList.component.html',
    styleUrls: ['./orderList.component.scss'],
    standalone: false
})
export class OrderListComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() customers: WooCustomer[];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public displayedColumns = ['id','created', 'completed', 'name', 'email','items', 'status','total','links'];
  public dataSource = new MatTableDataSource<WooOrder>();

  subscriptions: Subscription[] = [];
 loading = false;
 user: Person;
 pageSize: number;

  constructor(
    private wooService: WooCommerceService,
    private auth: AuthService,
    private router: Router,
    private arangoService: ArangoService,
  ) {
    console.log('Order construct ' + JSON.stringify(this.customers));
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'OrderListComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  makeForm() {
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  loadOrders(): Promise<any> {
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
    
    return new Promise( async resolve =>{ 
      this.loading = true;
      this.dataSource.data = [];
      console.log('Order load ' + JSON.stringify(this.customers)); 
      const olist: WooOrder[] = [];
      let custList = this.customers;

      if (!Array.isArray(this.customers)) { // Convert it for profile/Orders
        custList = [this.customers];
      }
      for (const c of this.customers) {

        let downs = [];
        if (!c.order_info) {
          let ods = [];
          const res = await firstValueFrom(this.wooService.loadOrdersByCustomer(c.id));
          ods = res.content;
          console.log('order purchases '+ JSON.stringify(c))
          const resd = await firstValueFrom(this.wooService.loadDownload(c.id));
          downs = resd.content;
          this.wooService.setDownloads(downs);
          for (const o of ods) {
            o.orderer = c.first_name + ' ' + c.last_name
            const tt: string[]= [];
            for (const t of o.line_items) {
              tt.push(t.name);
            }
            o.calendly = this.wooService.searchDownloads(o.id);
            o.tooltips = tt.join(',');
            olist.push(o);
          }
        } else {
          let ods = c.order_info;

          ods.orderer = c.order_info.billing.first_name + ' ' + c.order_info.billing.last_name
          ods.calendly = [createDownloadFromInfo(c.download_info)];
          const tt: string[]= [];
            for (const t of c.order_info.line_items) {
              tt.push(t.name);
            }
            ods.tooltips = tt.join(',');
          olist.push(ods);
        }
        
      }
      this.dataSource.data = olist;
      console.log('Orders loaded '+JSON.stringify(olist)); 
      this.loading = false;
      resolve(true);
    });

  }


  ngOnInit() {
    console.log('Orders List oninit'); 
    if (this.customers && this.customers.length > 0) {
      console.log('cust size '+this.customers.length);
      this.loadOrders().then ();
    }
  }

  onRefreshClick() {
    console.log('Orders Load'); 
    this.loadOrders().then ();
  }

  onPageChange(event){
    console.log('Orders List page change'); 
  }


  onClickExpand(p:WooOrder) {
    console.log('Expand Order '+ JSON.stringify(p));
  }


  onClickView($event: any) {
  }
}

function createDownloadFromInfo(i: WooDownloadInfo) : WooDownload {
   const email = i.user_email.replace('@','%40');
    return ({
      download_id: i.download_id,
      download_url: 'https://bodyof9.com/?download_file='+i.product_id+'&order=' + i.order_key + '&email='+email+'&key='+i.download_id,
      product_id: i.product_id,
      product_name: '',
      download_name: 'Link',
      order_id: i.order_id,
      order_key : i.order_key,
      downloads_remaining : ''+i.downloads_remaining,
      access_expires: i.access_expires,
      access_expires_gmt: i.access_expires,
      file: null
    }
    )
}
