import { Component, OnInit, ChangeDetectionStrategy, Input,  ViewChild, OnChanges, Output, EventEmitter, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { FormRelation, Relation} from '../../../../../bo9-shared/models/person/relation.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';


import { EventEmitterService } from 'src/services/events/events.service';

import { NaturalNumber, NaturalNumberTypes } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { dateFormating, DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { Resource, ResourceSearch } from '../../../../../bo9-shared/models/resource/resource.model'
import { userIsAdmin, userIsJourneyman } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { SystemVariablesService } from 'src/services/systemvariables/systemvariables.service';
import { EventDBService } from 'src/services/events/eventdb.service';
import { EspressoEventCategory } from '../../../../../bo9-shared/models/event/event9e.model';
import { DeviceDetectorService} from 'ngx-device-detector'
@Component({
    selector: 'partner-resource-list',
    templateUrl: './partnerResourceList.component.html',
    styleUrls: ['./partnerResourceList.component.scss'],
    standalone: false
})
export class PartnerResourceListComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() person: Person;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() notifyParent: EventEmitter<Resource> = new EventEmitter<Resource>();
  public displayedAdminColumns = ['edit',  'title', 'aboutNN', 'eventCategories', 'resourceType','resourceCategories','dateCreated','delete'];
  public displayedColumns = [ 'title', 'aboutNN', 'eventCategories','resourceType','resourceCategories','dateCreated'];
  public displayedNarrowColumns = [ 'title', 'aboutNN', 'resourceType'];
  public dataSource = new MatTableDataSource<Resource>();


  pList : Resource[] =[];
  messagesLoading$: Observable<boolean>;

  currentPane = 'center';
  valid: false;

  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;

  currentPerson: Person;
  loading = false;
  fieldValues = 0;
  relations: FormRelation[] =[];
  SearchForm: FormGroup;
  validationMessages = validationMessages;
  ResourceCount = 0;

  selectedResource$ : Observable<Resource>;

  ResourceModuleTypes$: Observable<string[]>;
  ResourceModuleTypes: string[]
  nnKeys: string[];
  nnSource: string[];
  invites: string[];
  onlyApproved: boolean = null;
  onlyNotApproved: boolean = null;
  allResources: boolean = true;
  pageSize: number;
  programs: string[];
  showForm=false;
  user: Person;
  disableBtn = false;
  currentResourcePane = 'center';
  subscriptions: Subscription[] = [];
  resourceTypes: string[] = [];
  resourceCategories: string[] = [];
  resourceSearch: ResourceSearch =null;
  catListDB: string[] = [];
  fullCategories: EspressoEventCategory[] = [];
  screenHeight: any;
  screenWidth: any;
  constructor(
    private router: Router,
    private arangoService: ArangoService,
    private eventdbService: EventDBService,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private emitterService: EventEmitterService,
    private systemVariablesService: SystemVariablesService,
    private deviceService: DeviceDetectorService,
    )
  {
    this.user = this.auth.getAuthUser();

 
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'PartnerResourceListComponent',
      visitor: this.user._key,
      help: false
    }).subscribe ({next: async () => {
      this.resourceTypes = await firstValueFrom(this.systemVariablesService.getSystemVariableArray('Resource Types'));
      this.resourceCategories = await firstValueFrom(this.systemVariablesService.getSystemVariableArray('Resource Categories'));
    }}));
    if (userIsAdmin(this.user)) {
      this.displayedColumns = this.displayedAdminColumns;
    } 
    if (this.isMobile()) {
      this.displayedColumns = this.displayedNarrowColumns;
    };
    this.currentPane = 'center';

    this.nnKeys = Object.keys(NaturalNumber).map(key => NaturalNumber[key]).filter(value => typeof value === 'string') as string[];
    this.SearchForm = this.formBuilder.group({
      content: new FormControl('', Validators.compose([])),
      naturalNumbers: new FormControl('', Validators.compose([])),
      resourceTypes: new FormControl('', Validators.compose([])),
      resourceCategories: new FormControl('', Validators.compose([])),
      eventCategories: new FormControl('', Validators.compose([])),
    });
    this.subscriptions.push(this.eventdbService.getCategories()
    .subscribe( async (res) => {
      console.log('get categories ' + JSON.stringify(res));
      this.fullCategories = res.content;
      for (const cat of res.content as EspressoEventCategory[]) {
        this.catListDB.push(cat.category_name);
      };
      const progArray:any[] = await firstValueFrom(this.systemVariablesService.getSystemVariableArray('Programs Allowed Events'));
      this.programs = [];

      if (progArray) {
        for (const p of progArray) {
          this.programs.push(p.programName)
        }
       }

    }))
    if (!this.person) {
      this.person === this.user;
    }
  }

  isMobile(): boolean {
    return this.deviceService.isMobile()
  }

  ngOnDestroy() {
    if (this.subscriptions) this.subscriptions.forEach(s => s.unsubscribe());
  }

  isAdmin(): boolean {
    return userIsAdmin(this.user);
  }

	dateFormating(date: any, kind: string) {
		return dateFormating(date, kind);
	}
  ngOnChanges() {
    if (!this.person) {
      this.person === this.user;
    }
    this.loading = false;


  }

  loadResources(search: ResourceSearch) {
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
    console.log('Get Resources');
      this.subscriptions.push( this.arangoService.getResources(search)
      .subscribe( async (result1) => {
        this.pList = [];
        if (result1) {
          const roles = await firstValueFrom(this.arangoService.getRoles(this.person));
          const systemRoles = roles && roles.content[0]? roles.content[0].systemRoles: [];
          let isJourneyman=false;
          let isSeniorJ = false;
          let isMaster = false;
          let isApprentice = false;
          for (const role of systemRoles ) {
            if (role.includes('Journeyman')) { isJourneyman = true; };
            if (role.includes('Apprentice')) { isApprentice = true; };
            if (role.includes('Master')) {isMaster= true;};
            if (role.includes('Admin')) {  isMaster = true; };
            if (role.includes('SeniorJ')) {isSeniorJ= true;};  // Partner = marketing partner now
          }
          for (const resource of result1.content as Resource[]) {
            const tmpresource: any = resource;
            if (resource.fileInfo && tmpresource.fileInfo == "undefined"){
              resource.fileInfo = null;
            }
            let found = false;
            if( !userIsAdmin(this.person)) { // Only show all if person being checked is admin
              
              if (this.person) {
                if (isApprentice && resource.eventCategories.includes('Apprentice') ) { // Only push events this person can attend
                  this.pList.push(resource);

                }
                  if (isJourneyman && (resource.eventCategories.includes('Journeyman') || resource.eventCategories.includes('Marketing')) ) { // Only push events this person can attend
                    this.pList.push(resource);

                  } 
                  if (isSeniorJ &&  (resource.eventCategories.includes('Journeyman') || resource.eventCategories.includes('Marketing')  || resource.eventCategories.includes('SeniorJ') )) {
                      this.pList.push(resource);

                  }
                  if (isMaster &&  (resource.eventCategories.includes('Master') || resource.eventCategories.includes('Journeyman') || resource.eventCategories.includes('Marketing')  || resource.eventCategories.includes('SeniorJ') )) {
                    this.pList.push(resource);

              }
              }
            } else {  // admin sees all
              this.pList.push(resource);
            }
        }

          this.dataSource.data = this.pList;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;

        }
      }))
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  onClickEdit(p:Resource) {
    this.selectedResource$ = of(p);
    this.showForm = true;
  }


  makeForm() {

  }


  ngOnInit() {
    //this.loadRelations();
    this.loadResources(this.resourceSearch);
  }


  onClickRefresh() {
    console.log("refresh");

    this.loadResources(this.resourceSearch);
  }
  onPageChange(event){
    console.log(event);
  }

  onCloseClick() {
    this.showForm=false;
  }

  onAddClick() {
    this.selectedResource$ = of(null);
    this.showForm=true;
  }


  onClickDelete(p: Resource) {
    if (confirm(`Please confirm deletion of this ${p.title} Resource`)) {

        this.subscriptions.push( this.arangoService.deleteResource(p)
        .subscribe( (res) => {
          console.log('Save result  is: ' + JSON.stringify(res.content));
          this.emitterService.emitter.emit('Message','Resource Deleted ' + p.title);
          if (this.user.preferences && this.user.preferences.autoRefresh) {
            this.loadResources(this.resourceSearch);
          }
        }))
      }


  }


  ResourceSubmittedHandler($event: any) {
    console.log(' Resource  submitted '+ JSON.stringify($event));
    this.showForm=false;
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.loadResources(this.resourceSearch);

    }
  }

  submitSearchForm() {
    console.log('Search Resource form ' + JSON.stringify(this.SearchForm.value));
    if (this.SearchForm.valid) {
      console.log('Search Resource form - valid');
      let dStart ='';
      let dEnd = '';

      if ( this.SearchForm.value.startDate)  {
        dStart = DateToYYMMDDhhmmss(this.SearchForm.value.startDate._d);
      }

      if (this.SearchForm.value.endDate) {
        dEnd = DateToYYMMDDhhmmss(this.SearchForm.value.endDate._d)
      }
      let approved = null;

      if (this.onlyNotApproved) {
        approved = false;
      } else if (this.onlyApproved) {
        approved = true;
      } // otherwise leave null

      this.resourceSearch = {
        search: this.SearchForm.value.content,
        naturalNumbers: this.SearchForm.value.naturalNumbers,
        resourceTypes: this.SearchForm.value.resourceTypes,
        resourceCategories: this.SearchForm.value.resourceCategories,
        eventCategories: this.SearchForm.value.eventCategories
      }
      if (this.resourceSearch.naturalNumbers[0] as any == "") {
        this.resourceSearch.naturalNumbers = [];
      }
      this.loadResources(this.resourceSearch)



    } else {
      console.log('Submit Resource Search form - Not valid');
      alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.SearchForm)));

    }
  }

}
