import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';


import { Person } from '../../../../../bo9-shared/models/person/person.model'
import { Router } from '@angular/router';
import { BO9UUID } from '../../../../../bo9-shared/models/UUID.model';

import { webAppVersion } from 'src/config/webapp.config';
import { LoginService } from 'src/services/loginservice/login.service';


@Component({
    selector: 'web-footer',
    templateUrl: './webfooter.component.html',
    styleUrls: ['./webfooter.component.scss'],
    standalone: false
})
export class WebFooterComponent implements OnInit {

  user: Person;

  version = webAppVersion;

  selectedOrg: BO9UUID;

  constructor(
    private auth: AuthService,
    private router: Router,

    private loginService: LoginService
  ) { }

  ngOnInit() {
    console.log('Init footer ');
   // this.user = this.auth.getAuthUser();
  }


}
