import { Component,  OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy} from '@angular/core';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { EventEmitterService } from 'src/services/events/events.service';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { AuthService } from 'src/services/auth/auth.service';
import { Word32Bit } from '../../../../../bo9-shared/helpers/bit.helper';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

// todo replace this with BO9Contact


@Component({
    selector: 'sysroles-form',
    templateUrl: './sysrolesForm.component.html',
    styleUrls: ['./sysrolesForm.component.scss'],
    standalone: false
})
 
export class SysRolesFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() person: Person;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();

  master: boolean=false;
  junior: boolean=false;
  admin: boolean=false;
  senior: boolean = false;
  apprentice: boolean = false;
  disableBtn = false;
  changed = false;
  subscriptions: Subscription[] = [];
  systemRoles: string[] = [];
  user: Person;
  constructor(private arangoService: ArangoService,    
    private auth: AuthService, 
    private router: Router, 
        private emitterService: EventEmitterService) 
  {
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'SysRolesFormComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 

  }

  clickMaster() {
    this.changed = true;
    this.master = !this.master;
  }

  clickSenior() {
    this.changed = true;
    this.senior = !this.senior;
  }

  clickAdmin() {
    this.changed = true;
    this.admin = !this.admin;
  }

  clickJourneyman() {
    this.changed = true;
    this.junior = !this.junior;
  }


  clickApprentice() {
    this.changed = true;
    this.apprentice = !this.apprentice;
  }

 ngOnInit() {

 }


 ngOnDestroy() {
  this.subscriptions.forEach(s => s.unsubscribe());
}


 onSubmit() {
   if (this.changed){
    this.systemRoles = [];

    if (this.admin) {
      this.systemRoles.push("Admin");
    }
    if (this.senior) {
      this.systemRoles.push("SeniorJ");
    }
    if (this.master) {
      this.systemRoles.push("Master");
    }
    if (this.junior) {
      this.systemRoles.push("Journeyman");
    }
     
    if (this.apprentice) {
      this.systemRoles.push("Apprentice");
    }

    this.subscriptions.push( this.arangoService.updateRoles(this.person,this.systemRoles)
    .subscribe({next: () => {
      this.master=false;
      this.junior=false;
      this.admin=false;
      this.senior= false;
      this.notifyParent.emit('Roles Saved');
 
    }}))
   } else {

    this.notifyParent.emit('No change');
   }

 }

  ngOnChanges() {
    console.log("CHANGE SR");
    this.subscriptions.push( this.arangoService.getRoles(this.person)
    .subscribe({next: (roles: any) => {

      if (roles && roles.content && roles.content.length > 0) {
        this.master = false;
        this.senior = false;
        this.junior=false;
        this.admin = false;
        this.apprentice = false;
        for (const role of roles.content) {
          if (role.systemRoles.includes('Journeyman')) {this.junior = true;};
          if (role.systemRoles.includes('Admin')) {this.admin = true;};
          if (role.systemRoles.includes('Master')) {this.master = true;};
          if (role.systemRoles.includes('SuperAdmin')) {this.admin = true;};
          if (role.systemRoles.includes('SeniorJ')) { this.senior = true; };
          if (role.systemRoles.includes('Apprentice')) {this.apprentice = true;};
        }
      }
    }}))
   
  }
  
}