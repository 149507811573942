import { Component, forwardRef, ChangeDetectionStrategy, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { validationMessages } from 'src/helpers/form.helper';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { AuthService } from 'src/services/auth/auth.service';
import { PhoneContactItem, PhoneContactType } from '../../../../../bo9-shared/models/contact/phone.model';
import { Person } from '../../../../../bo9-shared/models/person/person.model';

// todo replace this with BO9Contact

export interface PhoneFormValues {
  DNC: string;
  items: PhoneContactItem[]
}

@Component({
    selector: 'phone-form',
    templateUrl: './phoneForm.component.html',
    styleUrls: ['./phoneForm.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneFormComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PhoneFormComponent),
            multi: true,
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
  export class PhoneFormComponent implements OnInit ,ControlValueAccessor, OnDestroy {
    form: FormGroup = this.formBuilder.group({
      DNC: ['', Validators.compose([Validators.required])],
      items: this.formBuilder.array([])
    });
    subscriptions: Subscription[] = [];
    validationMessages = validationMessages;
    contactTypes;
    user: Person;

    constructor(
      private formBuilder: FormBuilder, 
      private arangoService: ArangoService,
      private router: Router,     
      private auth: AuthService,
      private cdr: ChangeDetectorRef) {
      console.log('Phone Init '+ JSON.stringify(this.value));
     this.contactTypes = Object.keys(PhoneContactType).map(key => PhoneContactType[key]).filter(value => typeof value === 'string') as string[];
     this.user = this.auth.getAuthUser();
      this.subscriptions.push(
        this.form.valueChanges.subscribe(value => {
         // console.log('Phone valuechange '+ JSON.stringify(value));
          this.onChange(value);
          this.onTouched();
        })
      );
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'PhoneFormComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
    }

    selectDNC() {
     // console.log('dnc '+ JSON.stringify(this.form.value.DNC));
      this.form.controls.DNC.setValue(!this.form.value.DNC);
    //  console.log('dnc'+ JSON.stringify(this.form.value.DNC));
    }

    selectPrimary(element: any) {
      console.log('check pri '+JSON.stringify(element));
      
      if (this.items.length > 0) {
          
        for (let i=0; i<this.items.length; i++ ) {
          const val = this.items.at(i);
          const p = val.value.primary;
          if (element === i) {
            val.patchValue({primary: !p});
          } else {
            val.patchValue({primary: false})
          }
          this.items.setControl(i, val);
        }
      }
    }

    ngOnInit() {
    }
  
    get value(): PhoneFormValues {
      console.log('Phone get '+ JSON.stringify(this.form.value));
      return this.form.value;
    }
  
    set value(value: PhoneFormValues) {
      console.log('Phone set '+ JSON.stringify(value));
      this.items.clear();
      for(const item of value.items) {
        console.log('Phone push '+ JSON.stringify(item));
        this.addPhoneItem();
      }
      this.form.patchValue(value);
      this.cdr.detectChanges();
    }

    get DNCControl() { return this.form.controls.DNC;}  
    items: FormArray = this.form.get('items') as FormArray;
  
    newPhoneItem() : FormGroup {
      return this.formBuilder.group( {
        primary: ['', Validators.compose([])],
        type: ['', Validators.compose([])],
        status: ['', Validators.compose([])],
        number: ['', Validators.compose([Validators.required])],
        countryCode:  ['', Validators.compose([])],
      })
    }

    addPhoneItem() {
      this.items.push( this.newPhoneItem());
    }

    removePhoneItem(i: number) {
      if (confirm('Please confirm delete')) {
        if (this.items.length !== 1) {
          this.items.removeAt(i);
        }
      }
    }

    ngOnDestroy() {
      console.log('Phone destroy ');
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  
    onChange: any = () => {

    };
    onTouched: any = () => {};
  
    registerOnChange(fn) {
      this.onChange = fn;
    }
  
    writeValue(value) {
      console.log('Phone write value ' + JSON.stringify(value));
      if (value) {
        this.value = value;
      }
  
      if (value === null) {
        this.form.reset();
      }
    }
  
    registerOnTouched(fn) {
      this.onTouched = fn;
    }
  
    validate(_: FormControl) {
      console.log('phone valid get '+ JSON.stringify(this.form.valid));
      return this.form.valid ? true : false;
    }
  
    reset() {
      this.form.reset();
    }
  }