import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { AuthService } from 'src/services/auth/auth.service';
import { Person } from '../../../../bo9-shared/models/person/person.model';


import { PearlsMap } from './pearlsmap';


interface output {
  relationship: string,
  one:number,
  two:number,
  three:number,
  four:number,
  five:number,
  six:number,
  seven:number,
  eight:number,
  nine:number,
  any:number,
  totals:number
}
@Component({
    selector: 'pearls-info',
    templateUrl: './pearlsinfo.component.html',
    styleUrls: ['./pearlsinfo.component.scss'],
    standalone: false
})

export class PearlsInfoComponent implements OnInit, OnDestroy, OnChanges {
  @Input() pCount: any;


  total = 0;
  pearls : Map<string, PearlsMap>;

  public displayedColumns =  ['relationship','one','two','three','four','five','six','seven','eight','nine','totals'];

  public dataSource = new MatTableDataSource<output>();
  subscriptions: Subscription[] = [];
  user: Person;
  constructor(        private auth: AuthService,
    private router: Router,
    private arangoService: ArangoService,
    ) { 
    this.pearls = new Map();
    this.dataSource.data = [];
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'PearlsInfoComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    
     
  }

  ngOnInit() {
    if (!this.pCount) {
      this.subscriptions.push( this.arangoService.countPearls()
      .subscribe(  (result) => {
        this.pCount =  result.content;
      })
      );
    }
  }
  

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async ngOnChanges() {

    try {
      if (!this.pCount) {
        const pc = await firstValueFrom(this.arangoService.countPearls());
          this.pCount =  pc.content;
      }
      if (this.pCount && this.pCount.pearls) {
        const op: output[] = [];
        // Create a PearlsMap for each relationship for Pearls
        for (const pInfo of this.pCount.pearls) {
          if (pInfo.prel) {
            for (const relateTo of pInfo.prel) {
              let pMap:PearlsMap;
              pMap = this.pearls.get(relateTo);
              if (!pMap) {
                pMap = new PearlsMap;
                this.pearls.set(relateTo, pMap)
              }
              pMap.add(pInfo);
            }
          }

  
  
        }
        console.log('P count ');
        this.total = 0;
        for (const pMap of Array.from(this.pearls)) {
          const output: output = {
            relationship: pMap[0],
            one:pMap[1].pearlMap[0],
            two:pMap[1].pearlMap[1],
            three:pMap[1].pearlMap[2],
            four:pMap[1].pearlMap[3],
            five:pMap[1].pearlMap[4],
            six:pMap[1].pearlMap[5],
            seven:pMap[1].pearlMap[6],
            eight:pMap[1].pearlMap[7],
            nine:pMap[1].pearlMap[8],
            any:pMap[1].pearlMap[9],
            totals:pMap[1].pearlMap.reduce((a,b)=> {return a+b},0)
          }
          this.total += output.totals;
          
         op.push(output as any );
        }
        this.dataSource.data=op;
      }
    } catch (err) {
      console.error(err);
    }
    console.log('data '+JSON.stringify(this.dataSource.data));
    }
}
