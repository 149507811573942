import { Component, forwardRef, ChangeDetectionStrategy, OnDestroy, OnInit, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { validationMessages } from 'src/helpers/form.helper';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { AuthService } from 'src/services/auth/auth.service';
import { SystemVariablesService } from 'src/services/systemvariables/systemvariables.service';

import { SocialMediaType} from '../../../../../bo9-shared/models/contact/contact.model';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
export interface SocialFormValues {
  items: SocialMediaType[]
}

@Component({
    selector: 'social-form',
    templateUrl: './socialForm.component.html',
    styleUrls: ['./socialForm.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SocialMediaFormComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SocialMediaFormComponent),
            multi: true,
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})

  export class SocialMediaFormComponent implements OnInit, OnDestroy, ControlValueAccessor, OnDestroy {
    form: FormGroup = this.formBuilder.group({
      items: this.formBuilder.array([])
    });
    subscriptions: Subscription[] = [];
    validationMessages = validationMessages;
    user: Person;
    companyNames: string[];
    constructor(private formBuilder: FormBuilder,     private auth: AuthService, 
      private router: Router,  
      private arangoService: ArangoService,
      private systemVariablesService: SystemVariablesService,
      private cdr: ChangeDetectorRef) {
      console.log('Social Init '+ JSON.stringify(this.value));
      this.user = this.auth.getAuthUser();

      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'SocialFormComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
      this.subscriptions.push(this.systemVariablesService.getSystemVariableArray('Social Media Companies').subscribe ({next: (result) => {
        this.companyNames = result;
        console.log('names'+ JSON.stringify(this.companyNames));
      }})); 
      this.subscriptions.push(
        this.form.valueChanges.subscribe(value => {
         // console.log('Social valuechange '+ JSON.stringify(value));
          this.onChange(value);
          this.onTouched();
        })
      );
    }

    ngOnInit() {
    }
  
    get value(): SocialFormValues {
      console.log('Social get '+ JSON.stringify(this.form.value));
      return this.form.value;
    }
  
    set value(value: SocialFormValues) {
      console.log('Social set '+ JSON.stringify(value));
      console.log('Social length '+ JSON.stringify(this.items.length));
      this.items.clear();

      if (value.items && value.items[0] === null && value.items[1]) {
        const res = value.items.splice(0,1); // remove null first Social
      }
      for(const i in value.items) {
          console.log('Social push '+ JSON.stringify(value.items[i]));
          this.items.push( this.newSocialItem());
      }
      console.log('Social length after '+ JSON.stringify(this.items.length));
      this.form.patchValue(value);
      this.cdr.detectChanges();
    }

    items: FormArray = this.form.get('items') as FormArray;
  
  
    newSocialItem() : FormGroup {
      return this.formBuilder.group( {
        company: ['', Validators.compose([Validators.required])],
        handle: ['', Validators.compose([Validators.required])],
      });
    }
    addSocialItem() {
      this.items.push( this.newSocialItem());
    }

    removeSocialItem(i: number) {
      if (confirm('Please confirm delete')) {
        if (this.items.length !== 1) {
          this.items.removeAt(i);
        }
      }
    }

    ngOnDestroy() {
      console.log('Social destroy ');
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  
    onChange: any = () => {

    };
    onTouched: any = () => {};
  
    registerOnChange(fn) {
      this.onChange = fn;
    }
  
    writeValue(value) {
      console.log('Social write value ' + JSON.stringify(value));
      if (value) {
        this.value = value;
      }
  
      if (value === null) {
        this.form.reset();
      }
    }
  
    registerOnTouched(fn) {
      this.onTouched = fn;
    }
  
    validate(_: FormControl) {
      console.log('Social valid get '+ JSON.stringify(this.form.valid));
      return this.form.valid ? true : false;
    }
  
    reset() {
      this.form.reset();
    }
  }