import { Component, forwardRef, ChangeDetectionStrategy, OnDestroy, OnInit, Input, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { validationMessages } from 'src/helpers/form.helper';
import { NaturalNumber, NaturalNumberType } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Router } from '@angular/router';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { AuthService } from 'src/services/auth/auth.service';
import { UseExistingWebDriver } from 'protractor/built/driverProviders';


@Component({
    selector: 'source-form',
    templateUrl: './sourceForm.component.html',
    styleUrls: ['./sourceForm.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SourceFormComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SourceFormComponent),
            multi: true,
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
  export class SourceFormComponent implements ControlValueAccessor, OnDestroy  {
    nnKeys = Object.keys(NaturalNumber).map(key => NaturalNumber[key]).filter(value => typeof value === 'string') as string[];
    form: FormGroup;
    subscriptions: Subscription[] = [];
    validationMessages = validationMessages;
    identifiers: string[] = [];
    user: Person;
    get value(): NaturalNumberType {
      console.log('Source nn get '+ JSON.stringify(this.form.value));
      return this.form.value;
    }
  
    set value(value: NaturalNumberType) {
      console.log('nn set '+ JSON.stringify(value));
      let nn= '';

      if (value.naturalNumber) {
        if (typeof value.naturalNumber === 'string') {
          nn = value.naturalNumber
        } else {
          nn = (value.naturalNumber as number).toString();
        }
      } else {
        nn = "None"
      }

      this.form.controls.setAt.setValue(value.setAt);
      if (value.setBy && value.setBy.name) {
        let iders=[];
        let idString="";
        if (typeof(value.setBy) === "string") {
          idString = value.setBy;
        } else if (value.setBy.name) {
          idString = value.setBy.name;
        }
        if (typeof(idString) === "string") {
          if (idString.toLowerCase().includes('and')) {
            iders = idString.split("and");
          } else {
            iders = idString.split(",");
          }
        }
        this.form.controls.setBy.setValue(iders);
      }

      this.form.controls.dateSet.setValue(value.dateSet);
      this.form.controls.naturalNumber.setValue(nn);
      this.onChange(value);

      this.onTouched();
    }

    get setAtControl() { return this.form.controls.setAt;}
    get setByControl() { 
      return this.form.controls.setBy;
    }
    get naturalNumberControl() { return this.form.controls.naturalNumber;}
    get dateSetControl() { return this.form.controls.dateSet;}

    constructor(    private router: Router,private arangoService: ArangoService,
      private auth: AuthService,
      private formBuilder: FormBuilder) {
      this.form = this.formBuilder.group({
        setAt: ['', Validators.compose([])],
        setBy: [[], Validators.compose([])],
        naturalNumber: ['', Validators.compose([])],
        dateSet: ['', Validators.compose([])]
      });
      this.user = this.auth.getAuthUser();
      /*
      // 08-27-2023 too many calls to this.
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'SourceFormComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
      */
      this.subscriptions.push(this.arangoService.getIdentifiers()
        .subscribe( (res) => {
          console.log('get identifers ' + JSON.stringify(res));
          for (const cat of res.content) {
            this.identifiers.push(cat.Name);
          }  
        })
        );
      this.subscriptions.push(
        this.form.valueChanges.subscribe(value => {
            this.onChange(value);
            this.onTouched();
        })
      );
    }

  
    ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
      this.form.reset();
    }
  
    onChange: any = () => {
      console.log('changed ' );
    };
    onTouched: any = () => {};
  
    registerOnChange(fn) {
      this.onChange = fn;
    }
  
    writeValue(value) {
      if (value) {
        this.value = value;
      }
  
      if (value === null) {
        this.form.reset();
      }
    }
  
    registerOnTouched(fn) {
      this.onTouched = fn;
    }
  
    validate(_: FormControl) {
      return this.form.valid ? true : false;
    }
  
    reset() {
      this.form.reset();
    }
      }