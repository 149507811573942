import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnDestroy, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormGroup} from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { Difference } from '../../../../../bo9-shared/models/person/differences.model';
import { validationMessages } from 'src/helpers/form.helper';
import { EventDBService } from 'src/services/events/eventdb.service';
import { Event, EventAttendee} from '../../../../../bo9-shared/models/event/event.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';

import { SearchService } from 'src/services/search/search.service';


import { EventEmitterService } from 'src/services/events/events.service';
import { addDays, DateToYYMMDD, DateToYYMMDDhhmmss, OnlyTimePart, showTZ } from '../../../../../bo9-shared/helpers/time.helper';
import { canContact } from '../../../../../bo9-shared/models/contact/contact.model';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { SystemVariablesService } from 'src/services/systemvariables/systemvariables.service';
import { Member } from '../../../../../bo9-shared/models/member/member.model';
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import * as dayjs from "dayjs";
@Component({
    selector: 'upcoming-events-list',
    templateUrl: './upcoming-events.component.html',
    styleUrls: ['./upcoming-events.component.scss'],
    standalone: false
})
export class UpcomingEventsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() person: Person;
  @Input() eventList: Event[];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() notifyEvents: EventEmitter<{}> = new EventEmitter<{}>();
  messagesLoading$: Observable<boolean>;
  people$: Observable<Person[]>;
  totalFound: number=0;
  totalFound$: Observable<number>;
  pageNum$: Observable<number>;
  totalPages$: Observable<number>;
  pageSize$: Observable<number>;
  pageNum: number;
  showICS: boolean = false;
  totalPages: number;
  disableBtn = false;
  people: Person[];
  currentPane = 'center';
  valid: false;
  noAttendees = [];

  origp: Person;
  newp: Person;

  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;
  google: string = "";
  outlook: string = '';
  office365: string = '';
  yahoo: string = '';
  ics: string = '';
  searchToString: string;
  searchFromString: string;

  public displayedColumns = ['name', 'startDate','timezone', 'register', 'link'];
  public dataSource = new MatTableDataSource<Event>();

  currentP$: Observable<Person>;
  currentEvent$: Observable<Event>
  currentPerson: Person;
  RelateForm: FormGroup;
  attendees: EventAttendee[] =[];

  relatingPersonName: string;
  totalEvents: number;
  eventNum = 0;
  origP: Person;
  loading: boolean;
  newP: Person;
  subscriptions: Subscription[] = [];
  difference: Difference;
  operation="Merge";
  lastPane: string;
  user:Person;
  validationMessages = validationMessages;
  events: Event[];
  pageSize: number=15;
  updated= false;
  constructor(
    private eventdbService: EventDBService,
    private arangoService: ArangoService,
    private searchService: SearchService,
    private auth: AuthService,
    private router: Router,
    private systemVariablesService: SystemVariablesService,
    private emitterService: EventEmitterService
    )
  {

    this.makeForm();
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'UpcomingEventsComponent',
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}}));
    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #013');
      this.router.navigate(['login'] );
    }
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }

    this.searchFromString = DateToYYMMDD(addDays(new Date(), +1));
    if (this.user.preferences && this.user.preferences.eventHistory && this.user.preferences.eventFutureDays) {
      this.searchFromString = DateToYYMMDD(addDays(new Date(), -this.user.preferences.eventHistory));
      this.searchToString = DateToYYMMDD(addDays(new Date(), +this.user.preferences.eventFutureDays));
    } else {
      this.searchFromString = DateToYYMMDD(addDays(new Date(), -60));
      this.searchToString = DateToYYMMDD(addDays(new Date(), +90));
    }

    console.log("Time From default "+JSON.stringify(this.searchFromString));
  

  }
  close() {
    this.showICS = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;


  }

  makeForm() {


  }

  

  async onClickRegister(e: Event) {
    this.loading = true;
    this.disableBtn = true;
      const newAttendee: EventAttendee = {
        _key: '', // filled in on insert
        person_id: '',
        contact: this.person.contact,
        mailCampaignID: 0,
        event_id: e._key,
        paid: "0.00",
        attended: 0,
        email: this.person.primaryEmail,
        first: this.person.contact.names.name.first,
        last: this.person.contact.names.name.last,
        street1: '',
        street2: '',
        city: (this.person.contact.address.items && this.person.contact.address.items.length >0)? this.person.contact.address.items[0].city: '',
        state: (this.person.contact.address.items && this.person.contact.address.items.length >0)? this.person.contact.address.items[0].state: '',
        phone: (this.person.contact.phone.items && this.person.contact.phone.items.length >0)? this.person.contact.phone.items[0].number: '',
        gender: this.person.details.gender,
        zip: (this.person.contact.address.items && this.person.contact.address.items.length >0)? this.person.contact.address.items[0].city: '',
        country: (this.person.contact.address.items && this.person.contact.address.items.length >0)? this.person.contact.address.items[0].city: '',
        start_date: e.startDate,
        end_date: e.endDate,
        event_time: OnlyTimePart(e.startTimeUTC),
        DNC: !canContact(this.person.contact),
        end_time: "00:00",
        date_created: DateToYYMMDDhhmmss()
      }


      let res: any;
      if (e.source !== 'calendly') {  // WP Event
        res = await firstValueFrom(this.eventdbService.setEventAttendee(newAttendee));

      } else {
        if (!newAttendee.person) {
          newAttendee._key = createUUID(); // Set before the add
        }
        res = await firstValueFrom(this.arangoService.setEventAttendee(newAttendee));
      }
      alert("Registered for "+e.name);
      const icsEvent = {
        title: e.name,
        busy: true,
        start: new Date(e.startTimeUTC),
        duration: [1, 'hour' as dayjs.UnitType] as [number, dayjs.UnitType], 
      };
      this.google = google(icsEvent);
      this.outlook = outlook(icsEvent);
      this.office365 = office365(icsEvent);
      this.yahoo = yahoo(icsEvent);
      this.ics = ics(icsEvent);
      this.showICS=true;
      this.emitterService.emitter.emit('Message','Attendee added to Event');
      this.disableBtn = false;
      this.notifyEvents.emit(null);
      this.loadEvents();
  }


  loadEvents() {

    console.log("Time From "+JSON.stringify(this.searchFromString));
    console.log("Time To "+JSON.stringify(this.searchToString));
    this.loading = true;
    this.dataSource.data = [];

    console.log("Prefs "+JSON.stringify(this.user.preferences));


    const futureList: Event[] =[];
    const today = addDays(new Date(),-1);
    if (this.eventList) {
      for (const ev of this.eventList) {
        if (new Date(ev.startDate) >= today) {
          futureList.push(ev);
        }
      }
    }
    this.loading = false;
    this.dataSource.data = futureList;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

      console.log('Events loaded');
    }


	showTZ(tz: string){
		return showTZ(tz);
	}


  ngOnInit() {

  }

  ngOnChanges(): void {
    const futureList: Event[] =[];
    const today = addDays(new Date(),-1);
    if (this.eventList) {
      for (const ev of this.eventList) {
        if (new Date(ev.startDate) >= today) {
          futureList.push(ev);
        }
      }
    }
    this.loading = false;
    this.dataSource.data = futureList;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    
  }


  onChangePage(event){
    console.log(event);
    this.pageNum = event.pageIndex
    this.pageSize = event.pageSize;
  }

  showKey (key: String) {
	  alert(key);
  }

}
