import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

type PaneType = 'left' | 'right' | 'center';

@Component({
    selector: 'my-slide-panel',
    styleUrls: ['./slide-panel.component.scss'],
    templateUrl: './slide-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('slide', [
            state('center', style({ transform: 'translateX(-33.33%)' })),
            state('left', style({ transform: 'translateX(0)' })),
            state('right', style({ transform: 'translateX(-66.66%)' })),
            transition('* => *', animate(300))
        ])
    ],
    standalone: false
})
export class SlidePanelComponent {
  @Input() activePane: PaneType = 'center';
}