import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';

import { Observable, of, Subscription } from 'rxjs';
import { Person } from '../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { Group } from '../../../../bo9-shared/models/person/group.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';

export interface Relations {
  from: string,
  to: string,
  email: string,
  Invite: string,
  pearlmodule: string,
  naturalnumber: string,
  source: string
}
@Component({
    selector: 'app-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.scss'],
    standalone: false
})
export class GroupsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  currentPane = 'center';
  valid: false;

  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;

  currentP$: Observable<Person>;
  currentG$: Observable<Group>;
  currentPerson: Person;
  GroupForm: FormGroup;

  invites: Relations[] =[];

  validationMessages = validationMessages;

  Groups$: Observable<Group[]>;
  PearlModuleTypes$: Observable<string[]>;

  gowner: string = '';
  canall = true;
  gtype = '';
  user: Person;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private arangoService: ArangoService
    ) 
  {
    this.currentPane = 'center';
    this.route.params.subscribe(params => {
       
      console.log(params); // log the entire params object
      console.log(" Params "+params['gtype']); // log the value of id
      console.log(" Params "+params['id']); // log the value of id

      if (params['gtype'] && params['id']) {
        this.gowner = params['id'];
        this.gtype = params['gtype']
        this.canall = false;
      }

      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'GroupsComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
      if (!this.user) {
        console.error('User Not logged in ');
        alert('EMail support&#64;bodyof9.com - Permission  Error #019'); 
        this.router.navigate(['login'] );
      }

    });
    
  }

  
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  ngAfterViewInit(): void {


  }

  ngOnInit() {

  }

  onPageChange(event){
    console.log(event);
  }


  onCloseClick() {
    this.currentPane = 'center';
  }



  onClickExpand(p:Group) {
    console.log('Expand Group  Contact '+ JSON.stringify(p.groupName));
    this.currentG$ = of(p);

    this.currentPane = 'right';
  }

  personSubmittedHandler($event: any) {
    console.log(' Person  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';
  }

  groupSubmittedHandler($event: any) {
    console.log(' Group  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';
  }

}
