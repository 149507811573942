import { Component, OnInit, ChangeDetectionStrategy, Input,  ViewChild, OnChanges, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person} from '../../../../../bo9-shared/models/person/person.model';
import { formPerson } from '../../../../../bo9-shared/models/person/formPerson.model';
import { formGroup, Group, mkMTGroup, GroupMembership, MemberIsAdmin } from '../../../../../bo9-shared/models/person/group.model';
import { validationMessages } from 'src/helpers/form.helper';
import { contactFullName } from '../../../../../bo9-shared/models/contact/contact.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

import { createUUID, UUIDToID } from '../../../../../bo9-shared/uuid.service';
import { GroupsService } from 'src/services/groups/groups.service';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { groupDeleteDialogComponent } from 'src/displayComponents/dialogs/groupDialog/groupDeleteDialog.component';
import { SelectPersonDialogComponent } from 'src/displayComponents/dialogs/selectPersonDialog/selectPersonDialog.component';
import { EventEmitterService } from 'src/services/events/events.service';
import { userIsAdmin, userIsJourneyman } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { InvitedStatus } from '../../../../../bo9-shared/models/person/invite.model';


import { Order } from '../../../../../bo9-shared/models/purchase/purchase.model';
import { Router } from '@angular/router';



@Component({
    selector: 'groupmember-list',
    templateUrl: './groupMemberList.component.html',
    styleUrls: ['./groupMemberList.component.scss'],
    standalone: false
})
export class GroupMemberListComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() inputGroup: formGroup;
  @Input() client: boolean;
  @Input() operNNId: boolean;
  @Input() idPackages: Order[];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  public displayedColumns = ['name','status','admin','primary','delete']; // Dont need to edit - can add from the dialog and edit directly 10/23/21


  public dataSource = new MatTableDataSource<GroupMembership>();
  messagesLoading$: Observable<boolean>;

  user: Person;
  valid: false;

  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;
  editing = false;

  currentI$: Observable<Group>;
  currentPerson: Person;
  GroupForm: formGroup;
  member: GroupMembership;
  loading = false;
  fieldValues = 0;
  pageSize: number;

  url = '';

  validationMessages = validationMessages;


  PearlModuleTypes$: Observable<string[]>;
  subscriptions: Subscription[] = [];

  constructor(
    private arangoService: ArangoService,
    private groupService: GroupsService,
    private dialog: MatDialog,
    private auth: AuthService,
    private router: Router,
    private emitterService: EventEmitterService,
    ) 
  {
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'GroupMemberListComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
   
    this.load(false);

  }

  
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  
  load(refresh:boolean) {

    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
    console.log(' Member  '+ JSON.stringify(this.inputGroup));
    if (this.inputGroup  && !refresh) {
      console.log('Members '+ JSON.stringify(this.inputGroup.members));
      this.dataSource.data = this.inputGroup.members;
    } else if (refresh && this.inputGroup) {
      this.groupService.getGroupMembers(this.inputGroup.group)
      .then ( (members:GroupMembership[]) => {
        this.inputGroup.members = members;
 
          for (const member of members) {
            if (member._key === this.inputGroup.group.createdBy  || member.admin) {
              this.inputGroup.isAdmin = true;
            } else {
              this.inputGroup.isAdmin = false;
            }
            if (member.invitedStatus == null ) {
              member.invitedStatus = InvitedStatus.Accepted;
            }
          }
          this.dataSource.data = this.inputGroup.members;
      })          
    }

  }


  ngOnChanges() {
    this.loading = false;
    this.load(true);
  }
  selectPrimary(p:GroupMembership) {
    console.log ('selectP ' + JSON.stringify(p));
    p.primaryContact = !p.primaryContact;
    p.person = null; // Tidy up
    this.groupService.updateGroupMembership(p).subscribe( () => {
      
      if (this.user.preferences && this.user.preferences.autoRefresh) {
        this.load(true);
      }
    });
  }

  selectAdmin(p:GroupMembership) {
    console.log ('selectP ' + JSON.stringify(p));
    p.admin = !p.admin;
    p.person = null; // Tidy up
    this.groupService.updateGroupMembership(p).subscribe( () => {
      if (this.user.preferences && this.user.preferences.autoRefresh) {
        this.load(true);
      }
    });
  }
  
  isAdmin(): boolean {
    return(userIsAdmin(this.user));
  }

  isJourneyman(): boolean {
    return(userIsJourneyman(this.user));
  }

  isGroupAdmin(): boolean {
    // console.log (`is Group Admin ${this.user._key} members ` + JSON.stringify(this.inputGroup.members));
    return(MemberIsAdmin(this.inputGroup, this.user._key));
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }


  onClickInvite() {
    this.editing = true;
  }


  onCloseClick() {
    this.editing = false;
  }

  onClickLeave(p:GroupMembership) {
    console.log('Leave  '+ JSON.stringify(p));

    if (confirm("Please confirm this person should leave")) {
      this.groupService.leaveGroup(p.from, p.to, p.groupOwner)
      .subscribe( (res) => {
        console.log('Save result  is: ' + JSON.stringify(res.content));
        this.emitterService.emitter.emit('Message','Member Deleted ' + contactFullName(p.person.contact)+ ' saved');
        if (this.user.preferences && this.user.preferences.autoRefresh) {
          this.load(true);
        }
      });
    }
  }

  memberSubmittedHandler($event) {
    console.log('memberSubmitted  '+ JSON.stringify($event));
    this.editing = false;
  }

  onBackClick() {
    if (this.notifyParent) {
      this.notifyParent.emit('Members Done');
    }

  }
 
  onClickDelete(p: Group) {
    const dRef = this.dialog.open(groupDeleteDialogComponent, {
      width: '55%',
      height: '25%',
      data: {group: p}
    });

    dRef.afterClosed()
    .subscribe( (result: Group) => {
      console.log('Selected result is ' + JSON.stringify(result));
      if (result) {

        this.groupService.deleteGroup(result)
        .subscribe( (res) => {
          alert("Group deleted");
        })

    }

    })
  }


  onClickAdd(p: formGroup) {
    const dRef = this.dialog.open(SelectPersonDialogComponent, {
      width: '75%',
      height: '75%',
      data: {group: p}
    });

    dRef.afterClosed()
    .subscribe( async (result: formPerson[]) => {
      console.log('Selected people is ' + JSON.stringify(result));
      if (result  && result.length >0) {
        for (const fp of result) {
           const gm = {
            _key: createUUID(),
            from: p._key,
            to: fp._key,
            groupOwner: p.createdBy, 
            primaryContact: fp.primaryContact,
            admin: fp.admin,
            invitedStatus: InvitedStatus.Accepted, // Added by admin can't decline :)
            meta: updateMeta()
  
          }
          const res = await firstValueFrom(this.groupService.updateGroupMembership(gm));
          
        }
        if (this.user.preferences && this.user.preferences.autoRefresh) {
          this.load(true);
        }
      }
      
    })
  }


  makeForm() {

  }


  ngOnInit() {
    //this.loadRelations();
    this.load(false);
  }


  onRefreshClick() {
    this.load(true);
  }
  onPageChange(event){
    console.log(event);
  }

}
