import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnChanges, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person, getPurchaseList, getUserName } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';

import { FormRelation, Relation} from '../../../../../bo9-shared/models/person/relation.model';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from 'src/services/search/search.service';
import { EventEmitterService } from 'src/services/events/events.service';
import { ProductsService } from 'src/services/products/products.service';
import { ModuleDetail, Product, multiProduct } from '../../../../../bo9-shared/models/product/product.model';
import { addMonths, addYears, dateFormating, DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { SelectListDialogComponent } from 'src/displayComponents/dialogs/selectListDialog/selectListDialog.component';
import { addPurchase, createPurchaseFromSubscription } from '../../../../../bo9-shared/models/purchase/purchase.model';
import { userIsAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { PearlService } from 'src/services/pearl/pearl.service';


@Component({
    selector: 'subscription-list',
    templateUrl: './subscriptionList.component.html',
    styleUrls: ['./subscriptionList.component.scss'],
    standalone: false
})
export class SubscriptionListComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() from: Person;
  @Input() canAll: boolean;


  valid: false;

  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;
  products: Product[];

  currentI$: Observable<FormRelation>;
  currentPerson: Person;
  RelationForm: FormGroup;
  loading = false;
  fieldValues = 0;
  relations: FormRelation[] =[];

  validationMessages = validationMessages;

  user: Person;
  person: Person;

  userD: multiProduct[];
  PearlModuleTypes$: Observable<string[]>;
  name: string;

  currentSubscriptionPane = 'center';
  subscriptions: Subscription[] = [];
  constructor(
    private searchService: SearchService,
    private emitterService: EventEmitterService,
    private router: Router,
    private arangoService: ArangoService,
    private auth: AuthService,
    private dialog: MatDialog,
    private pearlService: PearlService,
    private productService: ProductsService
    )
  {

    this.from = null;
	 this.currentSubscriptionPane = 'center';
   this.user = this.auth.getAuthUser();
   this.subscriptions.push(this.arangoService.recordVisit({
     page: this.router.url, component: 'SubscriptionListComponent', 
     visitor: this.user._key,
     help: false
   }).subscribe ({next: () => {}})); 

  }

  dateFormating(date: any, kind: string) {
    return dateFormating(date, kind);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  ngOnChanges() {
    this.fillUser();
  }

  fillUser() {
    let email = '';
    if (this.from.primaryEmail) {
      email = this.from.primaryEmail
    }
    this.name = getUserName(this.from);
    if (email !== '') {
      this.subscriptions.push(  this.searchService.searchEmail(email, true)
      .subscribe( (persons: Person[]) => {
        
        this.person = persons[0];

         const products = getPurchaseList(this.person, this.products);
         this.userD = [];
         for (const prod of products) {
            if (prod.productInfo.category === 'Pearl Module') {
              this.userD.push(prod);
            }
         }
        
      }))

    }

  }


  ngAfterViewInit(): void {
    this.user = this.auth.getAuthUser();
    this.products=[];
    this.subscriptions.push( this.productService.loadProducts()
    .subscribe( (res: any) => {
      console.log('products'+ JSON.stringify(res))
      for (const p of res.content as Product[]) {
       console.log('product'+ JSON.stringify(p))
       if (p.category === 'Pearl Module') {
        if (userIsAdmin(this.user)) {
          this.products.push(p);
        } else {
          if (p.status === 'publish') {
            this.products.push(p);
          }
        }
       }

      }
      this.fillUser();
      
    }))

  }

  onClickDelete(p: multiProduct, pi: number, oi: number) {
    console.log('Delete  product '+ JSON.stringify(p));
    this.person.purchases[pi].order.orderProducts[oi].moduleDetail.date_end = DateToYYMMDDhhmmss();
    this.person.purchases[pi].order.orderProducts[oi].moduleDetail.status = 'Cancelled';
    this.subscriptions.push( this.arangoService.updatePerson(this.person)
    .subscribe (async  (res) => {
      const r = await firstValueFrom(this.pearlService.unsubscribe(this.person.primaryEmail));
      console.log('delete result  is: ' + JSON.stringify(r.content));
      this.emitterService.emitter.emit('Message','Cancelled Purchase ' + p.productInfo.title+ ' saved');
      this.fillUser();
      
    }))
  }

  onClickSubscribe(p: Product) {
    console.log('Subscribe  to product '+ JSON.stringify(p));
    const detail: ModuleDetail = p.moduleDetail;
    detail.status = 'Completed';
    switch (p.moduleDetail.duration) {
      case 'Lifetime':
        detail.date_started = DateToYYMMDDhhmmss();
        detail.date_end = DateToYYMMDDhhmmss(addYears(new Date, 100));

        break;
      case 'Annual':
        detail.date_started = DateToYYMMDDhhmmss();
        detail.date_end = DateToYYMMDDhhmmss(addYears(new Date, 1));

        break;
        case 'Monthly':
          detail.date_started = DateToYYMMDDhhmmss();
          detail.date_end = DateToYYMMDDhhmmss(addMonths(new Date, 1));

          break;
      default:
        console.error('Unknown Duration - cant set '+(p.moduleDetail).duration);
    }
    if (p.moduleDetail.selectList !== '' && p.moduleDetail.selectList === 'Selected List') {
        const dRef = this.dialog.open(SelectListDialogComponent, {
          width: '55%',
          height: '25%',
          data: {selectList: p}
        });

        this.subscriptions.push( dRef.afterClosed()
        .subscribe( (result: string) => {
          console.log('Selected NN result is ' + JSON.stringify(result));
          if (result) {
            detail.selectList = result;
            console.log('detail is ' + JSON.stringify(detail));
            this.updateUser(p, detail)
            p.moduleDetail.selectList = 'Selected List'; // reset it
            detail.selectList = 'Selected List';
          }
          
        }))
    } else {
      this.updateUser(p, detail)
    }
  }

  isAdmin(): boolean {
    return(userIsAdmin(this.user));
  }

  updateUser(p: Product, d: ModuleDetail) {
    p.moduleDetail = d;
    console.log('module detail for person: ' + JSON.stringify(d));
    if (this.person && !this.person.purchases) {
      this.person.purchases = [];
    }
    this.person.purchases = addPurchase(this.person.purchases,createPurchaseFromSubscription(this.person._key,this.person.primaryEmail, [p]));
    console.log('purchases for person: ' + JSON.stringify(this.person.purchases));
    this.subscriptions.push( this.arangoService.updatePerson(this.person)
    .subscribe ( async (res) => {
      const r = await firstValueFrom(this.pearlService.subscribe(this.person.primaryEmail, 'daily'));

        console.log('Save result  is: ' + JSON.stringify(r.content));
        this.emitterService.emitter.emit('Message','Add Purchase ' + p.title+ ' saved');

        this.fillUser();

        
    }))
  }


  ngOnInit() {

  }


}
