import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';

import { AuthService } from 'src/services/auth/auth.service';

import { Router } from '@angular/router';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Page, getPageByKey } from '../../../../../bo9-shared/models/page/page.model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dialog-help',
    templateUrl: './helpDialog.component.html',
    styleUrls: ['./helpDialog.component.scss'],
    standalone: false
})

export class HelpDialogComponent implements OnInit  {
  disableBtn = false;
  pages: Page[];
  user: Person;
  general: Page;
  currentHelp:Page = null;
  title = '';
  subscriptions: Subscription[] = [];
  constructor(
    private dialogRef: MatDialogRef<HelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: { isRel: boolean } ,
    private auth: AuthService,
    private router: Router,
    private arangoService: ArangoService

   ) {
      console.log ('Init help dialog ' + JSON.stringify(data));
      this.user = this.auth.getAuthUser();
      this.subscriptions.forEach(s => s.unsubscribe());
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'HelpDialogComponent', 
        visitor: this.user._key,
        help: true
      }).subscribe ({next: () => {}})); 
     }

  ngOnInit() {

    console.log ('Init help init ' + JSON.stringify(this.data));
    this.user = this.auth.getAuthUser();

    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #004'); 
      this.router.navigate(['login'] );
    } 
    console.log("Help route is "+this.router.url)
      this.subscriptions.push( this.arangoService.getHelpPages()
      .subscribe( {next: (result) => {
        this.pages = result.content;
        let irlArray = this.router.url.split('/');
        if (this.data.isRel) {
          irlArray = ['release-notes']
          this.general = null;
          this.title = 'Release Notes;'
        } else {
          this.currentHelp = null;
          this.general = getPageByKey('general',this.pages);
          this.title = 'Help Text';
        }
        console.log("Help route is "+JSON.stringify(irlArray));
        let helpSlug = '';
        if (this.pages && this.pages.length >0) {
         
          for (const slug of irlArray) {
            if (slug) {
              if (helpSlug === '') {
                helpSlug = slug.toLowerCase()
              } else {
                helpSlug = helpSlug+ '-' + slug.toLowerCase();
              }
              
            }
          }
          this.currentHelp = getPageByKey(helpSlug,this.pages);
          
          window.scrollTo(0,0);
        } 
        
        // console.log("Help pages is "+JSON.stringify(this.pages));
      }}))
  }


  close() {
    this.dialogRef.close();
  }
}
