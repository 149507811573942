import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, ViewChild, Output, EventEmitter, OnDestroy, AfterViewInit, AfterViewChecked, ElementRef } from '@angular/core';

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person, personFromAttendee,getGender} from '../../../../../bo9-shared/models/person/person.model';
import { Difference} from '../../../../../bo9-shared/models/person/differences.model';
import {  differencesForSource, getDifferences, sameDifference} from '../../../../../bo9-shared/models/person/differencesCode.model';
import { validationMessages } from 'src/helpers/form.helper';
import { BO9Contact, canContact, contactFullName } from '../../../../../bo9-shared/models/contact/contact.model';
import {  Invite } from '../../../../../bo9-shared/models/person/invite.model';
import { EventDBService } from 'src/services/events/eventdb.service';
import { Event, EventAttendee, EventParams, orderFromEvent } from '../../../../../bo9-shared/models/event/event.model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';

import { SearchService } from 'src/services/search/search.service';
import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';
import { addNaturalNumber, makeMTNaturalNumber, NaturalNumber, NaturalNumberType, NaturalNumberTypes, stringToNaturalNumber } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { DateToYYMMDD, DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { EventEmitterService } from 'src/services/events/events.service';
import { ProductsService } from 'src/services/products/products.service';
import { ActivityService } from 'src/services/activity/activity.service';
import { Activity } from '../../../../../bo9-shared/models/activity/activity.model';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { AuthService } from 'src/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { makeDefaultPearl, ModuleDetail } from '../../../../../bo9-shared/models/product/product.model';
import { addPurchase, createPurchaseFromSubscription } from '../../../../../bo9-shared/models/purchase/purchase.model';
import { PearlService } from 'src/services/pearl/pearl.service';
import { DeviceDetectorService} from 'ngx-device-detector'
import { userIsAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';


@Component({
    selector: 'app-identified',
    templateUrl: './identifiedList.component.html',
    styleUrls: ['./identifiedList.component.scss'],
    standalone: false
})
export class IdentifiedListComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit{
  @Input() person: Person;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('table', {read: ElementRef}) table: ElementRef;

  @Output() notifyParent: EventEmitter<Person> = new EventEmitter<Person>();
  @Output() mergePerson: EventEmitter<{orig: Person, new: Person, difference: Difference[]}> = 
    new EventEmitter<{orig: Person, new: Person, difference: Difference[]}>();
  messagesLoading$: Observable<boolean>;
  events$: Observable<Person[]>;
  mobile = false;
  pageNum: number;
  totalPages: number;
  pageSize: number;
  attendees: EventAttendee[] =[];

  fromName = 'Not Set';

 
  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;

  currentP$: Observable<Person>;
  currentPerson: Person;
  people: Person;
  fieldValues: number;
  totalEvents: number;
  relatingPersonName: string;
  eventTag: string = '';
  operation: string = 'none';
  currentSource: EventAttendee;
  nnKeys: string[];
  fromF$:Observable<Person>;
  attendee: EventAttendee;

  validationMessages = validationMessages;

  Invites$: Observable<Invite[]>;
  PearlModuleTypes$: Observable<string[]>;
  loading = false;
  tagList =[];
  email: string;
  idList = [];
  user;
  disableBtn = false;
  hours: number[] = [];
  pageTitle = "Attendees";

  public displayedColumns = ['controls', 'first', 'last', 'email','idBy', 'idAt','nn','createDate','city', 'dnc'];
  public mobileColumns =   ['edit','who','idBy', 'idAt','nn'];
  public dataSource = new MatTableDataSource<EventAttendee>();
  subscriptions: Subscription[] = [];

  colList : string[]=[];
  constructor(
    private activityService: ActivityService,
    private pearlService: PearlService,
    private router: Router,
    private arangoService: ArangoService,
    private auth: AuthService,
    private activeCampaignService: ActiveCampaignService,
    private route: ActivatedRoute,
    private emitterService: EventEmitterService,
    private deviceService: DeviceDetectorService,
    ) 
  {
    this.user = this.auth.getAuthUser();

    if (this.isMobile()) {
      this.mobile = true;
      this.colList = this.mobileColumns;
    }  else {
      this.colList =  this.displayedColumns
    }
    this.disableBtn = false;
   

    this.nnKeys = Object.keys(NaturalNumber).map(key => NaturalNumber[key]).filter(value => typeof value === 'string') as string[];
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'AttendeesComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe({ next: () => { } })); 
    

    this.subscriptions.push(this.route.params.subscribe( async (params) => {
       
      const res = await firstValueFrom(this.activeCampaignService.getContactTagFieldValues(false))
      this.tagList = this.activeCampaignService.tagAndFields.tags;
      this.operation='none';
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.refresh();
    }))
  }

  isMobile(): boolean {
    return this.deviceService.isMobile()
  }

  ngAfterViewInit(): void {
    if (this.person) {
      this.pageTitle = 'Identified';
    }
  }

  isAdmin(): boolean {
    return userIsAdmin(this.user);
   }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  
 bySelected($event: any, element: any) {
    console.log(`by selected `+JSON.stringify($event.value));
    if (!element.person) {
      alert("Can't update person not saved to DB ");
    } else {
      const p:Person = element.person;
      p.naturalNumbers.identified.setBy =  {
        name: $event.value.join(","),
        uuid: '',
        comment: ''
      };
      this.subscriptions.push( this.arangoService.updatePerson(p)
      .subscribe( (res) => {
        
        }))

    }

  }

  selectDNC($event: any) {
    this.disableBtn = true;
    console.log(`select DNC `+JSON.stringify($event));
    $event.person.contact.DNC = ! $event.person.contact.DNC;
    this.subscriptions.push( this.arangoService.updatePerson($event.person)
    .subscribe( (res) => {
      this.disableBtn = false;
      }))
  }

  nnSelected($event: any, element: EventAttendee) {
    const p: Person = element.person;
    if (!element.person) {
      alert("Can't update person not saved to DB ");
    } else if ( !(p.naturalNumbers.identified.setBy.name || p.naturalNumbers.asserted.setBy.name)) {
      alert("Can't update person without selecting identifier");
    } else {
      console.log(`NN selected ${element.person._key} `+JSON.stringify($event.value));
      this.disableBtn = true;
      if (element.sourceType == NaturalNumberTypes.Identified) {
        p.naturalNumbers.identified = addNaturalNumber($event.value,element.idAt, 
        p.naturalNumbers.identified.setBy.name, DateToYYMMDDhhmmss(),'By Identifier', "Manually","");
      } else if (element.sourceType == NaturalNumberTypes.Asserted) {
        p.naturalNumbers.asserted = addNaturalNumber($event.value,element.idAt, 
        p.naturalNumbers.asserted.setBy.name, DateToYYMMDDhhmmss(),'By Identifier', "Manually","");
      }
     

      this.checkAttendeeInDBandActCamp(p)
        .then(async () => {
          const update = await firstValueFrom( this.arangoService.updatePerson(p));
          if (!p.primaryEmail) {
            
            this.emitterService.emitter.emit('Message',p.primaryEmail + ' DB Updated with email');
          } 
          
        if (canContact(p.contact) && element.sourceType == NaturalNumberTypes.Identified) {
            p.purchases = addPurchase(p.purchases, createPurchaseFromSubscription(p._key,p.primaryEmail, [makeDefaultPearl()]))
            console.log(`Create Pearl Purchase for  ${element.person.primaryEmail} `);
            const update = await firstValueFrom( this.arangoService.updatePerson(p));
            console.log(`Create Pearl subscription for  ${element.person.primaryEmail} `);
            this.emitterService.emitter.emit('Message',`Create Pearl subscription for  ${element.person.primaryEmail} `);
            const r = await firstValueFrom(this.pearlService.subscribe(p.primaryEmail, 'daily'));
            const resSync = await  firstValueFrom(this.activeCampaignService.syncContact(p))
            this.emitterService.emitter.emit('Message',p.primaryEmail + ' DB Updated and ActiveCampaign Updated');
          } else {
            this.emitterService.emitter.emit('Message',p.primaryEmail + ' DB Updated but not ActiveCampaign Updated (DNC)');
          }
      
  
          const act: Activity = {
            _key :createUUID(),
            from : p._key,
            to : p._key,
            kind : 'Identified',
            note : '',
            date : DateToYYMMDDhhmmss(),
            by : p.naturalNumbers.identified.setBy.name,
            NN: stringToNaturalNumber($event.value),
            meta : updateMeta()
          }
  
          const active = await  firstValueFrom(this.activityService.addActivity( act, 'person'))
          this.disableBtn = false;

         
      });

    }
  }



  ngOnChanges() {
      this.refresh(); 
  }

  refresh() {
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
    if (this.person) {
      this.idList = [contactFullName(this.person.contact)];
      this.loading = true;
      this.subscriptions.push(  this.arangoService.getIdentifiedBy(contactFullName(this.person.contact))
      .subscribe( async (result) => {
        let e;

        e = await this.makeEventAttendeeFromPeople(result.content.persons);

        console.log('Attendees set 1');
        this.loading = false;
        this.table.nativeElement.scrollIntoView(true);
      }))
    }
    
  }

  


  ngOnInit() {
    this.operation='none';
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  onRefreshClick() {
    this.refresh();
  }

  onCloseClick() {
    this.operation='none';
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      if (!this.mobile) this.refresh();
    }
  }

  onClickEdit(p:EventAttendee) {
    this.operation='editattendee';
    this.currentSource= p;
    this.attendee = p;

  }

  onClickSubscriptions(p:EventAttendee) {
    this.operation='subscriptions';
    this.currentSource= p;
    this.attendee = p;
    this.fromF$ = of(p.person);

  }

  onClickRelate(p:EventAttendee) {
    this.operation='relations';
    this.currentSource= p;
    this.fromF$ = of(p.person);
    this.email= p.email;
    this.fromName = p.first + ' ' + p.last;
  }

  

  onClickAddIdentified() {
    this.attendee = null;
    this.operation="editattendee";
  }
  onClickID(p:EventAttendee) {
    this.attendee = p;
    this.operation="editattendee";

  }

  
  onClickView(p:EventAttendee) {
    console.log('View Attendee of Event '+ JSON.stringify(p.person));
    this.mergePerson.emit({orig: p.person, new: null, difference: null});
  }

  onClickMerge(p:EventAttendee) {
    console.log('Merge Attendee of Event '+ JSON.stringify(p));
    this.mergePerson.emit({orig: p.person, new: p.newPerson, difference: p.difference});
  }

  // / {"_key":230,"first":"Mandisa","last":"Snodey","email":"LaShon.snodey@gmail.com","street1":"","street2":"","city":"","state":"","zip":"","country":""}
  onClickSave(p:any) {
    console.log('Save Identified  '+ JSON.stringify(p));
    this.disableBtn = true;
    this.loading = true;
    const newP =  this.person
    this.subscriptions.push( this.arangoService.updatePerson(newP)
    .subscribe( (result) =>{
      console.log('Update person result  is: ' + JSON.stringify(result.content));
      this.emitterService.emitter.emit('Message','Identified ' + contactFullName(newP.contact) + ' saved');
      this.loading = false;
      if (this.user.preferences && this.user.preferences.autoRefresh) {
        this.refresh();
      }
      this.disableBtn = false;
    }))

  }

  onClickAdd(p:Event) {
    console.log('Add attendee '+ JSON.stringify(p.name));


  }
  personSubmittedHandler($event: any) {
    console.log(' Person  submitted '+ JSON.stringify($event));

    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.refresh();
    }

  }

  relationSubmittedHandler($event: any) {
    console.log(' Relation  submitted '+ JSON.stringify($event));
    this.operation='none';


    this.fromF$ = of();
    this.fromF$ = of(this.currentSource.person);
    
  }

  attendeeSubmittedHandler($event: any) {
    console.log(' Attendee  submitted '+ JSON.stringify($event));
    
    this.operation='none';
    this.emitterService.emitter.emit('Message',$event);
    this.disableBtn = false;
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.refresh();
    }
    
  }



checkAttendeeInDBandActCamp(a: Person): Promise<boolean> {
  this.disableBtn = true;
  return new Promise(async (resolve, reject) => {
    if (canContact(a.contact)) {
      const added = await firstValueFrom(this.activeCampaignService.syncContact(a));
    }

      this.disableBtn = false;
      resolve(true);

  })
}

makeEventAttendeeFromPeople(people: Person[]) : Promise<EventAttendee[]> {
  this.attendees = [];
  people.push(this.user);
  return new Promise ( async (resolve, reject) => {
    this.disableBtn = true;
        this.hours=[];
        let e: EventAttendee[] = [];
        for( let i=0; i< people.length; i++) {
          e.push( {
            _key: people[i]._key,
            person_id: people[i]._key,
            contact: people[i].contact,

            mailCampaignID: 0,

            event_id: '',
            paid: '0',
            attended: 1,
            email: people[i].primaryEmail,
            phone: '',
            first: '',
            last: '',
  
            street1: '',
            street2: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            gender: '',
            start_date: DateToYYMMDDhhmmss(),
            end_date: DateToYYMMDDhhmmss(),
            event_time: '00:00',
            end_time: '00:00',
            date_created: DateToYYMMDDhhmmss()

          });

          let person = people[i];
         
          e[i].newPerson = person;
          e[i].person = person;
        
          

            e[i].person = person;
            if(person && person.contact && person.contact.names && person.contact.names.name) {
              e[i].dbFirst = person.contact.names.name.first;
              e[i].dbLast= person.contact.names.name.last;
              e[i].first =  e[i].dbFirst;
              e[i].last = e[i].dbLast;
            } else {
              e[i].dbFirst = e[i].first;
              e[i].dbLast = e[i].last;
            }

            if (person && person.contact && person.contact.address && person.contact.address.items && person.contact.address.items[0] && person.contact.address.items[0].city) {
              e[i].city = person.contact.address.items[0].city;
            }

 
            e[i].DNC = !canContact(person.contact)

            //console.log("Found mail for " + person.primaryEmail);
           // console.log("Found NN for " + JSON.stringify(person.naturalNumbers));

            if (!e[i].person.naturalNumbers.identified && !e[i].person.naturalNumbers.asserted) { // Handle bad conversion
              e[i].person.naturalNumbers.identified = makeMTNaturalNumber();
            }
            e[i].nnIssue = false;

            if (!e[i].person.naturalNumbers || ( !e[i].person.naturalNumbers.asserted && e[i].person.naturalNumbers && 
              (e[i].person.naturalNumbers.identified.naturalNumber === NaturalNumber.None  || 
                e[i].person.naturalNumbers.identified.naturalNumber === NaturalNumber.OldNone))) {
                  e[i].sourceType = NaturalNumberTypes.UnKnown;
            } else {
              let natNum: NaturalNumberType;

              if (e[i].person.naturalNumbers.identified && !(e[i].person.naturalNumbers.identified.naturalNumber === NaturalNumber.None  || 
                e[i].person.naturalNumbers.identified.naturalNumber === NaturalNumber.OldNone)) {
                natNum = e[i].person.naturalNumbers.identified;
                e[i].sourceType = NaturalNumberTypes.Identified;
              } else if (e[i].person.naturalNumbers.asserted) {
                natNum = e[i].person.naturalNumbers.asserted
                e[i].sourceType = NaturalNumberTypes.Asserted;
              }
             // console.log(`Set NN person ${i} `+JSON.stringify(natNum));
              e[i].idAt = natNum.setAt;
              if (typeof natNum === 'string') { 
                e[i].idBy = natNum
              } else if (natNum.setBy && typeof natNum.setBy === 'string') {
                const s: string = natNum.setBy
                e[i].idBy =  s.replace(' and ',',');
              } else if (natNum.setBy && typeof natNum.setBy.name === 'string') {
                const s1: string = natNum.setBy.name
                e[i].idBy =  s1.replace(' and ',',');
              } else {
                e[i].idBy =  'Susan Fisher';
              }
              
              e[i].idBy = e[i].idBy.split(',');
              e[i].nn = natNum.naturalNumber.toString();
             
              if (natNum.dateSet == "") {
                if (natNum.meta && natNum.meta.lastModified) {
                  e[i].dateIdentified = DateToYYMMDD(new Date(natNum.meta.lastModified));
                 
                } else {
                  e[i].dateIdentified = DateToYYMMDD(new Date());
                }

              } else {
                e[i].dateIdentified = DateToYYMMDD(new Date(natNum.dateSet));
              }
              e[i].date_created = e[i].dateIdentified
              
            }

  
          if (!e[i].nn) {
            e[i].nn = NaturalNumber.None;
          }
          e[i].tagged = false;
          
          
          
          this.attendees.push(e[i]);
          this.dataSource.data = this.attendees;
          this.disableBtn = false;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }

        resolve(e);
  })
}

 
}

