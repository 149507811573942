<div #addattendee>
Submitted {{submitted}}
<hr>




<form [formGroup]="form"  class="relate-form">
  <div>
    <h3>Register for: {{ event?.name}} </h3>
    
    <mat-form-field  appearance="outline" >
      <mat-label>First Name</mat-label>
      <input matInput 
        formControlName="first"
        id="first"
        required
      />

    </mat-form-field>


    <mat-form-field  appearance="outline" >
      <mat-label>Last Name</mat-label>
      <input matInput 
        formControlName="last"
        id="last"
        required
      />

    </mat-form-field>

    <br />
     

    <mat-form-field  appearance="outline" >
      <mat-label>Email</mat-label>
      <input matInput 
        formControlName="email"
        id="email"
        required
      />

    </mat-form-field>

    <br />

    <mat-form-field  appearance="outline" >
      <mat-label>City</mat-label>
      <input matInput 
        formControlName="city"
        id="city"
      />

    </mat-form-field>
    <mat-form-field  appearance="outline" >
      <mat-label>State</mat-label>
      <input matInput 
        formControlName="state"
        id="state"
      />

    </mat-form-field>
    <br />
    <mat-form-field  appearance="outline" >
      <mat-label>Zip</mat-label>
      <input matInput 
        formControlName="zip"
        id="zip"
      />

    </mat-form-field>
    <mat-form-field  appearance="outline" >
      <mat-label>Country</mat-label>
      <input matInput 
        formControlName="country"
        id="country"
      />

    </mat-form-field>
    <br />
    <mat-form-field  appearance="outline" >
      <mat-label>Phone</mat-label>
      <input matInput 
        formControlName="phone"
        id="phone"
      />

    </mat-form-field>
    <mat-form-field  appearance="outline" >
      <mat-label>Gender</mat-label>
      <mat-select matNativeControl   formControlName="gender">
        <mat-option *ngFor="let nnI of genderTypes" [value]="nnI">
          {{ nnI }}
        </mat-option>
      </mat-select>


    </mat-form-field>
    <br />
    <mat-form-field  appearance="outline" >
      <mat-label>Amount Paid/Donated</mat-label>
      <input matInput 
        formControlName="paid"
        id="paid"
      />
      </mat-form-field>

  </div>

  

</form>
<br />
<br />
<br />
<br />
<hr>
<button mat-button  (click)="resetForm()"  class="btn">Reset</button>


  <span *ngIf="form.value.email">

  <button mat-stroked-button type="button" [disabled]="disableBtn" (click)="reserveATime()" matTooltip="Reserve a Time">Reserve a Time</button>
<div *ngIf="slots" >
  <span *ngFor="let item of slots; index as idx" class="slots">
    {{item.eventDate}}
    <div *ngFor="let appt of item.appointments; index as idxA">
     <span *ngIf="!appt.attendeeKey" >  <button (click)="reserveTime(item.eventDate, appt.appointmentTime)" [ngClass]="appt.attendeeKey ? 'taken': 'available'"> {{appt.appointmentTime }}</button> </span>
    </div>
    
    </span>
    </div>
  <button mat-button type="button" [disabled]="disableBtn" (click)="submitNow()" class="btn btn-save mat-submit-button">Ready Now</button>
<br />

  <a href="https://www.canva.com/design/DAGYfMPhjMQ/7wSXJ8opaftGozSqyDUmpg/watch" target="_blank"> Play "What to Expect"</a> <br />


  
</span>

</div>