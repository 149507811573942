import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnChanges, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/services/loginservice/login.service';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { formGroup, Group, mkMTGroup, GroupMembership } from '../../../../../bo9-shared/models/person/group.model';
import { validationMessages } from 'src/helpers/form.helper';
import { contactFullName } from '../../../../../bo9-shared/models/contact/contact.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { BO9UUID } from '../../../../../bo9-shared/models/UUID.model';
import { RelationDialogComponent } from '../../dialogs/relationDialog/relationDialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from 'src/services/search/search.service';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { GroupsService } from 'src/services/groups/groups.service';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { groupDeleteDialogComponent } from 'src/displayComponents/dialogs/groupDialog/groupDeleteDialog.component';
import { EventEmitterService } from 'src/services/events/events.service';


@Component({
    selector: 'group-list',
    templateUrl: './groupList.component.html',
    styleUrls: ['./groupList.component.scss'],
    standalone: false
})
export class GroupListComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() from: string;
  @Input() canAll: boolean;
  @Input() gtype: string;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  public displayedColumns = ['edit','add','name','groupType','members', 'creator','delete'];
  public dataSource = new MatTableDataSource<formGroup>();
  messagesLoading$: Observable<boolean>;

  currentGroupPane = 'center';
  valid: false;

  user: Person;

  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;

  currentI: Group;
  currentfg$: Observable<formGroup>;
  currentPerson: Person;
  GroupForm: formGroup;
  loading = false;
  fieldValues = 0;
  pageSize: number;
  validationMessages = validationMessages;


  PearlModuleTypes$: Observable<string[]>;

  groupType = 'createdBy';

  subscriptions: Subscription[] = [];
  constructor(
    private groupService: GroupsService,
    private dialog: MatDialog,
    private auth: AuthService,
    private router: Router,
    private arangoService: ArangoService,
    private searchService: SearchService,
    private emitterService: EventEmitterService,
    ) 
  {
    this.currentGroupPane = 'center';
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'GroupListComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {
      this.loadGroups(true);
    }})); 
  }


  ngOnChanges() {
    this.loading = false;
  
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  loadGroups(force?: boolean) {
    this.currentGroupPane = 'center';
    this.fieldValues = 0;

    this.loading = true;
    this.dataSource.data = [];
    console.log(' Loadgroups    '+ JSON.stringify(this.from));
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
    
    this.groupService.getGroups(this.from, this.groupType, this.gtype)  // TODO not do it twice if this.from is set
    .then( async (result: Group[]) => {
      let memberOf: Group[] = [];
      let allGroups: Group[] = [];
      if (this.from && !this.gtype) { // A person
        memberOf = await this.groupService.getGroupsMemberOf(this.from);
        console.log("Group memberOf " + JSON.stringify(memberOf));
        allGroups = memberOf; // would include ones this.form created, so don't do it twice
        const cp = await this.searchService.searchPersonKey(this.from, false);
        if (cp) {
          this.currentPerson = cp;
        } else {
          console.error("This person not returning an email");
          alert(`${this.from} person not returning an email`);
        }

      } else {
        allGroups = result; // 
      }

      this.loading = false;
      const fgs: formGroup[] = [];
      for (const g of allGroups) {
        console.log("Group   " + JSON.stringify(g));

            const fg: formGroup = {
                _key: g._key,
                createdBy: g.createdBy,
                group: g,
                members: [],
                meta: g.meta
            }
            const p = await this.searchService.searchPersonKey(g.createdBy, false);
            console.log("Group creator  " + JSON.stringify(p));
            if (p) {
                fg.creatorName = contactFullName(p.contact);
            } else {
                fg.creatorName = 'UnKnown';
            }
            const members:GroupMembership[] = await this.groupService.getGroupMembers(g);
            fg.members = members;
            if (this.canAll) { // Admin listing - can do what I want
              fg.isAdmin = true;
            } else if (this.from === g.createdBy) {
              fg.isAdmin = true;

            } else {
              for (const member of members) {
                if (member._key === g.createdBy  || (member.to === this.user._key ) &&  member.admin) {
                  fg.isAdmin = true;
                  break;
                } else {
                  fg.isAdmin = false;
                }
              }
            }

            fgs.push(fg);
        }
        
      this.dataSource.data = fgs;
      this.fieldValues = 10;
    })

        
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

  onClickEdit(p:formGroup) {
    console.log('Edit Group  '+ JSON.stringify(p));

    this.currentI = p.group;
    this.currentGroupPane = 'left';
  }

  onClickLeave(p:formGroup) {
    console.log('Leave Group  '+ JSON.stringify(p));
    if (confirm("Please confirm this person should be removed from the group")) {
      this.subscriptions.push( this.groupService.leaveGroup(p._key, this.currentPerson._key, p.group.createdBy)
      .subscribe( (res) => {
        console.log('Save result  is: ' + JSON.stringify(res.content));
        
        this.emitterService.emitter.emit('Message','Member Removed from Group ' + contactFullName(this.currentPerson.contact)+ ' saved');
      }))
    }
  }

  onClickMember(p:formGroup) {
    console.log('Expand Member  '+ JSON.stringify(p));
    this.currentfg$ = of(p);
    this.currentGroupPane = 'right';
  }

  onClickDelete(p: any) {
    const dRef = this.dialog.open(groupDeleteDialogComponent, {
      width: '55%',
      height: '25%',
      data: {group: p}
    });

    this.subscriptions.push( dRef.afterClosed()
    .subscribe( async  (result: Group) => {
      console.log('Selected result is ' + JSON.stringify(result));
      if (result) {
        const s = await firstValueFrom(this.groupService.deleteGroup(result));
        alert("Group deleted");
      }
      
    }))
  }


  onClickAdd() {
   
    this.searchService.searchPersonKey(this.from, false)
    .then( (person: Person) => {
      if (person) {
        console.log("adding  " + JSON.stringify(person));

        const newFormGroup: Group = {
          _key: '',
          createdBy: person._key,
          groupType: this.gtype? this.gtype: '',
          groupName: contactFullName(person.contact),
          meta: updateMeta()
  
        }
        this.currentI = newFormGroup;
        this.currentGroupPane = 'left';
      } else {
        console.error("adding failed  " + JSON.stringify(this.from));
      }
      
     
    })

  }

  makeForm() {

  }


  ngOnInit() {
    //this.loadRelations();
  }


  onRefreshClick() {
    if (this.canAll) {
      this.from='';
    }
    this.loadGroups(true);
  }
  onPageChange(event){
    console.log(event);
  }

  onCloseClick() {
    this.currentGroupPane = 'center';
  }


  groupSubmittedHandler($event: any) {
    console.log(' Group  submitted '+ JSON.stringify($event));
    this.loadGroups(true);
    this.currentGroupPane = 'center';
  }

  groupMemberSubmittedHandler($event: any) {
    console.log(' Group  Member submitted '+ JSON.stringify($event));
    this.loadGroups(true);
    this.currentGroupPane = 'center';
  }

}
