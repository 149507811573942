import { Component, OnInit, OnDestroy, Input, Inject, ViewChild, AfterViewInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WooCustomer } from '../../../../../bo9-shared/models/woocommerce/woocustomer.model';
import { MatTableDataSource} from '@angular/material/table';
import { ModuleDetail, Product, multiProduct } from '../../../../../bo9-shared/models/product/product.model';
import { Subscription } from 'rxjs';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth/auth.service';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


@Component({
    selector: 'app-dialog-selectProduct',
    templateUrl: './selectProductDialog.component.html',
    styleUrls: ['./selectProductDialog.component.scss'],
    standalone: false
})

export class SelectProductDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  disableBtn = false;
  public displayedColumns = ['select', 'wooid', 'product','categories','sku'];
  public dataSource = new MatTableDataSource<Product>();
  pageSize: number;
  user: Person;

  products: Product[];
  selected: Product[] = [];
  searchString: string;
  loading: boolean;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private dialogRef: MatDialogRef<SelectProductDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: { products: Product[] } ,
    private router: Router,
    private arangoService: ArangoService
   ) {
      console.log (' select WooCustomer ' + JSON.stringify(data));
      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'SelectProductDialogComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
     }

     ngAfterViewInit(): void {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
  
  
    }
  ngOnInit() {

    console.log ('Init select Product init ' + JSON.stringify(this.data));
    this.dataSource.data = [];
    this.products = this.data.products;
    this.dataSource.data = this.data.products;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

 

  selectFormCustomer(p: Product ) {
    for (const fp of this.products) {
      if (fp._key===  p._key) {
        fp.selected = !fp.selected;
        this.dataSource.data = this.products;
        this.dataSource.sort = this.sort;
      }
    }

  }

  selectSingleCustomer(p: WooCustomer ) {
    this.dialogRef.close([p]);
  }

  selectAndClose() {
    this.selected = [];
    for (const fp of this.products) {
      if (fp.selected) {
        this.selected.push(fp);
      }
    }
    this.dialogRef.close(this.selected);
  }


  close() {
    this.dialogRef.close([]);
  }
}
