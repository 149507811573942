import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
import { MatCardModule as MatCardModule } from '@angular/material/card';
import { MatNativeDateModule, NativeDateModule, MatPseudoCheckboxModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule as MatInputModule } from '@angular/material/input';
import { MatListModule as MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule as MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule as MatFormFieldModule } from '@angular/material/form-field';
import { MomentDateModule } from '@angular/material-moment-adapter'
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule as MatTabsModule } from '@angular/material/tabs';
import { MatSliderModule as MatSliderModule } from '@angular/material/slider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule  } from '@angular/material/sort';
import { MatCheckboxModule as MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
    imports: [
        MatSortModule,
        CommonModule,
        MatButtonModule,
        MatToolbarModule,
        NativeDateModule,
        MatIconModule,
        MatSidenavModule,
        MatListModule,
        MatCardModule,
        MomentDateModule,
        MatInputModule,
        MatFormFieldModule,
        MatSliderModule,
        MatSelectModule,
        MatTableModule,
        MatCheckboxModule,
        MatTabsModule,
        MatDatepickerModule,
        MatPseudoCheckboxModule,
        MatExpansionModule,
        ],
    exports: [
        MatSortModule,
        CommonModule,
        MatDatepickerModule,
        MatButtonModule,
        MatToolbarModule,
        NativeDateModule,
        MatTabsModule,
        MomentDateModule,
        MatTableModule,
        MatIconModule,
        MatSidenavModule,
        MatListModule,
        MatSliderModule,
        MatFormFieldModule,
        NativeDateModule,
        MatCardModule,
        MatSelectModule,
        MatInputModule,
        MatPseudoCheckboxModule,
        ],
})
export class CustomMaterialModule { }
