import { Component,  OnInit, Input, Output, EventEmitter, OnChanges, forwardRef, ViewChild, ChangeDetectorRef} from '@angular/core';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { EventEmitterService } from 'src/services/events/events.service';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { AuthService } from 'src/services/auth/auth.service';

import { MatTableDataSource} from '@angular/material/table';
import { addPurchase, createPurchaseFromOrder, OrderTrack, Purchase } from '../../../../../bo9-shared/models/purchase/purchase.model';
import { getProductFromSKU, Product } from '../../../../../bo9-shared/models/product/product.model';
import { WooCommerceService } from 'src/services/woocommerce/woocommerce.service';
import { createWooCustomerFromPerson, createWooOrderFromPersonProduct, findExistingPurchaseIndexes, WooOrder, WooWebHookOrder } from '../../../../../bo9-shared/models/woocommerce/wooorder.model';
import { ProductsService } from 'src/services/products/products.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { dateFormating } from '../../../../../bo9-shared/helpers/time.helper';
import { MatPaginator} from '@angular/material/paginator';
import { WooDownload } from '../../../../../bo9-shared/models/woocommerce/woodownload.model';
import { userIsAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { Router } from '@angular/router';
import { allPersonEmails } from '../../../../../bo9-shared/models/contact/contact.model';
import { MatDialog } from '@angular/material/dialog';
import { SelectProductDialogComponent } from 'src/displayComponents/dialogs/selectProductDialog/selectProductDialog.component';
import { MatSort } from '@angular/material/sort';

// todo replace this with BO9Contact


@Component({
    selector: 'purchase-list',
    templateUrl: './purchaseList.component.html',
    styleUrls: ['./purchaseList.component.scss'],
    standalone: false
})

export class PurchaseListComponent implements OnInit {
  @Input() person: Person;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  edit: boolean=false;
  partner: boolean=false;
  admin: boolean=false;
  user:Person;
  loading = false;
  pageSize: number;
  products: Product[]=[];
  changed = false;
  orderTracks: OrderTrack[];
  public displayedColumns = ['products'];
  notAdminColumns = ['products'];
  adminColumns = ['products','setone','delete'];
  public displayedLIColumns = ['title', 'sku'];
  public dataSource = new MatTableDataSource<Purchase>();
  public lineItems = new MatTableDataSource<Product>();
  systemRoles: string[] = [];
  subscriptions: Subscription[] = [];
  constructor(    private router: Router,
    private arangoService: ArangoService,
    private auth: AuthService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private wooServices: WooCommerceService,
    private productService: ProductsService,
        private emitterService: EventEmitterService)
  {
    console.log('Purchases');
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'PurchaseListComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    this.subscriptions.push( this.productService.loadProducts()
    .subscribe(  (res: any) => {


      console.log('products'+ JSON.stringify(res))
      for (const p of res.content as Product[]) {
       console.log('product'+ JSON.stringify(p._key))
        this.products.push(p);
      }
    }
      ));
    if (userIsAdmin(this.user)) {
      this.displayedColumns = this.adminColumns;
    } else {
      this.displayedColumns = this.notAdminColumns;
    }

  }

  showKey (key: String) {
	  alert(key);
  }

  async onClickDelete(i: number) {
    console.log("Index i "+JSON.stringify(this.person.purchases[i]));
    if (confirm ('Confirm Delete of '+this.person.purchases[i].orderId)) {
      this.person.purchases.splice(i,1);
      const rese = await firstValueFrom(this.arangoService.updatePerson(this.person)); // No merge
      this.dataSource.data = this.person? this.person.purchases: [];
      this.dataSource.sort = this.sort;
    }
   
  }

  onClickAddProduct() {
    const dRef = this.dialog.open(SelectProductDialogComponent, {
      width: '75%',
      height: '75%',
      data: {products: this.products}
    });

    dRef.afterClosed()
    .subscribe( (result: Product[]) => {
      console.log('Selected Product is ' + JSON.stringify(result));
      this.wooServices.searchCustomers(this.person.primaryEmail)
      .then( async (wooCustomers: any[]) => {
        let woocustomer;
        if ( !(wooCustomers && wooCustomers.length> 0)) { //No customer for this user yet
          const makeCustomer = createWooCustomerFromPerson(this.person);
          console.log('New customer '+JSON.stringify(makeCustomer));
          const newCust = await firstValueFrom(this.wooServices.createCustomer(makeCustomer ));
          if (newCust && newCust.content) {
            if (typeof(newCust.content) == 'string' && newCust.content.includes("Create error")) {
              makeCustomer.username = makeCustomer.username+'.'+Math.trunc(Math.random()*100).toString(); // Try with username with random
              const newerCust = await firstValueFrom(this.wooServices.createCustomer(makeCustomer ));
              if (typeof(newerCust.content) == 'string' &&  newerCust.content.includes("Create error")) {
                alert('Cant create new customer, tried twice');
              } else {
                woocustomer = newCust.content.customer;
              }
            } else {
              woocustomer = newCust.content.customer;
            }

          } else {
            alert('Cant create new customer');
          }
        } else {
          woocustomer = wooCustomers[0];
        }
        if (woocustomer) {
          const order = createWooOrderFromPersonProduct(result[0], this.person, woocustomer,"1" );
          const newOrder = await firstValueFrom(this.wooServices.createOrder(order ));
          if (typeof(newOrder.content) == 'string' && newOrder.content.includes("error")) {
            this.emitterService.emitter.emit('Message','Order create error '+ JSON.stringify(newOrder.content));
            alert('Order create error '+ JSON.stringify(newOrder.content));
          } else {
            const completOrder = await firstValueFrom(this.wooServices.completeOrder(newOrder.content.id));
            this.emitterService.emitter.emit('Message','Order Completed ');
          }
        } else {
          this.emitterService.emitter.emit('Message','Order Create failed - no woo customer ');
        }
        this.loading = false;

      })
    })
  }

  isAdmin(): boolean {
    return(userIsAdmin(this.user));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  async onClickSetOne(i: number) {
    if (this.person.purchases && this.person.purchases[i]) {
      console.log("Index i "+JSON.stringify(this.person.purchases[i]));
   
      for (const o of this.person.purchases[i].order.orderProducts) { // set all q to 1 hack for now
        if (o.quantity) {
          o.quantity ++;
        } else {
          o.quantity = 1;
        }

      }
      
      const rese = await firstValueFrom(this.arangoService.updatePerson(this.person)); // No merge
      this.dataSource.data = this.person? this.person.purchases: [];
      this.dataSource.sort = this.sort;

    }
   
   
  }


 ngOnInit() {
  if (this.user.preferences) {
    this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
  }
  this.arangoService.getOrderTrackByPersonId(this.user._key)
  .subscribe ( (result) => {
    this.orderTracks  = result.content;
  })

 }

 async onClickSync() {
    this.loading = true;
   
    const eList = allPersonEmails(this.person.contact);
    if (eList && eList.length > 0) {
    let downloads:WooDownload[] =[];
    let wooOrder:WooOrder;
    let orders: WooWebHookOrder[]=[];
    const productsL   = await firstValueFrom(this.productService.loadProducts());
    const products: Product[] = productsL.content;

    for( const email of eList) {
      const ordersL = await firstValueFrom(this.wooServices.searchOrders(email));

      if (ordersL && ordersL.content && ordersL.content.length > 0) {
        wooOrder = ordersL.content[0];
        if (wooOrder.customer_id !== 0) {
          console.log('Wooorder '+ JSON.stringify(wooOrder))
          const thedwnloads:any =  await firstValueFrom(this.wooServices.loadDownload(wooOrder.customer_id))
          downloads = downloads.concat (...thedwnloads.content);
          console.log('downloads '+ JSON.stringify(downloads))
        }
  
      }
      orders = orders.concat (...ordersL.content);
    }
    
      
    for (const order of orders) {
      if (downloads && downloads.length  > 0) {
        for (const download of downloads) {
          if (download.order_key = order.order_key) {
            order.download = download;
          }
        }
      }
      for (const lineItem of order.line_items) {
          const matchedOrder = findExistingPurchaseIndexes(order, this.person);
          if (lineItem.sku) { // No sku no product
            const product: Product = getProductFromSKU(lineItem.sku, products);
        
          if (product.quantity === null && lineItem.quantity !== null) {
            product.quantity = lineItem.quantity;
          }

          if (matchedOrder.purchaseIndex < 0) {
            this.person.purchases = addPurchase(this.person.purchases,createPurchaseFromOrder([product], order));
          } else {
            this.person.purchases[matchedOrder.purchaseIndex].order.orderProducts[matchedOrder.orderProductIndex].quantity = lineItem.quantity;
            this.person.purchases[matchedOrder.purchaseIndex].order.orderKey = order.order_key; // Make sure it's set
            this.person.purchases[matchedOrder.purchaseIndex].order.download = order.download; // Make sure it's set
          }
      } else {
        console.log('Skip '+JSON.stringify(lineItem));
      }
    }
    if (this.person && !this.person.purchases) {
      this.person.purchases = [];
    }
    const rese = await firstValueFrom(this.arangoService.updatePerson(this.person)); // No merge
    }
  }
    
  this.dataSource.data = this.person? this.person.purchases: [];
  this.dataSource.sort = this.sort;
  this.loading = false;

 }

 onSubmit() {


 }
 ngAfterViewInit(): void {
	this.dataSource.paginator = this.paginator;

 }

  ngOnChanges() {
    console.log("CHANGE PF");

        this.dataSource.data = this.person? this.person.purchases: [];
        this.dataSource.sort = this.sort;
  
  }

  dateFormating(date: any, kind: string) {
	  return dateFormating(date, kind);
  }

}
