import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnChanges, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Observable, of, Subscription } from 'rxjs';
import { firstName, lastName, mkEmptyPerson, Person } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { contactFullName } from '../../../../../bo9-shared/models/contact/contact.model';
import { Invite,  FormInvite } from '../../../../../bo9-shared/models/person/invite.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { BO9UUID } from '../../../../../bo9-shared/models/UUID.model';
import { InviteDialogComponent } from '../../dialogs/inviteDialog/inviteDialog.component';
import { Invites } from 'src/pages/invites/invites.component';
import { InvitesService } from 'src/services/invites/invites.service';
import { SearchService } from 'src/services/search/search.service';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { JsonPipe } from '@angular/common';


@Component({
    selector: 'invite-list',
    templateUrl: './inviteList.component.html',
    styleUrls: ['./inviteList.component.scss'],
    standalone: false
})
export class InviteListComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() from: string;
  @Input() canAll: boolean;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public displayedColumns = ['edit','from','to',  'email', 'inviteType', 'pearlModule', 'naturalNumber','sourceType', 'delete'];
  public dataSource = new MatTableDataSource<FormInvite>();
  messagesLoading$: Observable<boolean>;

  currentPane = 'center';
  valid: false;

  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;

  currentI$: Observable<FormInvite>;
  currentPerson: Person;
  InviteForm: FormGroup;


  invites: FormInvite[] =[];
  subInvitor = false;

  validationMessages = validationMessages;

  PearlModuleTypes$: Observable<string[]>;
  subscriptions: Subscription[] = [];
  loading = false;
  user: Person;
  pageSize: number;
  constructor(
    private auth: AuthService,
    private router: Router,
    private arangoService: ArangoService,
    private dialog: MatDialog,
    private invitesService: InvitesService,
    private searchService: SearchService
    ) 
  {
    this.currentPane = 'center';
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'InviteListComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
  
  }

  ngOnInit() {
   //this.loadInvites();
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  ngOnChanges() {
    this.loading = false;
    console.log(' Invite  onchanges ');
    this.loadInvites();
  }

  onClickList(p:FormInvite) {
    console.log('List Invite  '+ JSON.stringify(p));
    this.from = p.fromPerson.primaryEmail;
    this.invitesService.getInvitesFromByEmail(p.fromPerson.primaryEmail)
    .subscribe( (result: FormInvite[]) => {
      this.dataSource.data = result;
    })

  }

  onClickEdit(p:FormInvite) {
    //console.log('Expand Invite  '+ JSON.stringify(p));

    this.currentI$ = of(p);
    this.currentPane = 'left';
  }

  inviteSubmittedHandler($event: any) {
    console.log(' Invite  submitted '+ JSON.stringify($event));
    this.loadInvites(true);
    this.currentPane = 'center';

  }

  loadInvites(force?: boolean) {
    this.loading = true;
    this.dataSource.data = [];
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
    console.log(' Loadinvites    '+ JSON.stringify(this.from));
    if ( this.from && this.from !== '') {
      this.invitesService.getInvitesFromByEmail(this.from, force)
      .subscribe( (result: FormInvite[]) => {
        this.loading = false;
        this.dataSource.data = result;

      })
    } else  if (this.canAll) {
      this.invitesService.getInvites(force)
      .then( (result: FormInvite[]) => {
        this.loading = false;
        this.dataSource.data = result;

      })
    } else {
      this.loading = false;
    }


  }

  onClickDelete(p: Invite) {
    const dRef = this.dialog.open(InviteDialogComponent, {
      width: '55%',
      height: '25%',
      data: {invite: p}
    });

    dRef.afterClosed()
    .subscribe( (result: Invite) => {
      console.log('Selected delete result is ' + JSON.stringify(result));
      if (result) {
        this.invitesService.deleteInvite(result, this.from)
        .subscribe( (res) => {
          alert("Invite deleted");
        })
    }

    })
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

  onClickInvite() {
    this.currentPane = 'left';
    this.searchService.searchEmail(this.from, false)
    .subscribe( (persons: Person[]) => {
      console.log("adding email " + JSON.stringify(persons));
      const newFormInvite: FormInvite = {
        
        _key: createUUID(),
        fromFirstName: firstName(persons[0]),
        fromLastName: lastName(persons[0]),
        fromPerson: persons[0]
      }
      this.currentI$ = of(newFormInvite);
    })


  }



  onRefreshClick() {
    this.loading = false;
    if (this.canAll) {
      this.from='';
    }
    this.loadInvites(true);
  }
  onPageChange(event){
    console.log(event);
  }

  

  onCloseClick() {
    this.currentPane = 'center';
  }


  personSubmittedHandler($event: any) {
    console.log(' Person  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';
  }

  relateSubmittedHandler($event: any) {
    console.log(' Relate  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';
  }

}
