
<my-slide-panel [activePane]="currentPane">

	<section leftPane>
		<button mat-stroked-button (click)="onCloseClick()">Back<mat-icon>arrow_back</mat-icon></button>
		<pearl-form [pearl]="selectedPearl$ | async" (notifyParent)="pearlSubmittedHandler($event)"></pearl-form>
	</section>

	<section centerPane>
		<button mat-stroked-button (click)="addPearlClick()" matTooltip="Add a pearl">Add<mat-icon>add_box</mat-icon></button>
		<button mat-stroked-button (click)="onRefreshClick()">Refresh<mat-icon>refresh</mat-icon></button>
		<pearls-info [pCount]="pearlCount"></pearls-info>
		<mat-card class="bo9mat">
			<h4>Loading Pearls From a File (format N:N:SOC:PWBP:Text)</h4>
			<form [formGroup]="PearlForm" (ngSubmit)="submitPearlForm()" class="Pearl-form" novalidate >
				Formatted Pearls File:
				<mat-form-field>
					<ngx-mat-file-input2 [formControl]="fileControl" ></ngx-mat-file-input2>
				</mat-form-field> &nbsp;
				<mat-form-field>
					<mat-label>Pearls Title</mat-label>
					<input matInput formControlName="title" id="title" />
  				</mat-form-field>
  				<button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Load File</button>
			</form>
		</mat-card>
		<pearl-list [pList]="pearls" [searchable]="true" (notifyParent)="pearlEditHandler($event)"></pearl-list>
	</section>

	<section rightPane>

	</section>
</my-slide-panel>
