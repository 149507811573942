import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';


import { ActivatedRoute } from '@angular/router';

import { AuthService } from 'src/services/auth/auth.service';

import { ChatRoomService } from 'src/services/chatmessageservice/chatroom.service';
import { Router } from '@angular/router';

import { forkJoin, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Group } from '../../../../../bo9-shared/models/person/group.model';
import { BO9UUID } from '../../../../../bo9-shared/models/UUID.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';

@Component({
    selector: 'app-dialog-group',
    templateUrl: './groupDeleteDialog.component.html',
    styleUrls: ['./groupDeleteDialog.component.scss'],
    standalone: false
})

export class groupDeleteDialogComponent implements OnInit  {
  disableBtn = false;
  user: Person;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private dialogRef: MatDialogRef<groupDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: { group: any } ,
    private arangoService: ArangoService,private router: Router,
   ) {
      console.log ('Init group del ' + JSON.stringify(data));
      this.user = this.auth.getAuthUser();
      this.subscriptions.forEach(s => s.unsubscribe());
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'groupDeleteDialogComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
     }

  ngOnInit() {

    console.log ('Init group delete init ' + JSON.stringify(this.data));

  }


  deleteSelectGroup(p: Group ) {
    this.dialogRef.close(p);
  }

  close() {
    this.dialogRef.close();
  }
}
