import { Component, OnInit,  Input, ViewChild, OnChanges, Output, EventEmitter, OnDestroy, AfterViewInit, AfterContentInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person} from '../../../../../bo9-shared/models/person/person.model';
import { formPerson, makeFormPersonFromPerson } from '../../../../../bo9-shared/models/person/formPerson.model';
import { formGroup, Group, GroupMembership, MemberIsAdmin } from '../../../../../bo9-shared/models/person/group.model';
import { validationMessages } from 'src/helpers/form.helper';
import { contactFullName } from '../../../../../bo9-shared/models/contact/contact.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { createUUID, UUIDToID } from '../../../../../bo9-shared/uuid.service';
import { GroupsService } from 'src/services/groups/groups.service';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { groupDeleteDialogComponent } from 'src/displayComponents/dialogs/groupDialog/groupDeleteDialog.component';
import { SelectPersonDialogComponent } from 'src/displayComponents/dialogs/selectPersonDialog/selectPersonDialog.component';
import { EventEmitterService } from 'src/services/events/events.service';
import { userIsAdmin, userIsJourneyman } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { InvitedStatus } from '../../../../../bo9-shared/models/person/invite.model';
import { MemberService } from 'src/services/memberService/member.service';
import { IdentificationMethod, ProgramDefinition, ProgramNotification } from '../../../../../bo9-shared/models/program/program.model';
import { Member, AffiliateByID, createCouponCode } from '../../../../../bo9-shared/models/member/member.model';
import { Router } from '@angular/router';
import { SystemVariablesService } from 'src/services/systemvariables/systemvariables.service';
import { EventCounts } from '../../../../../bo9-shared/models/event/event.model';
import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';
import { ProductsService } from 'src/services/products/products.service';
import { SelectIdentificationDialogComponent } from 'src/displayComponents/dialogs/selectIdentificationDialog/selectIdentificationDialog.component';
import { OrderTrack } from '../../../../../bo9-shared/models/purchase/purchase.model';
import { urlFromDownload } from '../../../../../bo9-shared/models/woocommerce/woodownload.model';



@Component({
    selector: 'clientmember-list',
    templateUrl: './clientMemberList.component.html',
    styleUrls: ['./clientMemberList.component.scss'],
    standalone: false
})
export class ClientMemberListComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() person: Person;
  @Input() inputGroup: formGroup;
  @Input() client: boolean;
  @Input() eventCounts: EventCounts;
  @Input() canMEA: boolean;
  @Input() packages: ProgramNotification[];
  @Input() prepaidCount: number;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  public displayedColumns = []; // Dont need to edit - can add from the dialog and edit directly 10/23/21


  displayClient = ['name','email','status','nn','calendly','delete'];
  displayOperNNId = ['name','email','status','nn','calendly','delete'];
  displayPrePaid = ['name','email','link','revoke','delete'];
  public dataSource = new MatTableDataSource<GroupMembership>();
  messagesLoading$: Observable<boolean>;

  user: Person;
  formPerson: formPerson;
  valid: false;

  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;
  editing = false;

  owner: string;
  currentI$: Observable<Group>;
  currentPerson: Person;
  GroupForm: formGroup;
  member: GroupMembership;
  loading = false;
  fieldValues = 0;
  pageSize: number;
  couponCode: string='';

  url = '';

  validationMessages = validationMessages;
  subscriptions: Subscription[] = [];

  PearlModuleTypes$: Observable<string[]>;
  idPackageCountRemaining =0;

  constructor(
    private router: Router,
    private arangoService: ArangoService,
    private groupService: GroupsService,
    private systemVariablesService: SystemVariablesService,
    private dialog: MatDialog,
    private auth: AuthService,
    private productsService: ProductsService,
    private actCamService: ActiveCampaignService,
    private emitterService: EventEmitterService,
    ) 
  {
    this.user = this.auth.getAuthUser();
    if (!this.person) {
      this.person = this.user;
    }
    this.formPerson = makeFormPersonFromPerson(this.person);
    this.dataSource.data = [];

    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'ClientMemberListComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe({
      })
              
    ); 
    this.couponCode = '';
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.couponCode='';
  }


  /*
   [download_url] => https://bodyof9.com/calendly/?id=rQUkAwwRU0gOy&download_file=243004&order=wc_order_rQUkAwwRU0gOy&uid=2876956ea2e0c91493c06c1f3f9e1a0c71311abb0db6be49cd5c5a8bc8aacfb2&key=afcbe737-d0fe-49c5-9f89-465e2f38d738
            [download_id] => afcbe737-d0fe-49c5-9f89-465e2f38d738
            [product_id] => 243004
            [product_name] => Mastery Program - Full Pay
            [product_url] => https://bodyof9.com/product/partner-program-full-pay/
            [download_name] => Natural Number 9 Activation Guide
            [order_id] => 243644
            [order_key] => wc_order_rQUkAwwRU0gOy
            [downloads_remaining] =>
            [access_expires] =>
            [file] => Array
                (
                    [name] => Natural Number 9 Activation Guide
                    [file] => https://bodyof9.com/wp-content/uploads/2021/11/nn9-body-9-activationguiderev3.pdf
                )

        )
*/

async  load(refresh:boolean) {
    if (!this.loading) {

      this.loading = true;
    if (this.packages) {
      
      for (const p of this.packages) {
        if (p.idMethod === IdentificationMethod.Identification) {
          this.idPackageCountRemaining++;
        }
      }
    }

    if (this.client) {
      this.displayedColumns = this.displayClient;
    } else {
      this.displayedColumns = this.displayPrePaid;
    }




    let name = '';
    
    if (this.person && this.person.contact && this.person.contact.names && this.person.contact.names.name && this.person.contact.names.name.first) {
      name = this.person.contact.names.name.first.charAt(0)+this.person.contact.names.name.last;
    }

    if (this.person && this.owner != name ) {
      this.owner = name;
      this.couponCode = '';
      const cc = await  firstValueFrom(this.productsService.getUserCoupon(this.person.primaryEmail));

        let uc: any;
        if (this.person) { // Yes here too.
          uc = await firstValueFrom(this.productsService.searchCoupon(name));
        }
        if (cc.content && cc.content.length> 0) {
          let title='';
          let amount='';
          let discount_type = '';
          for (const ccode of cc.content) {
            if (ccode.post_title !== title) {
              title=ccode.post_title;
            };
            if (ccode.meta_key ==='coupon_amount') {
              amount = ccode.meta_value;
            }
            if (ccode.meta_key ==='discount_type') {
              if (ccode.meta_value === 'percent' ){
                discount_type = '%';
              } else {
                discount_type = ' '+ccode.meta_value;
              }
              
              if (this.couponCode != '') {
                this.couponCode = this.couponCode+', ';
              }
              
            }
            
          }
          this.couponCode = title+'('+amount+ discount_type+') ';
          
        }
        if (uc && uc.content && uc.content.length >0) {
          if (uc.content[0].discount_type) {
            uc.content[0].discount_type='%';
          }
          if (this.couponCode != '') {
            this.couponCode += ', ';
          }
          this.couponCode += uc.content[0].code+'('+uc.content[0].amount+ uc.content[0].discount_type+') ';
        }
        console.log('coupon info '+JSON.stringify(this.couponCode));
      }

    console.log(' Member  '+ JSON.stringify(this.inputGroup));
    if (this.inputGroup  && !refresh) {
      console.log('Members '+ JSON.stringify(this.inputGroup.members));
      this.dataSource.data = this.inputGroup.members;
    } else if (refresh && this.inputGroup) {
      const members:GroupMembership[] = await  this.groupService.getGroupMembers(this.inputGroup.group)
        this.inputGroup.members = members;
          const memberList:GroupMembership[] =[];
          for (const member of members) {
            if (member._key === this.inputGroup.group.createdBy  || member.admin) {
              this.inputGroup.isAdmin = true;
            } else {
              this.inputGroup.isAdmin = false;
            }
            if (this.client && member.invitedStatus == null ) {
              member.invitedStatus = InvitedStatus.Accepted;
            } else {
              const ep = await firstValueFrom(this.arangoService.getEventParams(member.invitedPerson.id));
              if (ep && ep.content) {
                member.eventParams = ep.content[0];
              }
            }
            member.formPerson = makeFormPersonFromPerson(member.person);
            memberList.push(member);
          }
          this.dataSource.data = memberList;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
    } 
    this.loading = false;
  }

  }

  onChangePage(event) {
    this.pageSize = event.pageSize;
  }

  async addCouponCode() {
    console.log("Add coupon code");
    const code= createCouponCode(this.person.contact);
    const couponid = await firstValueFrom(this.productsService.setCoupon(this.person.primaryEmail, code, "20"));
    if (couponid.content.code && couponid.content.data && couponid.content.data.status == "400") {
      this.emitterService.emitter.emit('Message','Coupon Add failed '+JSON.stringify(couponid.content.code));
    } else {
      this.emitterService.emitter.emit('Message','Coupon Added ');
    }
    
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.load(true);
    }
  }

  assignLink(p: Person) {// For clients??
    const dRef = this.dialog.open(SelectIdentificationDialogComponent, {
      width: '75%',
      height: '75%',
      data: {packages: this.packages, p: p}
    });

    dRef.afterClosed()
    .subscribe( async (result: ProgramNotification) => {
      console.log('Selected people is ' + JSON.stringify(result));
      if (result) {
        const orderTrack: OrderTrack = {
          personId: p._key,
          orderId: result.order.orderId,
          productId: result.product._key,
          meta: updateMeta(),
          quantityUsed: 1,
          resetCount: false,
          notes: "Identification Applied",
          appliedTo: p
        }
        const ot = await firstValueFrom(this.arangoService.addOrderTrack(orderTrack));
        const name = p.contact.names? (p.contact.names.name.first+';'+p.contact.names.name.last): '';
          p.naturalNumbers.invited.idLink =  this.systemVariablesService.getSystemVariableArray('IdentificationURL')+'/identification'+urlFromDownload(result.order, p.primaryEmail+';'+name)
          const res = await firstValueFrom(this.arangoService.updatePerson(p));
          
        }
        if (this.user.preferences && this.user.preferences.autoRefresh) {
          this.load(true);
        }
      });
  }


  ngOnChanges() {
    this.loading = false;
    this.load(true);
  }


  
  isAdmin(): boolean {
    return(userIsAdmin(this.user));
  }

  isJourneyman(): boolean {
    return(userIsJourneyman(this.user));
  }

  isGroupAdmin(): boolean {
    // console.log (`is Group Admin ${this.user._key} members ` + JSON.stringify(this.inputGroup.members));
    return(MemberIsAdmin(this.inputGroup, this.user._key));
  }

  ngAfterViewInit(): void {
    this.load(true);
  }


  onClickInvite() {
    this.editing = true;
  }


  onCloseClick() {
    this.editing = false;
  }

  onClickLeave(p:GroupMembership) {
    console.log('Leave  '+ JSON.stringify(p));

    if (confirm("Please confirm this person should leave")) {
      this.groupService.leaveGroup(p.from, p.to, p.groupOwner)
      .subscribe( (res) => {
        console.log('Save result  is: ' + JSON.stringify(res.content));
        this.emitterService.emitter.emit('Message','Member Reomved ' + contactFullName(p.person.contact)+ ' saved');
        if (this.user.preferences && this.user.preferences.autoRefresh) {
          this.load(true);
        }
      });
    }
  }

  memberSubmittedHandler($event) {
    console.log('memberSubmitted  '+ JSON.stringify($event));
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.load(true);
    }
    this.editing = false;
  }

  onBackClick() {
    if (this.notifyParent) {
      this.notifyParent.emit('Members Done');
    }

  }

  onClickRevoke(p:GroupMembership) {
    console.log('Revoke');
    if (confirm("Please confirm the revoking")) {
      this.subscriptions.push(this.actCamService.revoke(p.invitedPerson.inviteDeal.id, p.invitedPerson.id)
      .subscribe( (result) => {
        console.log('Revoke result'+JSON.stringify(result));
        if (this.user.preferences && this.user.preferences.autoRefresh) {
          this.load(true);
        }
      }))
    }

  }
 
  onClickDelete(p: Group) {
    const dRef = this.dialog.open(groupDeleteDialogComponent, {
      width: '55%',
      height: '25%',
      data: {group: p}
    });

    dRef.afterClosed()
    .subscribe( (result: Group) => {
      console.log('Selected result is ' + JSON.stringify(result));
      if (result) {

        this.groupService.deleteGroup(result)
        .subscribe( (res) => {
          alert("Group deleted");
          if (this.user.preferences && this.user.preferences.autoRefresh) {
            this.load(true);
          }
        })

    }

    })
  }


  onClickAdd(p: formGroup) {

    const dRef = this.dialog.open(SelectPersonDialogComponent, {
      width: '75%',
      height: '75%',
      data: {group: p}
    });

    dRef.afterClosed()
    .subscribe( async (result: formPerson[]) => {
      console.log('Selected people is ' + JSON.stringify(result));
      if (result  && result.length >0) {
        for (const fp of result) {
           const gm = {
            _key: createUUID(),
            from: p._key,
            to: fp._key,
            groupOwner: p.createdBy, 
            primaryContact: fp.primaryContact,
            admin: fp.admin,
            invitedStatus: InvitedStatus.Accepted, // Added by admin can't decline :)
            meta: updateMeta()
  
          }
          const res = await firstValueFrom(this.groupService.updateGroupMembership(gm));
          
        }
        if (this.user.preferences && this.user.preferences.autoRefresh) {
          this.load(true);
        }
      }
      
    })

  }


  makeForm() {

  }


  ngOnInit() {
    //this.loadRelations();

  }


  onRefreshClick() {
    this.load(true);
  }
  onPageChange(event){
    console.log(event);
  }

}
