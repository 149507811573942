function t() {
  return t = Object.assign || function (t) {
    for (var e = 1; e < arguments.length; e++) {
      var r = arguments[e];
      for (var n in r) Object.prototype.hasOwnProperty.call(r, n) && (t[n] = r[n]);
    }
    return t;
  }, t.apply(this, arguments);
}
function e(t) {
  var e = {
    exports: {}
  };
  return t(e, e.exports), e.exports;
}
"undefined" != typeof globalThis ? globalThis : "undefined" != typeof window ? window : "undefined" != typeof global ? global : "undefined" != typeof self && self;
var r = e(function (t, e) {
    t.exports = function () {
      var t = "millisecond",
        e = "second",
        r = "minute",
        n = "hour",
        i = "day",
        a = "week",
        o = "month",
        s = "quarter",
        u = "year",
        c = "date",
        l = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[^0-9]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/,
        f = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g,
        d = {
          name: "en",
          weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
          months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_")
        },
        h = function (t, e, r) {
          var n = String(t);
          return !n || n.length >= e ? t : "" + Array(e + 1 - n.length).join(r) + t;
        },
        y = {
          s: h,
          z: function (t) {
            var e = -t.utcOffset(),
              r = Math.abs(e),
              n = Math.floor(r / 60),
              i = r % 60;
            return (e <= 0 ? "+" : "-") + h(n, 2, "0") + ":" + h(i, 2, "0");
          },
          m: function t(e, r) {
            if (e.date() < r.date()) return -t(r, e);
            var n = 12 * (r.year() - e.year()) + (r.month() - e.month()),
              i = e.clone().add(n, o),
              a = r - i < 0,
              s = e.clone().add(n + (a ? -1 : 1), o);
            return +(-(n + (r - i) / (a ? i - s : s - i)) || 0);
          },
          a: function (t) {
            return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
          },
          p: function (l) {
            return {
              M: o,
              y: u,
              w: a,
              d: i,
              D: c,
              h: n,
              m: r,
              s: e,
              ms: t,
              Q: s
            }[l] || String(l || "").toLowerCase().replace(/s$/, "");
          },
          u: function (t) {
            return void 0 === t;
          }
        },
        p = "en",
        m = {};
      m[p] = d;
      var $ = function (t) {
          return t instanceof O;
        },
        g = function (t, e, r) {
          var n;
          if (!t) return p;
          if ("string" == typeof t) m[t] && (n = t), e && (m[t] = e, n = t);else {
            var i = t.name;
            m[i] = t, n = i;
          }
          return !r && n && (p = n), n || !r && p;
        },
        v = function (t, e) {
          if ($(t)) return t.clone();
          var r = "object" == typeof e ? e : {};
          return r.date = t, r.args = arguments, new O(r);
        },
        D = y;
      D.l = g, D.i = $, D.w = function (t, e) {
        return v(t, {
          locale: e.$L,
          utc: e.$u,
          x: e.$x,
          $offset: e.$offset
        });
      };
      var O = function () {
          function d(t) {
            this.$L = g(t.locale, null, !0), this.parse(t);
          }
          var h = d.prototype;
          return h.parse = function (t) {
            this.$d = function (t) {
              var e = t.date,
                r = t.utc;
              if (null === e) return new Date(NaN);
              if (D.u(e)) return new Date();
              if (e instanceof Date) return new Date(e);
              if ("string" == typeof e && !/Z$/i.test(e)) {
                var n = e.match(l);
                if (n) {
                  var i = n[2] - 1 || 0,
                    a = (n[7] || "0").substring(0, 3);
                  return r ? new Date(Date.UTC(n[1], i, n[3] || 1, n[4] || 0, n[5] || 0, n[6] || 0, a)) : new Date(n[1], i, n[3] || 1, n[4] || 0, n[5] || 0, n[6] || 0, a);
                }
              }
              return new Date(e);
            }(t), this.$x = t.x || {}, this.init();
          }, h.init = function () {
            var t = this.$d;
            this.$y = t.getFullYear(), this.$M = t.getMonth(), this.$D = t.getDate(), this.$W = t.getDay(), this.$H = t.getHours(), this.$m = t.getMinutes(), this.$s = t.getSeconds(), this.$ms = t.getMilliseconds();
          }, h.$utils = function () {
            return D;
          }, h.isValid = function () {
            return !("Invalid Date" === this.$d.toString());
          }, h.isSame = function (t, e) {
            var r = v(t);
            return this.startOf(e) <= r && r <= this.endOf(e);
          }, h.isAfter = function (t, e) {
            return v(t) < this.startOf(e);
          }, h.isBefore = function (t, e) {
            return this.endOf(e) < v(t);
          }, h.$g = function (t, e, r) {
            return D.u(t) ? this[e] : this.set(r, t);
          }, h.unix = function () {
            return Math.floor(this.valueOf() / 1e3);
          }, h.valueOf = function () {
            return this.$d.getTime();
          }, h.startOf = function (t, s) {
            var l = this,
              f = !!D.u(s) || s,
              d = D.p(t),
              h = function (t, e) {
                var r = D.w(l.$u ? Date.UTC(l.$y, e, t) : new Date(l.$y, e, t), l);
                return f ? r : r.endOf(i);
              },
              y = function (t, e) {
                return D.w(l.toDate()[t].apply(l.toDate("s"), (f ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(e)), l);
              },
              p = this.$W,
              m = this.$M,
              $ = this.$D,
              g = "set" + (this.$u ? "UTC" : "");
            switch (d) {
              case u:
                return f ? h(1, 0) : h(31, 11);
              case o:
                return f ? h(1, m) : h(0, m + 1);
              case a:
                var v = this.$locale().weekStart || 0,
                  O = (p < v ? p + 7 : p) - v;
                return h(f ? $ - O : $ + (6 - O), m);
              case i:
              case c:
                return y(g + "Hours", 0);
              case n:
                return y(g + "Minutes", 1);
              case r:
                return y(g + "Seconds", 2);
              case e:
                return y(g + "Milliseconds", 3);
              default:
                return this.clone();
            }
          }, h.endOf = function (t) {
            return this.startOf(t, !1);
          }, h.$set = function (a, s) {
            var l,
              f = D.p(a),
              d = "set" + (this.$u ? "UTC" : ""),
              h = (l = {}, l[i] = d + "Date", l[c] = d + "Date", l[o] = d + "Month", l[u] = d + "FullYear", l[n] = d + "Hours", l[r] = d + "Minutes", l[e] = d + "Seconds", l[t] = d + "Milliseconds", l)[f],
              y = f === i ? this.$D + (s - this.$W) : s;
            if (f === o || f === u) {
              var p = this.clone().set(c, 1);
              p.$d[h](y), p.init(), this.$d = p.set(c, Math.min(this.$D, p.daysInMonth())).$d;
            } else h && this.$d[h](y);
            return this.init(), this;
          }, h.set = function (t, e) {
            return this.clone().$set(t, e);
          }, h.get = function (t) {
            return this[D.p(t)]();
          }, h.add = function (t, s) {
            var c,
              l = this;
            t = Number(t);
            var f = D.p(s),
              d = function (e) {
                var r = v(l);
                return D.w(r.date(r.date() + Math.round(e * t)), l);
              };
            if (f === o) return this.set(o, this.$M + t);
            if (f === u) return this.set(u, this.$y + t);
            if (f === i) return d(1);
            if (f === a) return d(7);
            var h = (c = {}, c[r] = 6e4, c[n] = 36e5, c[e] = 1e3, c)[f] || 1,
              y = this.$d.getTime() + t * h;
            return D.w(y, this);
          }, h.subtract = function (t, e) {
            return this.add(-1 * t, e);
          }, h.format = function (t) {
            var e = this;
            if (!this.isValid()) return "Invalid Date";
            var r = t || "YYYY-MM-DDTHH:mm:ssZ",
              n = D.z(this),
              i = this.$locale(),
              a = this.$H,
              o = this.$m,
              s = this.$M,
              u = i.weekdays,
              c = i.months,
              l = function (t, n, i, a) {
                return t && (t[n] || t(e, r)) || i[n].substr(0, a);
              },
              d = function (t) {
                return D.s(a % 12 || 12, t, "0");
              },
              h = i.meridiem || function (t, e, r) {
                var n = t < 12 ? "AM" : "PM";
                return r ? n.toLowerCase() : n;
              },
              y = {
                YY: String(this.$y).slice(-2),
                YYYY: this.$y,
                M: s + 1,
                MM: D.s(s + 1, 2, "0"),
                MMM: l(i.monthsShort, s, c, 3),
                MMMM: l(c, s),
                D: this.$D,
                DD: D.s(this.$D, 2, "0"),
                d: String(this.$W),
                dd: l(i.weekdaysMin, this.$W, u, 2),
                ddd: l(i.weekdaysShort, this.$W, u, 3),
                dddd: u[this.$W],
                H: String(a),
                HH: D.s(a, 2, "0"),
                h: d(1),
                hh: d(2),
                a: h(a, o, !0),
                A: h(a, o, !1),
                m: String(o),
                mm: D.s(o, 2, "0"),
                s: String(this.$s),
                ss: D.s(this.$s, 2, "0"),
                SSS: D.s(this.$ms, 3, "0"),
                Z: n
              };
            return r.replace(f, function (t, e) {
              return e || y[t] || n.replace(":", "");
            });
          }, h.utcOffset = function () {
            return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
          }, h.diff = function (t, c, l) {
            var f,
              d = D.p(c),
              h = v(t),
              y = 6e4 * (h.utcOffset() - this.utcOffset()),
              p = this - h,
              m = D.m(this, h);
            return m = (f = {}, f[u] = m / 12, f[o] = m, f[s] = m / 3, f[a] = (p - y) / 6048e5, f[i] = (p - y) / 864e5, f[n] = p / 36e5, f[r] = p / 6e4, f[e] = p / 1e3, f)[d] || p, l ? m : D.a(m);
          }, h.daysInMonth = function () {
            return this.endOf(o).$D;
          }, h.$locale = function () {
            return m[this.$L];
          }, h.locale = function (t, e) {
            if (!t) return this.$L;
            var r = this.clone(),
              n = g(t, e, !0);
            return n && (r.$L = n), r;
          }, h.clone = function () {
            return D.w(this.$d, this);
          }, h.toDate = function () {
            return new Date(this.valueOf());
          }, h.toJSON = function () {
            return this.isValid() ? this.toISOString() : null;
          }, h.toISOString = function () {
            return this.$d.toISOString();
          }, h.toString = function () {
            return this.$d.toUTCString();
          }, d;
        }(),
        M = O.prototype;
      return v.prototype = M, [["$ms", t], ["$s", e], ["$m", r], ["$H", n], ["$W", i], ["$M", o], ["$y", u], ["$D", c]].forEach(function (t) {
        M[t[1]] = function (e) {
          return this.$g(e, t[0], t[1]);
        };
      }), v.extend = function (t, e) {
        return t.$i || (t(e, O, v), t.$i = !0), v;
      }, v.locale = g, v.isDayjs = $, v.unix = function (t) {
        return v(1e3 * t);
      }, v.en = m[p], v.Ls = m, v.p = {}, v;
    }();
  }),
  n = e(function (t, e) {
    t.exports = function (t, e, r) {
      var n = e.prototype;
      r.utc = function (t) {
        return new e({
          date: t,
          utc: !0,
          args: arguments
        });
      }, n.utc = function (t) {
        var e = r(this.toDate(), {
          locale: this.$L,
          utc: !0
        });
        return t ? e.add(this.utcOffset(), "minute") : e;
      }, n.local = function () {
        return r(this.toDate(), {
          locale: this.$L,
          utc: !1
        });
      };
      var i = n.parse;
      n.parse = function (t) {
        t.utc && (this.$u = !0), this.$utils().u(t.$offset) || (this.$offset = t.$offset), i.call(this, t);
      };
      var a = n.init;
      n.init = function () {
        if (this.$u) {
          var t = this.$d;
          this.$y = t.getUTCFullYear(), this.$M = t.getUTCMonth(), this.$D = t.getUTCDate(), this.$W = t.getUTCDay(), this.$H = t.getUTCHours(), this.$m = t.getUTCMinutes(), this.$s = t.getUTCSeconds(), this.$ms = t.getUTCMilliseconds();
        } else a.call(this);
      };
      var o = n.utcOffset;
      n.utcOffset = function (t, e) {
        var r = this.$utils().u;
        if (r(t)) return this.$u ? 0 : r(this.$offset) ? o.call(this) : this.$offset;
        var n = Math.abs(t) <= 16 ? 60 * t : t,
          i = this;
        if (e) return i.$offset = n, i.$u = 0 === t, i;
        if (0 !== t) {
          var a = this.$u ? this.toDate().getTimezoneOffset() : -1 * this.utcOffset();
          (i = this.local().add(n + a, "minute")).$offset = n, i.$x.$localOffset = a;
        } else i = this.utc();
        return i;
      };
      var s = n.format;
      n.format = function (t) {
        return s.call(this, t || (this.$u ? "YYYY-MM-DDTHH:mm:ss[Z]" : ""));
      }, n.valueOf = function () {
        var t = this.$utils().u(this.$offset) ? 0 : this.$offset + (this.$x.$localOffset || new Date().getTimezoneOffset());
        return this.$d.valueOf() - 6e4 * t;
      }, n.isUTC = function () {
        return !!this.$u;
      }, n.toISOString = function () {
        return this.toDate().toISOString();
      }, n.toString = function () {
        return this.toDate().toUTCString();
      };
      var u = n.toDate;
      n.toDate = function (t) {
        return "s" === t && this.$offset ? r(this.format("YYYY-MM-DD HH:mm:ss:SSS")).toDate() : u.call(this);
      };
      var c = n.diff;
      n.diff = function (t, e, n) {
        if (t && this.$u === t.$u) return c.call(this, t, e, n);
        var i = this.local(),
          a = r(t).local();
        return c.call(i, a, e, n);
      };
    };
  }),
  i = new RegExp("%[a-f0-9]{2}", "gi"),
  a = new RegExp("(%[a-f0-9]{2})+", "gi");
function o(t, e) {
  try {
    return decodeURIComponent(t.join(""));
  } catch (t) {}
  if (1 === t.length) return t;
  var r = t.slice(0, e = e || 1),
    n = t.slice(e);
  return Array.prototype.concat.call([], o(r), o(n));
}
function s(t) {
  try {
    return decodeURIComponent(t);
  } catch (n) {
    for (var e = t.match(i), r = 1; r < e.length; r++) e = (t = o(e, r).join("")).match(i);
    return t;
  }
}
var u = function (t) {
    if ("string" != typeof t) throw new TypeError("Expected `encodedURI` to be of type `string`, got `" + typeof t + "`");
    try {
      return t = t.replace(/\+/g, " "), decodeURIComponent(t);
    } catch (e) {
      return function (t) {
        for (var e = {
            "%FE%FF": "��",
            "%FF%FE": "��"
          }, r = a.exec(t); r;) {
          try {
            e[r[0]] = decodeURIComponent(r[0]);
          } catch (t) {
            var n = s(r[0]);
            n !== r[0] && (e[r[0]] = n);
          }
          r = a.exec(t);
        }
        e["%C2"] = "�";
        for (var i = Object.keys(e), o = 0; o < i.length; o++) {
          var u = i[o];
          t = t.replace(new RegExp(u, "g"), e[u]);
        }
        return t;
      }(t);
    }
  },
  c = (t, e) => {
    if ("string" != typeof t || "string" != typeof e) throw new TypeError("Expected the arguments to be of type `string`");
    if ("" === e) return [t];
    const r = t.indexOf(e);
    return -1 === r ? [t] : [t.slice(0, r), t.slice(r + e.length)];
  },
  l = function (t, e) {
    for (var r = {}, n = Object.keys(t), i = Array.isArray(e), a = 0; a < n.length; a++) {
      var o = n[a],
        s = t[o];
      (i ? -1 !== e.indexOf(o) : e(o, s, t)) && (r[o] = s);
    }
    return r;
  },
  f = e(function (t, e) {
    function r(t) {
      if ("string" != typeof t || 1 !== t.length) throw new TypeError("arrayFormatSeparator must be single character string");
    }
    function n(t, e) {
      return e.encode ? e.strict ? encodeURIComponent(t).replace(/[!'()*]/g, t => `%${t.charCodeAt(0).toString(16).toUpperCase()}`) : encodeURIComponent(t) : t;
    }
    function i(t, e) {
      return e.decode ? u(t) : t;
    }
    function a(t) {
      return Array.isArray(t) ? t.sort() : "object" == typeof t ? a(Object.keys(t)).sort((t, e) => Number(t) - Number(e)).map(e => t[e]) : t;
    }
    function o(t) {
      const e = t.indexOf("#");
      return -1 !== e && (t = t.slice(0, e)), t;
    }
    function s(t) {
      const e = (t = o(t)).indexOf("?");
      return -1 === e ? "" : t.slice(e + 1);
    }
    function f(t, e) {
      return e.parseNumbers && !Number.isNaN(Number(t)) && "string" == typeof t && "" !== t.trim() ? t = Number(t) : !e.parseBooleans || null === t || "true" !== t.toLowerCase() && "false" !== t.toLowerCase() || (t = "true" === t.toLowerCase()), t;
    }
    function d(t, e) {
      r((e = Object.assign({
        decode: !0,
        sort: !0,
        arrayFormat: "none",
        arrayFormatSeparator: ",",
        parseNumbers: !1,
        parseBooleans: !1
      }, e)).arrayFormatSeparator);
      const n = function (t) {
          let e;
          switch (t.arrayFormat) {
            case "index":
              return (t, r, n) => {
                e = /\[(\d*)\]$/.exec(t), t = t.replace(/\[\d*\]$/, ""), e ? (void 0 === n[t] && (n[t] = {}), n[t][e[1]] = r) : n[t] = r;
              };
            case "bracket":
              return (t, r, n) => {
                e = /(\[\])$/.exec(t), n[t = t.replace(/\[\]$/, "")] = e ? void 0 !== n[t] ? [].concat(n[t], r) : [r] : r;
              };
            case "comma":
            case "separator":
              return (e, r, n) => {
                const a = "string" == typeof r && r.includes(t.arrayFormatSeparator),
                  o = "string" == typeof r && !a && i(r, t).includes(t.arrayFormatSeparator);
                r = o ? i(r, t) : r;
                const s = a || o ? r.split(t.arrayFormatSeparator).map(e => i(e, t)) : null === r ? r : i(r, t);
                n[e] = s;
              };
            default:
              return (t, e, r) => {
                r[t] = void 0 !== r[t] ? [].concat(r[t], e) : e;
              };
          }
        }(e),
        o = Object.create(null);
      if ("string" != typeof t) return o;
      if (!(t = t.trim().replace(/^[?#&]/, ""))) return o;
      for (const r of t.split("&")) {
        if ("" === r) continue;
        let [t, a] = c(e.decode ? r.replace(/\+/g, " ") : r, "=");
        a = void 0 === a ? null : ["comma", "separator"].includes(e.arrayFormat) ? a : i(a, e), n(i(t, e), a, o);
      }
      for (const t of Object.keys(o)) {
        const r = o[t];
        if ("object" == typeof r && null !== r) for (const t of Object.keys(r)) r[t] = f(r[t], e);else o[t] = f(r, e);
      }
      return !1 === e.sort ? o : (!0 === e.sort ? Object.keys(o).sort() : Object.keys(o).sort(e.sort)).reduce((t, e) => {
        const r = o[e];
        return t[e] = Boolean(r) && "object" == typeof r && !Array.isArray(r) ? a(r) : r, t;
      }, Object.create(null));
    }
    e.extract = s, e.parse = d, e.stringify = (t, e) => {
      if (!t) return "";
      r((e = Object.assign({
        encode: !0,
        strict: !0,
        arrayFormat: "none",
        arrayFormatSeparator: ","
      }, e)).arrayFormatSeparator);
      const i = r => e.skipNull && null == t[r] || e.skipEmptyString && "" === t[r],
        a = function (t) {
          switch (t.arrayFormat) {
            case "index":
              return e => (r, i) => {
                const a = r.length;
                return void 0 === i || t.skipNull && null === i || t.skipEmptyString && "" === i ? r : null === i ? [...r, [n(e, t), "[", a, "]"].join("")] : [...r, [n(e, t), "[", n(a, t), "]=", n(i, t)].join("")];
              };
            case "bracket":
              return e => (r, i) => void 0 === i || t.skipNull && null === i || t.skipEmptyString && "" === i ? r : null === i ? [...r, [n(e, t), "[]"].join("")] : [...r, [n(e, t), "[]=", n(i, t)].join("")];
            case "comma":
            case "separator":
              return e => (r, i) => null == i || 0 === i.length ? r : 0 === r.length ? [[n(e, t), "=", n(i, t)].join("")] : [[r, n(i, t)].join(t.arrayFormatSeparator)];
            default:
              return e => (r, i) => void 0 === i || t.skipNull && null === i || t.skipEmptyString && "" === i ? r : null === i ? [...r, n(e, t)] : [...r, [n(e, t), "=", n(i, t)].join("")];
          }
        }(e),
        o = {};
      for (const e of Object.keys(t)) i(e) || (o[e] = t[e]);
      const s = Object.keys(o);
      return !1 !== e.sort && s.sort(e.sort), s.map(r => {
        const i = t[r];
        return void 0 === i ? "" : null === i ? n(r, e) : Array.isArray(i) ? i.reduce(a(r), []).join("&") : n(r, e) + "=" + n(i, e);
      }).filter(t => t.length > 0).join("&");
    }, e.parseUrl = (t, e) => {
      e = Object.assign({
        decode: !0
      }, e);
      const [r, n] = c(t, "#");
      return Object.assign({
        url: r.split("?")[0] || "",
        query: d(s(t), e)
      }, e && e.parseFragmentIdentifier && n ? {
        fragmentIdentifier: i(n, e)
      } : {});
    }, e.stringifyUrl = (t, r) => {
      r = Object.assign({
        encode: !0,
        strict: !0
      }, r);
      const i = o(t.url).split("?")[0] || "",
        a = e.extract(t.url),
        s = e.parse(a, {
          sort: !1
        }),
        u = Object.assign(s, t.query);
      let c = e.stringify(u, r);
      c && (c = `?${c}`);
      let l = function (t) {
        let e = "";
        const r = t.indexOf("#");
        return -1 !== r && (e = t.slice(r)), e;
      }(t.url);
      return t.fragmentIdentifier && (l = `#${n(t.fragmentIdentifier, r)}`), `${i}${c}${l}`;
    }, e.pick = (t, r, n) => {
      n = Object.assign({
        parseFragmentIdentifier: !0
      }, n);
      const {
        url: i,
        query: a,
        fragmentIdentifier: o
      } = e.parseUrl(t, n);
      return e.stringifyUrl({
        url: i,
        query: l(a, r),
        fragmentIdentifier: o
      }, n);
    }, e.exclude = (t, r, n) => {
      const i = Array.isArray(r) ? t => !r.includes(t) : (t, e) => !r(t, e);
      return e.pick(t, i, n);
    };
  });
const d = {
  dateTimeLocal: "YYYY-MM-DD[T]HH:mm:ss",
  dateTimeUTC: "YYYYMMDD[T]HHmmss[Z]",
  allDay: "YYYYMMDD"
};
function h({
  startTime: t,
  endTime: e
}, r) {
  const n = d[r];
  return {
    start: t.format(n),
    end: e.format(n)
  };
}
r.extend(n);
const y = (e, n = !0) => {
    const {
        start: i,
        end: a,
        duration: o
      } = e,
      s = function (t, e) {
        if (null == t) return {};
        var r,
          n,
          i = {},
          a = Object.keys(t);
        for (n = 0; n < a.length; n++) e.indexOf(r = a[n]) >= 0 || (i[r] = t[r]);
        return i;
      }(e, ["start", "end", "duration"]),
      u = n ? r(i).utc() : r(i),
      c = a ? n ? r(a).utc() : r(a) : (() => {
        if (e.allDay) return u.add(1, "day");
        if (o && 2 == o.length) {
          const t = Number(o[0]);
          return u.add(t, o[1]);
        }
        return n ? r().utc() : r();
      })();
    return t({}, s, {
      startTime: u,
      endTime: c
    });
  },
  p = t => {
    const e = y(t),
      {
        start: r,
        end: n
      } = h(e, e.allDay ? "allDay" : "dateTimeUTC"),
      i = {
        action: "TEMPLATE",
        text: e.title,
        details: e.description,
        location: e.location,
        trp: e.busy,
        dates: r + "/" + n,
        recur: e.rRule ? "RRULE:" + e.rRule : void 0
      };
    return e.guests && e.guests.length && (i.add = e.guests.join()), `https://calendar.google.com/calendar/render?${f.stringify(i)}`;
  },
  m = t => {
    const e = y(t, !1),
      {
        start: r,
        end: n
      } = h(e, "dateTimeLocal");
    return `https://outlook.live.com/calendar/0/action/compose?${f.stringify({
      path: "/calendar/action/compose",
      rru: "addevent",
      startdt: r,
      enddt: n,
      subject: e.title,
      body: e.description,
      location: e.location,
      allday: e.allDay || !1
    })}`;
  },
  $ = t => {
    const e = y(t, !1),
      {
        start: r,
        end: n
      } = h(e, "dateTimeLocal");
    return `https://outlook.live.com/calendar/0/deeplink/compose?${f.stringify({
      path: "/calendar/action/compose",
      rru: "addevent",
      startdt: r,
      enddt: n,
      subject: e.title,
      body: e.description,
      location: e.location,
      allday: e.allDay || !1
    })}`;
  },
  g = t => {
    const e = y(t, !1),
      {
        start: r,
        end: n
      } = h(e, "dateTimeLocal");
    return `https://outlook.office.com/calendar/0/action/compose?${f.stringify({
      path: "/calendar/action/compose",
      rru: "addevent",
      startdt: r,
      enddt: n,
      subject: e.title,
      body: e.description,
      location: e.location,
      allday: e.allDay || !1
    })}`;
  },
  v = t => {
    const e = y(t, !1),
      {
        start: r,
        end: n
      } = h(e, "dateTimeLocal");
    return `https://outlook.office.com/calendar/0/deeplink/compose?${f.stringify({
      path: "/calendar/action/compose",
      rru: "addevent",
      startdt: r,
      enddt: n,
      subject: e.title,
      body: e.description,
      location: e.location,
      allday: e.allDay || !1
    })}`;
  },
  D = t => {
    const e = y(t),
      {
        start: r,
        end: n
      } = h(e, e.allDay ? "allDay" : "dateTimeUTC");
    return `https://calendar.yahoo.com/?${f.stringify({
      v: 60,
      title: e.title,
      st: r,
      et: n,
      desc: e.description,
      in_loc: e.location,
      dur: !!e.allDay && "allday"
    })}`;
  },
  O = t => {
    const e = y(t),
      {
        start: r,
        end: n
      } = h(e, e.allDay ? "allDay" : "dateTimeUTC");
    return `https://calendar.aol.com/?${f.stringify({
      v: 60,
      title: e.title,
      st: r,
      et: n,
      desc: e.description,
      in_loc: e.location,
      dur: !!e.allDay && "allday"
    })}`;
  },
  M = t => {
    const e = y(t),
      n = (e.description || "").replace(/,/gm, ",").replace(/;/gm, ";").replace(/\r\n/gm, "\n").replace(/\n/gm, "\\n").replace(/(\\n)[\s\t]+/gm, "\\n"),
      i = (e.location || "").replace(/,/gm, ",").replace(/;/gm, ";").replace(/\r\n/gm, "\n").replace(/\n/gm, "\\n").replace(/(\\n)[\s\t]+/gm, "\\n"),
      {
        start: a,
        end: o
      } = h(e, e.allDay ? "allDay" : "dateTimeUTC"),
      s = r(new Date()).utc().format(d.dateTimeUTC),
      u = [{
        key: "BEGIN",
        value: "VCALENDAR"
      }, {
        key: "VERSION",
        value: "2.0"
      }, {
        key: "PRODID",
        value: e.title
      }, {
        key: "BEGIN",
        value: "VEVENT"
      }, {
        key: "URL",
        value: e.url
      }, {
        key: "DTSTART",
        value: a
      }, {
        key: "DTEND",
        value: o
      }, {
        key: "DTSTAMP",
        value: s
      }, {
        key: "RRULE",
        value: e.rRule
      }, {
        key: "SUMMARY",
        value: e.title
      }, {
        key: "DESCRIPTION",
        value: n
      }, {
        key: "LOCATION",
        value: i
      }, {
        key: "ORGANIZER",
        value: e.organizer
      }, {
        key: "UID",
        value: Math.floor(1e5 * Math.random()).toString().replace(".", "")
      }, {
        key: "END",
        value: "VEVENT"
      }, {
        key: "END",
        value: "VCALENDAR"
      }];
    let c = "";
    return u.forEach(t => {
      if (t.value) if ("ORGANIZER" == t.key) {
        const e = t.value;
        c += `${t.key};${encodeURIComponent(`CN=${e.name}:MAILTO:${e.email}\r\n`)}`;
      } else c += `${t.key}:${encodeURIComponent(`${t.value}\r\n`)}`;
    }), `data:text/calendar;charset=utf8,${c}`;
  };
export { O as aol, y as eventify, p as google, M as ics, g as office365, v as office365Mobile, m as outlook, $ as outlookMobile, D as yahoo };
