import { Component, forwardRef, ChangeDetectionStrategy, OnDestroy, OnInit, Input, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BO9Contact, contactFullName } from '../../../../../bo9-shared/models/contact/contact.model';
import { validationMessages } from 'src/helpers/form.helper';
import { FirstLastName } from '../../../../../bo9-shared/models/contact/name.model';
import { primaryEmail } from '../../../../../bo9-shared/models/contact/contact.model';
import { Difference } from '../../../../../bo9-shared/models/person/differences.model';
import { checkDiffs } from '../../../../../bo9-shared/models/person/differencesCode.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Router } from '@angular/router';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { AuthService } from 'src/services/auth/auth.service';


// todo replace this with BO9Contact

@Component({
    selector: 'contact-form',
    templateUrl: './contactForm.component.html',
    styleUrls: ['./contactForm.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ContactFormComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ContactFormComponent),
            multi: true,
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
  export class ContactFormComponent implements ControlValueAccessor, OnDestroy {
    @Input() diffs: Difference[];
    form: FormGroup;
    subscriptions: Subscription[] = [];
    validationMessages = validationMessages;
    name: string;
    others: FirstLastName[];
    user: Person;
    email= '';
    existingPhoneNumber= '';
    existingAddress= '';
    checkDiffs = checkDiffs;

    get value(): BO9Contact {
      console.log('contact get '+ JSON.stringify(this.form.value));
      return this.form.value;
    }

    set value(value: BO9Contact) {
      console.log('contact set '+ JSON.stringify(value));
      if (value.email && value.email.items && value.email.items.length ) {
        const primary = primaryEmail(value);
        this.email = primary?  primary: '';
      }
      if (value.address && value.address.items && value.address.items.length > 0) {
        this.existingAddress = value.address.items[0].city;
      }
      if (value.phone && value.phone.items && value.phone.items.length > 0) {
        this.existingPhoneNumber= value.phone.items[0].number;
      }

      this.form.patchValue(value);

      this.onChange(value);
      this.onTouched();
    }

    get usageControl() {
        return this.form.controls.usage;
    }

    get namesControl() {
      return this.form.controls.names;
    }


    constructor(      private auth: AuthService, private router: Router, private arangoService: ArangoService,private formBuilder: FormBuilder) {
      this.form = this.formBuilder.group({
        names: [],
        email: [],
        phone: [],
        address: [],
        socialMedia:[]
      });
      this.user = this.auth.getAuthUser();
      this.subscriptions.push(
        this.form.valueChanges.subscribe(value => {
          this.onChange(value);
          this.onTouched();
        })
      );
      this.subscriptions.push( this.arangoService.recordVisit({
        page: this.router.url, component: 'ContactFormComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}}))
    }


    ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
    }

    onChange: any = () => {};
    onTouched: any = () => {};

    registerOnChange(fn) {
      this.onChange = fn;
    }

    writeValue(value) {
        console.log('contact writevalue '+ JSON.stringify(value));
      if (value) {
        if (value.names && value.names.name) {
          this.name = value.names.name.first + ' ' + value.names.name.last;
        }
        if (value.names && value.names.other) {
          this.others = value.names.other;
        }
        if (value.details && value.details.gender) {
          value.gender = value.details.gender;
        } else if (value.contact && value.contact.gender){
          value.gender = value.contact.gender;
        }
        this.value = value;
      }

      if (value === null) {
        this.form.reset();
      }
    }

    registerOnTouched(fn) {
      this.onTouched = fn;
    }

    validate(_: FormControl) {
      return this.form.valid ? true : false;
    }

    reset() {
      this.form.reset();
    }
  }
