import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WooCustomer } from '../../../../../bo9-shared/models/woocommerce/woocustomer.model';
import { MatTableDataSource} from '@angular/material/table';

import { Subscription } from 'rxjs';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth/auth.service';
import { Person } from '../../../../../bo9-shared/models/person/person.model';


@Component({
    selector: 'app-dialog-selectCustomer',
    templateUrl: './selectCustomerDialog.component.html',
    styleUrls: ['./selectCustomerDialog.component.scss'],
    standalone: false
})

export class SelectCustomerDialogComponent implements OnInit  {
  disableBtn = false;
  public displayedColumns = ['select', 'email', 'name'];
  public dataSource = new MatTableDataSource<WooCustomer>();

  user: Person;
  customer: WooCustomer;
  Customers: WooCustomer[];

  selected: WooCustomer[] = [];
  searchString: string;
  loading: boolean;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private dialogRef: MatDialogRef<SelectCustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: { customers: WooCustomer[] } ,
    private router: Router,
    private arangoService: ArangoService
   ) {
      console.log (' select WooCustomer ' + JSON.stringify(data));
      this.user = this.auth.getAuthUser();
      this.subscriptions.forEach(s => s.unsubscribe());
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'SelectCustomerDialogComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
     }

  ngOnInit() {

    console.log ('Init select Customer init ' + JSON.stringify(this.data));
    this.dataSource.data = [];
    this.Customers = this.data.customers;
    this.dataSource.data = this.data.customers;

  }

 

  selectFormCustomer(p: WooCustomer ) {
    for (const fp of this.Customers) {
      if (fp.id===  p.id) {
        fp.selected = !fp.selected;
        this.dataSource.data = this.Customers;
      }
    }

  }

  selectSingleCustomer(p: WooCustomer ) {
    this.dialogRef.close([p]);
  }

  selectAndClose() {
    this.selected = [];
    for (const fp of this.Customers) {
      if (fp.selected) {
        this.selected.push(fp);
      }
    }
    this.dialogRef.close(this.selected);
  }


  close() {
    this.dialogRef.close([]);
  }
}
