import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { BO9Container, DisplayMessage } from '../../../../bo9-shared/models/containers.model';
import { AuthService } from 'src/services/auth/auth.service';
import { ChatMessageService } from 'src/services/chatmessageservice/chatevent.service';

import { Person } from '../../../../bo9-shared/models/person/person.model';



import { ChatseenMessageservice } from 'src/services/chatmessageservice/chatseenevent.service';
import { SystemService } from 'src/services/system.service';
import { BO9UUID } from '../../../../bo9-shared/models/UUID.model';
import { ChatEvent } from '../../../../bo9-shared/models/chat/event.model';
import { ChatRoom, getChatRoomByUUID, clearChatRoomNotSeen } from '../../../../bo9-shared/models/chat/chatroom.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss'],
    standalone: false
})
export class MessagesComponent implements OnInit, OnDestroy, AfterViewChecked, OnDestroy {

  newmessage: string;

  message$: Observable<DisplayMessage[]>;

  messages: ChatEvent[];

  chatRoom: ChatRoom;

  chatRoomId: BO9UUID;
  // New page new container - TOD might need to go back to getting all containers?
  chatroom$: Observable< ChatRoom[] >;

  container: BO9Container;

  routep: any;
  user: Person;

  chatWith: string;
  chatWithAvatar: string;
  chatOrgName: string;

  messagesLoading$: Observable<boolean>;

  messageContainer: HTMLElement;
  subscriptions: Subscription[] = [];
  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private chatService: ChatMessageService,
    private router: Router,
    private arangoService: ArangoService) { 
      this.user = this.auth.getAuthUser();
      this.subscriptions.forEach(s => s.unsubscribe());
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'MessagesComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
    }

  ngOnInit() {
    this.user = this.auth.getAuthUser();
    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #022'); 
      this.router.navigate(['login'] );
    }

   // this.webSocket.initSocket(this.user.wsSocket);
    this.messagesLoading$ = of (true);
    this.route.params.subscribe(params => {
 
      console.log(params); // log the entire params object
      console.log(params['id']); // log the value of id

      this.chatRoomId = params['id'];
      //this.webSocket.setCurrentChatRoomId(this.chatRoomId);
      this.user = this.auth.getAuthUser();

    });
  }

  onClickSendMessage() {
    const m: string = this.newmessage.trim();
    this.newmessage = '';
    if ( m !== '') {
      console.log('Send message   ' + JSON.stringify(m));
      this.chatService.sendChatMessage(m, this.chatRoomId)
      .subscribe((msginner: any) => {
        console.log('Send message response  ' + JSON.stringify(msginner));
      });
    } else {
      console.log('send - Ignore empty  message');
    }
  }

  ngAfterViewChecked() {
    this.messageContainer = document.getElementById("message-container");
    if(this.messageContainer) {
      this.messageContainer.scrollTop = this.messageContainer.scrollHeight;
    }
  }

  ngOnDestroy() {
   // this.webSocket.clearEvents();
    if (this.routep) {
      this.routep.unsubscribe();
    }
  }
}
