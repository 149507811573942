import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';

import { Observable, of, Subscription } from 'rxjs';
import { Person } from '../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { contactFullName } from '../../../../bo9-shared/models/contact/contact.model';
import { Invite } from '../../../../bo9-shared/models/person/invite.model';

@Component({
    selector: 'app-certification',
    templateUrl: './certification.component.html',
    styleUrls: ['./certification.component.scss'],
    standalone: false
})
export class CertificationComponent implements OnInit {
  messagesLoading$: Observable<boolean>;
  people$: Observable<Person[]>;
  totalFound: number=0;
  totalFound$: Observable<number>;
  pageNum$: Observable<number>;
  totalPages$: Observable<number>;
  pageSize$: Observable<number>;
  pageNum: number;
  totalPages: number;
  pageSize: number;
  people: Person[];
  currentPane = 'center';
  valid: false;
  user: Person;
  config = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: this.totalFound
  }
  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;
  labels: any = {
      previousLabel: '<--',
      nextLabel: '-->',
      screenReaderPaginationLabel: 'Pagination',
      screenReaderPageLabel: 'page',
      screenReaderCurrentLabel: `You're on page`
  };
  searchString: string;

  currentP$: Observable<Person>;
  currentPerson: Person;
  RelateForm: FormGroup;

  relatingPersonName: string;

  validationMessages = validationMessages;

  Invites$: Observable<Invite[]>;
  PearlModuleTypes$: Observable<string[]>;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private arangoService: ArangoService
    ) 
  {
    this.config = {
      itemsPerPage: 20,
      currentPage: 1,
      totalItems: this.totalFound
    }
    this.user = this.auth.getAuthUser();
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'CertificationComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #016'); 
      this.router.navigate(['login'] );
    } 
    this.makeForm();

    
  }

  get emailControl() { return this.RelateForm.controls.email; }
  get nameControl() { return this.RelateForm.controls.name; }

  makeForm() {

    this.RelateForm = this.formBuilder.group({
      name: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([ Validators.email])),
      Invite: new FormControl('', Validators.compose([])),
      pearlmodule: new FormControl('', Validators.compose([])),
    });
  }


  ngOnInit() {

  }

  onPageChange(event){
    console.log(event);
    this.config.currentPage = event;
  }

  onClickSearch() {
    // this.arangoService.search(this.searchString, (this.pageNum -1)* this.pageSize, this.pageSize)

  }

  onCloseClick() {
    this.currentPane = 'center';
  }

  onClickRelate(p:Person) {
    this.currentPane = 'left';
    this.relatingPersonName = contactFullName(p.contact);
  }


  onClickExpand(p:Person) {
    console.log('Expand Person  Contact '+ JSON.stringify(p.contact));
    this.currentP$ = of(p);

    this.currentPane = 'right';
  }

  personSubmittedHandler($event: any) {
    console.log(' Person  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';

  }

  relateSubmittedHandler($event: any) {
    console.log(' Relate  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';

  }

}
