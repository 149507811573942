import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/services/loginservice/login.service';

import { Person } from '../../../../../bo9-shared/models/person/person.model'
import { BO9UUID } from '../../../../../bo9-shared/models/UUID.model';
import { webAppVersion } from 'src/config/webapp.config';

@Component({
    selector: 'web-navigation',
    templateUrl: './webnavigation.component.html',
    styleUrls: ['./webnavigation.component.scss'],
    host: {
        '(window:resize)': 'onResize($event)'
    },
    standalone: false
})
export class WebNavigationComponent implements OnInit {

  user: Person;

  version = webAppVersion;

  selectedOrg: BO9UUID;

  mobile:boolean = false;

  onResize(event) {
    if (event.target.innerWidth > 960) {
      this.mobile = false;
    }
  }

  showNav() {
    this.mobile = !this.mobile;
  }

  constructor(
    public loginService: LoginService,
  ) { }

  ngOnInit() {
    console.log('Init nav');

  }


}
