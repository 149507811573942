import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnChanges, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { LoginService } from 'src/services/loginservice/login.service';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { mkEmptyPerson, Person } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { contactFullName } from '../../../../../bo9-shared/models/contact/contact.model';
import { Activity, FormActivity } from '../../../../../bo9-shared/models/activity/activity.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { BO9UUID } from '../../../../../bo9-shared/models/UUID.model';
import { ActivityDialogComponent } from '../../dialogs/activityDialog/activityDialog.component';
import { ActivityService } from '../../../services/activity/activity.service';
import { SearchService } from 'src/services/search/search.service';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { JsonPipe } from '@angular/common';
import { userIsAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { dateFormating } from '../../../../../bo9-shared/helpers/time.helper';


@Component({
    selector: 'activity-list',
    templateUrl: './activityList.component.html',
    styleUrls: ['./activityList.component.scss'],
    standalone: false
})
export class ActivityListComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() from: string;
  @Input() canAll: boolean;
  @Input() preList: FormActivity[];
  @Input() partnerList: boolean;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public displayedColumns = ['edit','from','to','kind','date','by','NN','delete'];
  public dataSource = new MatTableDataSource<FormActivity>();
  messagesLoading$: Observable<boolean>;

  currentPane = 'center';
  valid: false;

  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;


  currentI$: Observable<FormActivity>;
  currentPerson: Person;

  activitys: FormActivity[] =[];
  subInvitor = false;

  validationMessages = validationMessages;

  PearlModuleTypes$: Observable<string[]>;

  loading = false;
  user: Person;
  pageSize: number;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private router: Router,
    private arangoService: ArangoService,
    private dialog: MatDialog,
    private activitysService: ActivityService,
    private searchService: SearchService,

    )
  {
    this.currentPane = 'center';
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'ActivityListComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
   

  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  isAdmin(): boolean {
    return(userIsAdmin(this.user));
  }

  ngOnInit() {
   //this.loadActivitys();
  }

  ngOnChanges() {

    console.log(' Activity  onchanges ');
    this.loadActivitys(true);
  }

  onClickEdit(p:FormActivity) {
    //console.log('Expand Activity  '+ JSON.stringify(p));

    this.currentI$ = of(p);
    this.currentPane = 'left';
  }

  activitySubmittedHandler($event: any) {
    console.log(' Activity  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';

  }

  dateFormating(date: any, kind: string) {
		return dateFormating(date, kind);
	}

  loadActivitys(force?: boolean) {
    if (this.partnerList)  {
      this.displayedColumns = ['from','to','kind','date'];
    } else if (userIsAdmin(this.user)) {
      this.displayedColumns =['edit','from','to','kind','date','by','NN','delete'];
    } else {
      this.displayedColumns = ['from','to','kind','date'];
    }
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
    this.loading = true;
    this.dataSource.data = [];
   
    if (this.preList != null) {
      this.dataSource.data = this.preList
    } else if ( this.from !== '') {
      this.activitysService.getActivity(this.from, force)
      .then( (result: FormActivity[]) => {
        this.loading = false;
        this.dataSource.data = result;
      })
    }
  }

  onClickDelete(p: Activity) {
    const dRef = this.dialog.open(ActivityDialogComponent, {
      width: '55%',
      height: '25%',
      data: {activity: p}
    });

    this.subscriptions.push( dRef.afterClosed()
    .subscribe( async (result: Activity) => {
      console.log('Selected delete result is ' + JSON.stringify(result));
      if (result) {
        const res = await firstValueFrom(this.activitysService.deleteActivity(result));
          alert("Activity deleted");
        
    }

    }))
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  onClickActivity() {
    this.currentPane = 'left';
    this.searchService.searchEmail(this.from, true)
    .subscribe( (persons:Person[]) => {
      console.log("adding activity " + JSON.stringify(persons));
      const newFormActivity: FormActivity = {
        _key: createUUID(),
        fromName: contactFullName(persons[0].contact),
        fromPerson: persons[0]
      }
      this.currentI$ = of(newFormActivity);
    })
  }

  onRefreshClick() {
    if (this.canAll) {
      this.from='';
    }
    this.loadActivitys(true);
  }

  onPageChange(event){
    console.log(event);
  }



  onCloseClick() {
    this.currentPane = 'center';
  }


  personSubmittedHandler($event: any) {
    console.log(' Person  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';
  }

  relateSubmittedHandler($event: any) {
    console.log(' Relate  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';
  }

}
