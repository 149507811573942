
import { zeroPad } from "./text.helper";
import { convertAbbrToTZ } from "./timezone.maps";

//let timezones = Intl.supportedValuesOf('timeZone');

export function tzSubset(): string[] {
	let subset: string [] = [];
	subset.push('America/Denver');
	subset.push('America/New_York');
	subset.push('America/Los_Angeles');
	subset.push('America/Chicago');
	subset.push('America/Phoenix');
	subset.push('Europe/London');
	subset.push('Australia/Brisbane');
	subset.push('Australia/Sydney');

	/*
	for (const tz of timezones) {

		if (! (tz.includes('Africa')) && 
			!(tz.includes('Antarctica')) &&
			!(tz.includes('Asia')) &&
			!(tz.includes('Pacific')) &&
			!(tz.includes('Europe')) &&
			!(tz.includes('America')) &&
			!(tz.includes('Atlantic')) &&
			!(tz.includes('Artic')) &&
			!(tz.includes('Indian')) 
			) {
				subset.push(tz);
			}

	}
	*/
	return subset;
}
export function timeToHours (t :string): {hrs: number, mins: number} {

	let hrs =0;

	let mins=0;

	const timepart = t.split(':');
		if (timepart[1]) {
			if (timepart[1].includes(' ')){
				let ampm = timepart[1].split(' ');
				if (ampm[1] && ampm[1].includes('pm')) {
					timepart[0] = (+timepart[0]+12).toString();
				} 
				mins = +ampm[0]
			} else {
				mins = +timepart[1]
			}

		}
		hrs = +timepart[0];

	return {hrs: hrs, mins: mins} 


}

export function UTCTimeFromDateandtTimezone(t: string, din: string, tz: string): Date {
	const d = dateFromYYYYMMDD(din);
	try {
		
		const tth = timeToHours(t);


		let newD:Date;
		let fullTZ = tz;
		if (!tz.includes('/'))  {
			fullTZ = convertAbbrToTZ(tz);
		}
	
		// suppose the date is 12:00 UTC
		var invdate = new Date(d.toLocaleString('en-US', {
			timeZone: 'America/Denver'
		}));

		var diff = (d.getTime() - invdate.getTime())/(1000*3600);
		let fullD: Date=new Date(d.getFullYear(), d.getMonth(), d.getDate(), tth.hrs,tth.mins);
		newD=addHours(fullD, diff);

		return newD;
		

	}
	catch(err:any) {
		return d;
	}

}


const mons : string[] = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec'
]

export function addYears(dt:Date,n:number): Date {
	return new Date(dt.setFullYear(dt.getFullYear() + n));
}

export function addDays(dt:Date,n:number) : Date {
	return new Date(dt.getFullYear(),dt.getMonth(),dt.getDate()+n);
}

export function addHours(dt:Date,n:number) : Date {
	return new Date(dt.setHours(dt.getHours()+n));
}

export function addMinutes(dt:Date,n:number) : Date {
	return new Date(dt.setMinutes(dt.getMinutes()+n));
}

export function addMonths(dt:Date,n:number) : Date {
	return new Date(dt.setMonth(dt.getMonth()+n));
}
export function monthDiff(d1: Date, d2: Date): number {
	let months: number;

	months = (d2.getFullYear() - d1.getFullYear()) * 12;
	months -= d1.getMonth();
	months += d2.getMonth()+1;
	return months <= 0 ? -months : months
}

export const microsInMonth = 24 * 60 * 60 * 1000 * 30;

export function timestamp(str: string): number {
	const res = new Date(str);
	console.log('timestamp ' + str + ' is ' + res.getTime());
	return res.getTime();
}

export function showDay( t: number): string {
	const res = new Date(t);
	return res.toLocaleString();
}

export function dateFromYYYYMMDD( t: any): Date {
	if (t instanceof Date) {
		return t;
	}
	if (t.includes('-')) {
		const parts = t.split("-");
		return new Date(+parts[0], +parts[1]-1, +parts[2].slice(0,2));
	}

	const d: any = new Date(t);
	const x = d.toLocaleString();
	console.error('datefromYYYYMMDD ' + t + ' is ' + x);
	return(d);
}

export function toBrowserDate( t: string ): string {
	const d: any = new Date(t);
	const x = d.toLocaleString();
	if ((x === "Invalid Date") || (typeof d === 'undefined')) {
		const res = dateFromYYYYMMDD(t);
		return res.toLocaleString();
	} else {
		return d.toLocaleString();
	}
}

// Note YYYMMDD has '-'
export function YYYYMMDDToYYMM( t: string): string {
	return t.slice(2,4) +'-' + t.slice(5,7);
}

function makeOkDate(dIn?: Date) : Date { // Returns date in UTC
	let d: Date;

	try {
		if (!dIn) {
				d = new Date();
		} else {
				if (typeof dIn === 'string') {
					d = new Date(dIn);
				} else {
				//  console.log('-----------MKOKDATE TYPEOF'+JSON.stringify(typeof dIn));
				//  console.log('-----------MKOKDATE ELSE'+JSON.stringify(dIn));
				//console.log('-----------MKOKDATE '+JSON.stringify(dIn));
					d = dIn;
				}
		}
	} catch {
		d = new Date();
		console.log('Bad Date '+JSON.stringify(dIn));
	}
	return d;
}

export function DateToYYMMDD( dIn?: Date): string {
	let d: Date = makeOkDate(dIn);
	return d.getFullYear() +'-' +zeroPad(+d.getMonth()+1, 2) + '-' + zeroPad(d.getDate(),2);
}

export function DateToYYMMDDhhmmss( dIn?: Date): string {
	let d: Date = makeOkDate(dIn);
	const s: string =
		d.getFullYear() +'-' +zeroPad(+d.getMonth()+1, 2) + '-' + zeroPad(d.getDate(),2) + 'T'
		+zeroPad(d.getHours(),2) + ':' +zeroPad(d.getMinutes(),2) + ':'  +zeroPad(d.getSeconds(),2);
	return(s);
}



export function TimePartOfDate(dIn?: Date): string[] {
	let d: Date = makeOkDate(dIn);
	console.log('TPOD '+d.toString());

	const s: string[] = d.toString().split(' ');
	let t = ['12','00', '00'];

	if (s[4]) {
		t = s[4].split(':');
		return (t);
	} else {
		const s: string[] = d.toString().split('T');
		if (s[4]) {
			t = s[4].split(':');
		}
	}

	return t;
}

export function StringToDateArray( iDate: string) : number[] {
	let res = [0,0,0,0,0];

	const d = new Date(iDate);

	res = [d.getFullYear(), d.getMonth()+1, d.getDate(), d.getHours(), d.getMinutes()]

	return res;
}
export function DateToDateArray( d: Date) : number[] {
	let res = [0,0,0,0,0];

	res = [d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes()]

	return res;
}

export function OnlyTimePart(dIn: Date): string {
	let t=  TimePartOfDate(dIn);
	const MT = AMPM(+t[0], t[1]);
	return MT;
}

export function OnlyTimePartNumbers(dIn: Date): string {
	let t=  TimePartOfDate(dIn);
	const MT = +t[0]+":"+t[1];
	return MT;
}

export function AMPM(hour: number, minutes: string): string {
	const ap = hour >= 12 ? ' pm' : ' am';
	const newh = hour > 12 ? hour-12 :  hour;

	return( newh.toString()+':' + minutes + ap);
}

export function fromAMPM(time: string) : string {

	let result = time;
	time = time.toLowerCase();
	const tA = time.split(' ');
	const hrsmins = tA[0].split(':');
	if (time.includes('pm')) {
		hrsmins[0] = (+hrsmins[0]+12).toString();
		result = hrsmins[0] +':'+ hrsmins[1] + ':00';
	} else if (time.includes('am')) {
		result = hrsmins[0]+':'+ hrsmins[1]+ ':00';
	}
	return result;

}

/*
The idea is that, since no country observes DST that lasts for seven months[1], 
in an area that observes DST the offset from UTC time in January will be different to the one in July.
https://stackoverflow.com/questions/11887934/how-to-check-if-dst-daylight-saving-time-is-in-effect-and-if-so-the-offset
*/

export function isDST(dIn?: Date) {
	let d: Date = makeOkDate(dIn);
    let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) !== d.getTimezoneOffset();    
}

export function fromAMPMZ(time: string, onThisDate: string) : string {

	// Daylight Savings - change 7 to 6 #184 186

	let onDate = new Date(onThisDate);

		console.log('FAMPMZ In '+time.toString() + ' daylight '+JSON.stringify(isDST(onDate)));


	let result = time;
	time = time.toLowerCase();
	const tA = time.split(' ');
	const hrsmins = tA[0].split(':');

	let offset = '7';

	if (isDST(onDate)) {
		offset = '6';
	}

	if (time.includes('pm')) {
		if (hrsmins[0] != "12") {
			hrsmins[0] = (+hrsmins[0]+12).toString();
		}

		result = hrsmins[0].padStart(2, '0') +':'+ hrsmins[1].padEnd(2,'0') + ':00-0'+offset+':00';
	} else  {
		result = hrsmins[0].padStart(2, '0')+':'+ hrsmins[1].padEnd(2,'0')+ ':00-0'+offset+':00';
	}
	console.log('FAMPMZ out '+result);
	return result;
}

export function TimeToExpandTime( dIn?: Date): string {
	if (dIn) {
		console.log('TTET In '+dIn.toString());
	}

	let d: Date = makeOkDate(dIn);
	console.log('TTET '+d.toString());
	let t=  TimePartOfDate(d);
	const MT = AMPM(+t[0], t[1])+ ' MT';
	t = TimePartOfDate(addHours(d, -1));  // PT = MT -1
	const PT = AMPM(+t[0], t[1]) + ' PT';
	t = TimePartOfDate(addHours(d, 3)); // ET = PT + 3
	const ET = AMPM(+t[0], t[1]) + ' ET';
	t = TimePartOfDate(addHours(d, 5)); // UK = ET + 5
	let GMT = AMPM(+t[0], t[1]) + ' UK';


	return(PT + ', ' + MT + ', ' + ET +', ' + GMT);
}

export function USWeekday(dIn?: Date): string {
	let d: Date = makeOkDate(dIn);
	//Create an array containing each day, starting with Sunday.
	var weekdays = new Array(
		"Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
	);
	//Use the getDay() method to get the day.
	var day = d.getDay();
	//Return the element that corresponds to that index.
	return weekdays[day];
	}

export function ordinalDay(d: number): string {
		let selector:any;

		if (d <= 0) {
			selector = 4;
		} else if ((d > 3 && d < 21) || d % 10 > 3) {
			selector = 0;
		} else {
			selector = d % 10;
		}

		return d + ['th', 'st', 'nd', 'rd', ''][selector];
		};

export function format(date:any , tmp?: any){
	const regex1 = RegExp('/1?.$/')
	return [
		(tmp = date.getDate()) +
		([, 'st', 'nd', 'rd'][Number(regex1.exec(tmp))] || 'th'),
		[ 'January', 'February', 'March', 'April',
		'May', 'June', 'July', 'August',
		'September', 'October', 'November', 'December'
		][date.getMonth()],
		date.getFullYear()
	].join(' ')
}

export function DateToFullDate( dIn?: Date): string {
	let d: Date = makeOkDate(dIn);
	console.log('-----------DateToFullDateIn ' + JSON.stringify(dIn));
	return(' '+ USWeekday(d) +', '+ format(d));
}

export function DateToActiveCampaignDate( dIn?: Date): string {
	return DateToYYMMDD(dIn)
}

export function DateToActiveCampaignDateTime( dIn?: Date): string {


	return  DateToYYMMDDhhmmss(dIn)
}

export function YYYYMMDDTommmYY( t: string): string {
	//console.log('-----------YYYYMMDDTommmYY ALL ' + JSON.stringify(t));
	//console.log('-----------YYYYMMDDTommmYY ' + JSON.stringify(t.slice(5,7)));
	return mons[+t.slice(5,7)-1]+' '+t.slice(0,4);
}

export function mmmYYYYToDate(mmm: string, YYYY:string): Date {
	if (mmm ==='') {
		mmm = YYYY = new Date().getMonth().toString();
	}
	if (YYYY=='') {
		YYYY = new Date().getFullYear().toString();
	}
	for (let i=0; i< mons.length; i++) {
		if (mons[i]==mmm) {
			return new Date(+YYYY,i,1);
		}
	}
	return new Date();
}

export function YYYYMMDDTommm( t: string): string {
	return mons[+t.slice(5,7)-1];
}

export function filterPips(value: number, type: number): number {
	const dt = new Date(value);
	if (type === 0) {
		return ((dt.getMonth() + 1) % 4) ? -1 : 0;
	}
	return ((dt.getMonth() + 1) % 4) ? 0 : 2; // 2 small, 1 b1g
}

export function lastDayOfOffsetMonth(d: Date, offset: number): Date {
	console.log('-----------previous day lastDayOfOffsetMonth ' + JSON.stringify(d));
	const lastDay:Date = new Date(d.getFullYear(), d.getMonth() + offset, 0);
	console.log('-----------lastDayOfOffsetMonth ' + JSON.stringify(lastDay));
	return (lastDay );
}

// TODO TZ??

export function getMySQLDate(d: Date): string {
	const year = d.getFullYear();
	let month = (d.getMonth() + 1).toString();
	month = month.length > 1 ? month : '0' + month;
	let hour = d.getHours().toString();
	hour = hour.length > 1 ? hour: '0' + hour;
	let minute =  d.getMinutes().toString();
	minute = minute.length > 1 ? minute : '0' + minute;
	let day = d.getDate().toString();
	day = day.length > 1 ? day : '0' + day;

	return year+'-'+month+'-'+day+' '+hour+':'+minute;

}

export function getArangoDate(d: Date): string {
	const year = d.getFullYear();
	let month = (d.getMonth() + 1).toString();
	month = month.length > 1 ? month : '0' + month;
	let hour = d.getHours().toString();
	hour = hour.length > 1 ? hour: '0' + hour;
	let minute =  d.getMinutes().toString();
	minute = minute.length > 1 ? minute : '0' + minute;
	let day = d.getDate().toString();
	day = day.length > 1 ? day : '0' + day;

	return year+'-'+month+'-'+day+'T'+hour+':'+minute+':00';

}

  /**
	* Compares two Date objects and returns e number value that represents
	* the result:
	* 0 if the two dates are equal.
	* 1 if the first date is greater than second.
	* -1 if the first date is less than second.
	* @param date1 First date object to compare.
	* @param date2 Second date object to compare.
	*/

export function compareDate(date1: any, date2: any): number {
	// With Date object we can compare dates them using the >, <, <= or >=.
	// The ==, !=, ===, and !== operators require to use date.getTime(),
	// so we need to create a new instance of Date with 'new Date()'

	let d1 = new Date(date1);
	let d2 = new Date(date2);

	// Check if the dates are equal
	let same = d1.getTime() === d2.getTime();
	let res = 0;
	if (same) res = 0;

	// Check if the first is greater than second
	if (d1 > d2) res = 1;

	// Check if the first is less than second
	if (d1 < d2) res = -1;

	return (res);
}



export function dateFormating(date: any, kind: string): string {
	// format the dates into a cleaner format
	// because the data is messy, non dates will be returned as is
	var isDateFormat = Date.parse(date);
	var retDate = date;
	var options = {};
	if (isDateFormat) {
		retDate = new Date(date);
		switch (kind){
			case("ts"):
				// 22.01.01 : 01.01.01
				retDate = retDate.getFullYear().toString().substring(2) + '.' + (retDate.getMonth() + 1).toString().padStart(2,"0") + '.' + retDate.getDate().toString().padStart(2,"0") + ' : ' + retDate.getHours().toString().padStart(2,"0") + '.' + retDate.getHours().toString().padStart(2,"0") + '.' + retDate.getSeconds().toString().padStart(2,"0");
				break;
			case("identified"):
				// Jan 01, 01
				options = {year:"2-digit", month:"short", day:'2-digit'};
				retDate = retDate.toLocaleDateString(undefined, options);
				break;
			case("purchased"):
				// January 1, 20022
				options = {year:"numeric", month:"long", day:'numeric'};
				retDate = retDate.toLocaleDateString(undefined, options);
				break;
		}
	} else {
		/*
		if(date != '') {
			console.error("Bad date format "+JSON.stringify(date))
		}
		*/

	}

	return retDate;
}


export function 	showTZ(tz: string){
		var retval = tz;

		switch (tz){
			case ('America/New_York'):
				retval = "(EST)";
				if (isDST() ) {
					retval = "(EDT)";
				}
				break;
			case ('America/Chicago'):
				retval = "(CST)";
				if (isDST() ) {
					retval = "(CDT)";
				}
				break;
			case ('America/Denver'):
				retval = "(MST)";
				if (isDST() ) {
					retval = "(MDT)";
				}
				break;
			case ('America/Los_Angeles'):
				retval = "(PST)";
				if (isDST() ) {
					retval = "(PDT)";
				}
				break;
			case ('Australia/Sydney'):
				retval = "(Sydney-AU)";
				break;
			case ('Pacific/Auckland'):
				retval = "(Auckland-NZ)";
				break;
			case ('Europe/London'):
				retval = "(London-UK)";
				break;
		}

		return (retval);
	}
