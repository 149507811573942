import { Component, OnInit, OnDestroy, Input, OnChanges, Output,EventEmitter } from '@angular/core';

import { firstValueFrom, Observable, of, Subscription } from 'rxjs';

import { AuthService } from 'src/services/auth/auth.service';

import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';


import { getTupleFromArray, SystemVariablesService, SysTuple } from 'src/services/systemvariables/systemvariables.service';
import { _isNumberValue } from '@angular/cdk/coercion';
import { Event, EventAttendee, EventColor, EventCounts } from '../../../../../bo9-shared/models/event/event.model';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { canContact } from '../../../../../bo9-shared/models/contact/contact.model';
import { DateToYYMMDD, DateToYYMMDDhhmmss, OnlyTimePart, TimePartOfDate } from '../../../../../bo9-shared/helpers/time.helper';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { EventDBService } from 'src/services/events/eventdb.service';
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import * as dayjs from "dayjs";

export interface EventData {
  id: number;
  title: string;
  desc?: string;
  link?: string;
  startDate: Date;
  endDate: Date;
  createdBy?: string;
  createdAt?: Date;
  type?: number;
  color?: string;
  source: string;
  startTimeUTC: Date;
}

export interface Day {
  day: number;
  month: number;
  year: number;
  events: EventData[];
}

@Component({
    selector: 'event-calendar-display',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    standalone: false
})
export class EventCalendarComponent implements OnInit, OnDestroy, OnChanges, OnDestroy {
  @Input() events: Event[];
  @Input() person: Person;
  @Output() notifyDateChange: EventEmitter<{month: string, year:string}> = new EventEmitter<{month: string, year:string}>();


  loading = false;

  nineECounts: Observable<any>;
  arangoCounts: any;
  arangoNumbers: Observable<any>;

  user: Person;
  showICS: boolean = false;

  numberToXfer: number;
  numberToXferActivity: number;
  searchString: string;

  currentP$: Observable<Person>;


  eventCounts: EventCounts;

  navSubscription;
  messagesLoading$: Observable<boolean>;
  PersonEditForm: FormGroup;
  operation='edit';
  identificationsPurchased= 0;

  idAppSKUs: string [] = [];
  idIdentificationSKUs: SysTuple[] = [];

  person$: Observable<Person>;
  eventCount$ : Observable<EventCounts>;
  canMEA = false;
  google: string = "";
  outlook: string = '';
  office365: string = '';
  yahoo: string = '';
  ics: string = '';

  allEvents: EventData[] = [];
  allEvents$: Observable<EventData[]>;

  public displayedColumns = ['name','value'];
  public displayedColumnsF = ['name','value'];
  public displayedColumnsT = ['name','value'];
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private router: Router,
    private eventdbService: EventDBService,
    private arangoService: ArangoService) {

      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'GuildComponent', 
      visitor: this.user._key
        }).subscribe (  () => {
         
        }))
     }

  close() {
    this.showICS = false;
  }

  ngOnChanges() {
    this.allEvents = [];
    if (this.events) {
      for (const event of this.events) {
        // relies on registered first, hmmm
        if (!this.allEvents.find( e=> e.id === +event._key)) {

          const calEvent: EventData = {
            id: +event._key,
            link: event.linkUrl,
            title: event.name,
            startDate: new Date(event.startDate),
            endDate: new Date(event.endDate),
            color: event.color? event.color : 'grey',
            startTimeUTC: event.startTimeUTC,
            source: event.source
          }
          this.allEvents.push(calEvent);

        }
      }
      this.allEvents$ = of(this.allEvents);
    } else {
      alert("No Events given");
    }
  }

  ngOnInit(): void {
   
  }

  newMonth(newMon: any){
    console.log("Got new month "+ JSON.stringify(newMon));
    this.notifyDateChange.emit(newMon);
  }


  async selectDay(event:EventData) {
    console.log(event.id);
    if (this.loading) {
      alert("Already Registering ");
    } else if (event.color === EventColor.upcoming) {
      this.loading = true;
        const newAttendee: EventAttendee = {
          _key: '', // filled in on insert
          person_id: '',
          contact: this.person.contact,
          mailCampaignID: 0,
          event_id: event.id.toString(),
          paid: "0.00",
          attended: 0,
          email: this.person.primaryEmail,
          first: this.person.contact.names.name.first,
          last: this.person.contact.names.name.last,
          street1: '',
          street2: '',
          city: (this.person.contact.address.items && this.person.contact.address.items.length >0)? this.person.contact.address.items[0].city: '',
          state: (this.person.contact.address.items && this.person.contact.address.items.length >0)? this.person.contact.address.items[0].state: '',
          phone: (this.person.contact.phone.items && this.person.contact.phone.items.length >0)? this.person.contact.phone.items[0].number: '',
          gender: this.person.details.gender,
          zip: (this.person.contact.address.items && this.person.contact.address.items.length >0)? this.person.contact.address.items[0].city: '',
          country: (this.person.contact.address.items && this.person.contact.address.items.length >0)? this.person.contact.address.items[0].city: '',
          start_date: DateToYYMMDD(event.startDate),
          end_date:  DateToYYMMDD(event.endDate),
          event_time: OnlyTimePart(event.startTimeUTC),
          DNC: !canContact(this.person.contact),
          end_time: "00:00",
          date_created: DateToYYMMDDhhmmss()
        }


        let res: any;
        
        if (event.source !== 'calendly') {  // WP Event
          res = await firstValueFrom(this.eventdbService.setEventAttendee(newAttendee));

        } else {

          res = await firstValueFrom(this.arangoService.setEventAttendee(newAttendee));
        }
        
        this.loading = false;
        this.notifyDateChange.emit(null);
        alert("Registered for "+event.title);
        const icsEvent = {
          title: event.title,
          busy: true,
          start: new Date(event.startTimeUTC),
          duration: [1, 'hour' as dayjs.UnitType] as [number, dayjs.UnitType], 
        };
        this.google = google(icsEvent);
        this.outlook = outlook(icsEvent);
        this.office365 = office365(icsEvent);
        this.yahoo = yahoo(icsEvent);
        this.ics = ics(icsEvent);
        this.showICS=true;
    } else {
      alert("Already Registered for "+event.title);
    }
 }
 


  loadHome() {
  }

  onRefreshClick() {
    console.log('Refresh click ');
    this.loadHome();

  }

  makeForm() {

  }



  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());

  }

}
