import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';

import { NaturalNumber, NaturalNumberTypes, stringToNaturalNumber } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { Resource, ResourceType } from '../../../../../bo9-shared/models/resource/resource.model';

import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { UserService } from 'src/services/auth/session.service';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { SelectsService } from 'src/services/selects/selects.service';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { SystemVariablesService } from 'src/services/systemvariables/systemvariables.service';
import { EspressoEventCategory } from '../../../../../bo9-shared/models/event/event9e.model';
import { EventDBService } from 'src/services/events/eventdb.service';
import { EventEmitterService } from 'src/services/events/events.service';


@Component({
    selector: 'resource-form',
    templateUrl: './resourceForm.component.html',
    styleUrls: ['./resourceForm.component.scss'],
    standalone: false
})
export class ResourceFormComponent implements OnInit, OnDestroy {
  @Input() resource: Resource;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  messagesLoading$: Observable<boolean>;


  valid: false;
  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;
  currentP$: Observable<Resource>;
  ResourceForm: FormGroup;

  validationMessages = validationMessages;

  invites: string[];
  ResourceModuleTypes: string[]
  nnKeys: string[];
  resourceType: string[];

  isLoading = false;

  user: Person;
  programs: string[];
  files: any ;
  fileControl: FormControl;
  image: string;
  approved: boolean;
  subscriptions: Subscription[] = [];
  disableBtn = false;
  categories: string[];
  catListDB: string[] = [];
  fullCategories: EspressoEventCategory[] = [];

  constructor(
    private auth: AuthService,
    private router: Router,
    private userService: UserService,
    private eventdbService: EventDBService,
    private formBuilder: FormBuilder,
    private arangoService: ArangoService,
    private systemVariablesService: SystemVariablesService,
    private emitterService: EventEmitterService
    ) 
  {

    this.user = this.auth.getAuthUser();
    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #011'); 
      this.router.navigate(['login'] );
    }
  
    
    this.nnKeys = Object.keys(NaturalNumber).map(key => NaturalNumber[key]).filter(value => typeof value === 'string') as string[];
    this.ResourceForm = this.formBuilder.group({
      _key:  new FormControl('', Validators.compose([])),
      title: new FormControl('', Validators.compose([Validators.required])),
      description: new FormControl('', Validators.compose([ Validators.required])),
      aboutNN: new FormControl([], Validators.compose([Validators.required])),
      resourceType: new FormControl('', Validators.compose([Validators.required])),
      resourceCategories: new FormControl('', Validators.compose([Validators.required])),
      eventCategories: new FormControl('', Validators.compose([Validators.required])),
      url: new FormControl('', Validators.compose([]))
    });
   

    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'ResourceFormComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ( {next:  () => {

    }})); 
    
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  compareNNs(nn1: string, nn2: string): boolean {
    console.log('Compare NN1 ' + JSON.stringify(nn1));
    console.log('Compare NN2 ' + JSON.stringify(nn2));
    return (nn1 === nn2);
  }

  delImage() {
    this.files =[];
    this.image = null;
    this.resource.fileInfo = null;
  }

  async ngOnInit() {
    this.files =[];
    this.image = null;
    this.fileControl = new  FormControl('',[]);

    this.fileControl.valueChanges.subscribe(async (files: any) => { 
      console.log('File ' + JSON.stringify(files.name))
      if (!Array.isArray(files)) {
        this.files= [files];
      } else {
        this.files = files;
      }
    });

      if (!this.catListDB || this.catListDB.length == 0) {
        const res = await firstValueFrom(this.eventdbService.getCategories())

          console.log('get event categories ' + JSON.stringify(res));
          this.fullCategories = res.content;
          for (const cat of res.content as EspressoEventCategory[]) {
            this.catListDB.push(cat.category_name);
          }
          this.resourceType = await firstValueFrom(this.systemVariablesService.getSystemVariableArray('Resource Types'));

          this.categories = await firstValueFrom(this.systemVariablesService.getSystemVariableArray('Resource Categories'));

          const progArray:any[] = await firstValueFrom(this.systemVariablesService.getSystemVariableArray('Programs Allowed Events'));
          this.programs = [];

          if (progArray) {
            for (const p of progArray) {
              this.programs.push(p.programName)
            }
           }
      }
      const p: Resource = this.resource
      this.ResourceForm.reset();
      if (p) {
        console.log('Got Resource form ' + JSON.stringify(this.resource));
        //  console.log('Got Resource img ' + JSON.stringify(this.Resource.fileInfo[0].Location));
          if (this.resource && this.resource.fileInfo &&  this.resource.fileInfo.length > 0) {
            this.image = this.resource.fileInfo[0].Location;
          } else {
            this.image = null;
            this.files = [];
          }

          if (!Array.isArray(p.aboutNN)) {
            p.aboutNN = [p.aboutNN];
          }
          
      }

      let rt: ResourceType[] = p?.resourceType;
      if (p && typeof p.resourceType === 'string') {
        rt = [p.resourceType];
      } 

      this.ResourceForm.patchValue({
        _key: p? p._key : '',
        title: p? p.title : '',
        description: p? p.description : '',
        url: p? p.url: '',
        resourceType:  rt,
        aboutNN: p? p.aboutNN : [NaturalNumber.None],
        resourceCategories: p?p.resourceCategories: [],
        eventCategories: p? p.eventCategories: []

      });


  }

  get titleControl() { return this.ResourceForm.controls.title; }
  get descriptionControl() { return this.ResourceForm.controls.description; }
  get aboutNNControl() { return this.ResourceForm.controls.aboutNN;}
  get resourceTypeControl() { return this.ResourceForm.controls.resourceType;}
  get urlControl() { return this.ResourceForm.controls.url;}
  get resourceCategoriesControl() { return this.ResourceForm.controls.resourceCategories;}


  onPageChange(event){
    console.log('Form change event');
  }

  optionSelected(event: any) {
    console.log('Selected  ' + JSON.stringify(event.source.value));
  }


  submitResourceForm() {
    console.log('Submit Resources form ' + JSON.stringify(this.ResourceForm.value));
    if (this.ResourceForm.valid) {
      console.log('Submit Resource form - valid');

      const p: Resource = {
        _key: this.ResourceForm.value._key? this.ResourceForm.value._key: createUUID(),
        title: this.ResourceForm.value.title,
        aboutNN: this.ResourceForm.value.aboutNN,
        description: this.ResourceForm.value.description,
        resourceType: this.ResourceForm.value.resourceType,
        resourceCategories: this.ResourceForm.value.resourceCategories,
        eventCategories: [this.ResourceForm.value.eventCategories],
        url: this.ResourceForm.value.url,
        meta: updateMeta(this.userService.get())
      }

      if (this.resource && this.resource.fileInfo) {
        p.fileInfo = this.resource.fileInfo;
      }

        this.subscriptions.push( this.arangoService.updateResource(p,this.files? this.files[0]: [] )
        .subscribe( (res) => {
          console.log('Resource add response ' + JSON.stringify(res));
          this.emitterService.emitter.emit('Message','Resource Submit response: ' + JSON.stringify(res));
          this.notifyParent.emit('Resource Saved');

        }))

      

    } else {
      console.log('Submit Resource form - Not valid');
      alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.ResourceForm)));

    }
  }
  
}
