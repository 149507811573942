import { Component, OnInit, OnDestroy, Input, Inject,  NgModule } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { AuthService } from 'src/services/auth/auth.service';

import { Router } from '@angular/router';

import { forkJoin, Subscription } from 'rxjs';


import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person } from '../../../../../bo9-shared/models/person/person.model';

import { Agreement, AgreementVersions } from '../../../../../bo9-shared/models/person/agreements.model';
import { contactFirstLastName } from '../../../../../bo9-shared/models/contact/contact.model';
import  {  PdfViewerModule  }  from  'ng2-pdf-viewer';
import { WebsiteService } from 'src/services/website/website.services';


@Component({
    selector: 'app-dialog-agreement',
    templateUrl: './agreementDialog.component.html',
    styleUrls: ['./agreementDialog.component.scss'],
    standalone: false
})

export class AgreementDialogComponent implements OnInit  {
  disableBtn = false;
  user: Person;
  agreement: Agreement;
  version: AgreementVersions;
  subscriptions: Subscription[] = [];
  auths: string[];
  url: string;
  userName: string;
  isPdf: boolean;
  src: string = "";
  constructor(
    private dialogRef: MatDialogRef<AgreementDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: { agreement: Agreement } ,

    private auth: AuthService,
    private router: Router,
    private webService: WebsiteService
   ) {
    this.agreement = this.data.agreement;
    this.version = this.agreement.versions[this.agreement.versions.length - 1]
    this.url = 'https://bo9guilddocs.bodyof9.com/agreements/' + this.version.name;
    //this.url = 'https:/bo9guild.s3.us-west-2.amazonaws.com/agreements/'+ this.version.name;

    if (this.url.includes('.pdf')) {
      this.isPdf = true;

    } else {
      this.isPdf = false;
      this.subscriptions.push(this.webService.loadGuildDoc(this.url).
        subscribe((content: string) => {
          this.src = content;
        })
      )

      }
     }

  ngOnInit() {

    console.log ('Init agrememnt ' + JSON.stringify(this.data));
    this.user = this.auth.getAuthUser();

    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #001'); 
      this.router.navigate(['login'] );
    }
    this.userName = contactFirstLastName(this.user.contact);

  }

  skip() {
    this.agreement.versions[this.agreement.versions.length - 1].dateSeen = new Date();
    this.dialogRef.close({agreement: this.agreement, changed: true, skip: true});
  }

  accept() {
    this.agreement.versions[this.agreement.versions.length - 1].dateSeen = new Date();
    this.agreement.versions[this.agreement.versions.length - 1].dateAgreed = new Date();
    this.dialogRef.close({agreement: this.agreement, changed: true, skip: false});
  }

  close() {
    this.dialogRef.close({agreement: this.data.agreement, changed: false, skip: true});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
