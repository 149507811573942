import { Component } from '@angular/core';

@Component({
    selector: 'app-home-layout',
    template: `
    <app-homenav class="app-wrapper"></app-homenav>
  `,
    styles: [],
    standalone: false
})
export class HomeLayoutComponent {}
