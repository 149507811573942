import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { Page, mkMTPage  } from '../../../../bo9-shared/models/page/page.model';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { validationMessages } from 'src/helpers/form.helper';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { Person } from '../../../../bo9-shared/models/person/person.model';
import { EventEmitterService } from 'src/services/events/events.service';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss'],
    standalone: false
})
export class PageComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedPage$: Observable<Page>;
  messagesLoading$: Observable<boolean>;


  currentPane = 'center';

  public displayedColumns = ['edit','_key', 'title', 'pageType', 'accesskey', 'timeStamp','delete'];
  public dataSource = new MatTableDataSource<Page>();



  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;

  Pages: Page[];


  relatingPersonName: string;

  validationMessages = validationMessages;
  user:Person;
  pageSize: number;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private router: Router,
    private arangoService: ArangoService,
    private emitterService: EventEmitterService
    ) {
      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'PageComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
      if (!this.user) {
        console.error('User Not logged in ');
        alert('EMail support&#64;bodyof9.com - Permission  Error #025'); 
        this.router.navigate(['login'] );
      }  
     this.loadPages();
     }

  loadPages() {
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
    
    this.arangoService.getPages()
    .subscribe( (result) => {
      const e = result.content;
 
      this.dataSource.data = e as Page[];
      
    });
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  onClickDelete($event) {
    console.log('Delete click '+JSON.stringify($event));
    if (confirm("Are you sure you want to delete this Page? "+ $event.title)) {

      this.arangoService.deletePage($event._key)
      .subscribe ( (result) => {

        console.log('Save result  is: ' + JSON.stringify(result.content));
        this.emitterService.emitter.emit('Message','Page ' +  $event.payment_email + ' deleted');
        if (this.user.preferences && this.user.preferences.autoRefresh) {
          this.loadPages(); // refreash
        }

      })
    }


  }

  ngOnInit() {

  }
  onCloseClick() {
    this.currentPane = 'center';
  }
  tabChanged(event : any) {
    
  }

  addPageClick() {
    this.selectedPage$ = of(mkMTPage());
    this.currentPane = 'left';
  }

  onClickEdit(p:Page) {
    console.log('Expand Page  Contact '+ JSON.stringify(p.title));
    this.selectedPage$ = of(p);

    this.currentPane = 'left';
  }

  onRefreshClick() {
    console.log('Refresh click ');
    this.loadPages();

  }

  PageSubmittedHandler($event: any) {
    console.log(' Page  submitted '+ JSON.stringify($event));
    this.loadPages();
    this.currentPane = 'center';

  }
  

}
