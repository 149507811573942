import { Component, Input, OnInit } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { SystemVariablesService } from "src/services/systemvariables/systemvariables.service";
import { Person } from "../../../../bo9-shared/models/person/person.model";
import { DeviceDetectorService} from 'ngx-device-detector'

interface Bookings {
  url: string;
  title: string;
}
@Component({
    selector: 'calendly-bookings',
    templateUrl: './calendlybookings.component.html',
    styleUrls: ['./calendlybookings.component.scss'],
    standalone: false
})


export class CalendlyBookingComponent implements OnInit {
  @Input() person: Person;

  booking: any[] = [];
  constructor(
    private systemVariablesService: SystemVariablesService,
    private deviceService: DeviceDetectorService,
    )
  {
    
  }

  async ngOnInit() {
    this.booking = await firstValueFrom( this.systemVariablesService.getSystemVariableArray('Calendly Booking Resources'));
    console.log("Bookings "+JSON.stringify(this.booking));
  }

  isMobile(): boolean {
    const im = this.deviceService.isMobile()
    return im
  }


}
