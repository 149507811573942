{
    "version": "9.2.1p",
    "admversion": "9.5.1p",
    "meaversion": "0.1.1p",
    "releaseLevel": "production",
    "sockethostName": "ws://srvr.bodyof9.com",
    "BO9AppToken": "201903283060Cal!",
    "ActCampaignDelay": 400,
    "contactLimit": 20,
    "productionChart" : {
        "width": 400,
        "height": 300
    },
    "websiteConfig": {
        "hostName": "https://bodyof9.com",
        "pages": "/wp-json/wp/v2/pages/"
    },
    "websitePages": {
        "home": 459,
        "market": 464,
        "technology": 462,
        "contact": 466
    },
    "networkConfig": {
        "hostName": "https://srvr.bodyof9.com",
        "hostIp": "127.0.0.1",
        "controlPort": 3000,
        "executableDir": "",
        "enabled": true,
        "debugPort": 3100,
        "transportLevel": "TLS",
        "serverName": "http",
        "socket": null,
        "childProcess": null,
        "server": null
    },
    "externalConfig": {
        "hostName": "https://srvr.bodyof9.com",
        "hostIp": "127.0.0.1",
        "controlPort": 0,
        "executableDir": "",
        "enabled": true,
        "debugPort": 3180,
        "transportLevel": "TLS",
        "serverName": "http",
        "socket": null,
        "childProcess": null,
        "server": null
    },
    "meeting_url": "https://meeting.bodyof9.com/#/",
    "zoomApiToken": "A9m9QxLcS1OefiPDe6PKcA",
    "BO9MapToken": "pk.eyJ1IjoibWFydGlucmNmaXNoZXIiLCJhIjoiY2p0bTE4YmdhMHJ4cDQzazZ3MmZ0ZnlnZyJ9.-rP5SaHNlHgcHskGdkRbSQ",
    "CalendlyApiToken": "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzM3Mzg3NDExLCJqdGkiOiI2ZTIzMjQ0MS02NTUzLTRlNjgtOTYwOC1hZGFlZjI3YzQzMjgiLCJ1c2VyX3V1aWQiOiJISEFBUkFVQ1A3VkozNTZJIn0.2FWRxBxwsSYvhrbSn4bbcgayp_Qa7ozU9zwEFY9LayYeD0qM_aSH70wDJf1xf1Bd8MsEjtKkq2x5YWQ5T_3SwA",    "firebaseConfig" : {
        "apiKey": "AIzaSyCtv3TXMfLbLAac1Hy6hWclucuGOfvM-PA",
        "authDomain": "bodyof9.firebaseapp.com",
        "projectId": "bodyof9",
        "storageBucket": "bodyof9.appspot.com",
        "messagingSenderId": "140294795799",
        "appId": "1:140294795799:web:1ac07d7a6001f43c0f2252",
        "measurementId": "G-4TS1ZNZB5Y"
    },
    "affiliate_wp_config": {
        "username": "mrcfisher",
        "public_key": "bb0166ef6716e51293a85ede0026cb6f",
        "token": "9d710759bafb93d4496cd1d9997d9158",
        "secret_key": "cabc6c31f8459b94f71a13cbcd64e38f",
        "path": "wp-json/affwp/v1"
    },
    "wooCommerceConfig" : {
        "url": "https://bodyof9.com",
        "consumerKey": "ck_eda180b4449a553b58a132ff360fc6801747c8e5",
        "consumerSecret": "cs_473f0b3a4c1db81ae1938c2f393182c5ee7c5dd8",
        "version": "wc/v3",
        "webhookSecret": "ws_20210926woos"
    },
    "lifterLMSConfig" : {
        "url": "https://bodyof9.com",
        "consumerKey": "ck_76944fd29dc9ff8239876ec06886ad4d2a0d61fc",
        "consumerSecret": "cs_a241f649ea49557cc674d89ccc2384a54d780bbe",
        "version": "wc/v3",
        "webhookSecret": "ws_20210926woos"
    },
    "clickSendConfig" : {
        "user": "martin.fisher@kinetre.com",
        "api": "D4F554DE-84E2-DD36-7E0D-252F53A93A06"

    },
    "eventsConfig" : {
        "user": "mrcfisher",
        "pwd": "140Steph!",
        "URI" : "https://bodyof9.com/espresso-api/v1/"
    },
    "discordConfig" : {
        "application_id": "1094297152152404008",
        "client_id" : "1094296933155229867",
        "public_key": "e734e56cf3d18e24712fbdb9da8215aa37b9e72e70a86e935b5fca7307bd26c8",
        "InviteURI" : "https://discord.com/api/oauth2/authorize?client_id=1094297152152404008&permissions=294205336576&scope=bot%20applications.commands",
        "TOKEN" : "MTA5NDI5NzE1MjE1MjQwNDAwOA.GkiJNO.ikPkeJYawL_tSqUgnlJNBf-45XzmbBySc4v5uc",
        "APIURL": "https://discord.com/api"
    }
}
