import { Component, OnInit, ChangeDetectionStrategy, Input,  ViewChild, OnChanges, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person, getPurchaseList, getUserName } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { FormRelation, Relation} from '../../../../../bo9-shared/models/person/relation.model';
import { MatDialog } from '@angular/material/dialog';
import { ProductsService } from 'src/services/products/products.service';
import { ModuleDetail, Product, multiProduct } from '../../../../../bo9-shared/models/product/product.model';
import { productIsProgram, ProgramDefinition } from '../../../../../bo9-shared/models/program/program.model';
import { userIsAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';


@Component({
    selector: 'product-list',
    templateUrl: './productList.component.html',
    styleUrls: ['./productList.component.scss'],
    standalone: false
})
export class ProductListComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  valid: false;

  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;
  products: Product[];

  currentP$: Observable<Product>;
  currentPerson: Person;
  RelationForm: FormGroup;
  loading = false;
  fieldValues = 0;
  relations: FormRelation[] =[];

  validationMessages = validationMessages;

  user: Person;
  person: Person;

  userD: multiProduct[];
  PearlModuleTypes$: Observable<string[]>;
  pageSize: number;
  name: string;
  programLists: ProgramDefinition[];
  public dataSource = new MatTableDataSource<Product>();
  public displayedColumns = ['wooid','product','categories','sku','status','price','assign'];

  currentSubscriptionPane = 'center';
  subscriptions: Subscription[] = [];
  constructor(
    private router: Router,
    private arangoService: ArangoService,
    private auth: AuthService,
    private dialog: MatDialog,
    private productService: ProductsService
    )
  {

    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'ProductListComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
	 this.currentSubscriptionPane = 'center';
   this.currentP$ = of(null);
   if (this.user.preferences) {
    this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
  }


  }


  ngOnChanges() {
  }



  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  ngAfterViewInit(): void {
    this.products=[];
    this.loading = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.subscriptions.push( this.productService.loadProducts()
    .subscribe( async (res: any) => {
      const rest = await firstValueFrom(this.arangoService.getPrograms());
      this.programLists = rest.content;

      console.log('products'+ JSON.stringify(res))
      for (const p of res.content as Product[]) {
       console.log('product'+ JSON.stringify(p))


        p.program = productIsProgram(p, this.programLists);
        this.products.push(p);


      }
      this.dataSource.data = this.products;
      this.loading = false;
      
    }))

  }

  onClickAssign(p:Product) {
    this.currentP$ = of(p);

    this.currentSubscriptionPane = 'right';
  }

  onClickUpdate(p:Product) {
    this.currentSubscriptionPane = 'left';
  }


  isAdmin(): boolean {
    return(userIsAdmin(this.user));
  }

  onCloseClick() {
    this.currentSubscriptionPane = 'center';
  }
  

  ngOnInit(): void {
      
  }


}
