import { Component, OnInit, OnDestroy, Input, Inject, NgModule } from '@angular/core';



import { ActivatedRoute } from '@angular/router';

import { AuthService } from 'src/services/auth/auth.service';

import { ChatRoomService } from 'src/services/chatmessageservice/chatroom.service';
import { Router } from '@angular/router';

import { forkJoin, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Activity } from '../../../../../bo9-shared/models/activity/activity.model';
import { BO9UUID } from '../../../../../bo9-shared/models/UUID.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { MatCardModule as MatCardModule } from '@angular/material/card';
import { HTMLTemplate } from '../../../../../bo9-shared/models/htmltemplate/htmltemplate.model';
import { NaturalNumber } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ArangoService } from 'src/services/arangoservice/arango.service';


@Component({
    selector: 'app-dialog-template',
    templateUrl: './selectListDialog.component.html',
    styleUrls: ['./selectListDialog.component.scss'],
    standalone: false
})

export class SelectListDialogComponent implements OnInit  {
  disableBtn = false;
  selectList: string;
  user: Person;
  nnKeys: string[];
  NNForm: FormGroup;
  subscriptions: Subscription[] = [];
  constructor(
    private dialogRef: MatDialogRef<SelectListDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: { selectList: string } ,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private arangoService: ArangoService,
    private formBuilder: FormBuilder,
   ) {
      console.log ('Init template d ' + JSON.stringify(data));
      this.nnKeys = Object.keys(NaturalNumber).map(key => NaturalNumber[key]).filter(value => typeof value === 'string') as string[];
      this.NNForm = this.formBuilder.group({

        selectedNN: new FormControl('', Validators.compose([Validators.required])),
      });
      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'SelectListDialogComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
     
     }

  ngOnInit() {

    console.log ('Init temaplate d init ' + JSON.stringify(this.data));
    this.user = this.auth.getAuthUser();

    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #008'); 
      this.router.navigate(['login'] );
    }

  }

  submitNNForm() {
    console.log('Selected '+JSON.stringify(this.NNForm.value.selectedNN))
    
    this.dialogRef.close(this.NNForm.value.selectedNN);
  }

  close() {
    this.dialogRef.close('');
  }
}
