import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person, mkEmptyPerson} from '../../../../bo9-shared/models/person/person.model';
import { Difference } from '../../../../bo9-shared/models/person/differences.model';
import { validationMessages } from 'src/helpers/form.helper';

import { Event, mkMTEvent } from '../../../../bo9-shared/models/event/event.model';

import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';

import { TransportNative } from '../../../../bo9-shared/models/mailCampaign/mailCampaign.model';

import { WooCommerceService } from 'src/services/woocommerce/woocommerce.service';
import { WooCustomer } from '../../../../bo9-shared/models/woocommerce/woocustomer.model';
import { MatDialog } from '@angular/material/dialog';
import { SelectCustomerDialogComponent } from 'src/displayComponents/dialogs/selectCustomerDialog/selectCustomerDialog.component';
import { WooDownloadInfo } from '../../../../bo9-shared/models/woocommerce/woodownload.model';
import { WooOrder } from '../../../../bo9-shared/models/woocommerce/wooorder.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
    standalone: false
})
export class OrdersComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  messagesLoading$: Observable<boolean>;
  people$: Observable<Person[]>;
  totalFound: number=0;
  customerId$: Observable<number>;
  pageNum$: Observable<number>;
  totalPages$: Observable<number>;
  pageSize$: Observable<number>;
  pageNum: number;
  totalPages: number;
  pageSize: number;
  people: Person[];
  currentPane = 'center';
  valid: false;

  origp: Person;
  newp: Person;

  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;



  customers$: Observable<WooCustomer[]>;
  orders$: Observable<WooOrder[]>;
  currentEvent$: Observable<Event>
  currentPerson: Person;
  RelateForm: FormGroup;

  relatingPersonName: string;
  totalOrders: number;
  eventNum = 0;
  event: Event;
  origP: Person;
  newP: Person;
  difference: Difference;
  operation="Merge";
  lastPane: string;
  user:Person;
  customers: WooCustomer[];
  send: WooCustomer[];
  subscriptions: Subscription[] = [];
  validationMessages = validationMessages;
  Orders: Event[];
  constructor(
    private dialog: MatDialog,
    private auth: AuthService,
    private router: Router,
    private arangoService: ArangoService,
    private wooService: WooCommerceService
    ) 
  {

    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'OrdersComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #024'); 
      this.router.navigate(['login'] );
    }  
    this.subscriptions.push( this.wooService.loadCustomers()
    .subscribe ( (res:any) => {
      console.log('Customers '+JSON.stringify(res.content));
      this.customers = res.content;
      this.wooService.setCustomers(this.customers);
      
    }))
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }



  ngAfterViewInit(): void {

  }

  makeForm() {

  }

  
  ngOnInit() {

  }




  onPageChange(event){
    console.log(event);
  }



  onClickExpand(p:Event) {
    console.log('Expand Event '+ JSON.stringify(p.name));
    this.currentEvent$ = of(p);
    this.lastPane = this.currentPane;
    this.currentPane = 'left';
  }



  
  onClickSearch() {

    this.currentPane = 'center'
    this.customers = [];
    const cust = this.wooService.searchCustomers(this.searchString);
    console.log('chosen cust '+ JSON.stringify(cust));
    if (!cust) {
      alert('No match for '+this.searchString);
    } else {
      const dRef = this.dialog.open(SelectCustomerDialogComponent, {
        width: '75%',
        height: '75%',
        data: {customers: cust}
      });
  
      this.subscriptions.push( dRef.afterClosed()
      .subscribe(  (result: WooCustomer[]) => {
        console.log('Selected Customers is ' + JSON.stringify(result));
        if (result) {
          this.customers$ = of(result);
          this.send = result;
        }
        
      }))
    }

  }

  onClickSearchBilling() {

    this.currentPane = 'center'
    this.customers = [];
    const uniqueCustomers = [];
    const uniques = [];
    this.subscriptions.push( this.wooService.searchDownloaders(this.searchString)
    .subscribe( async res=> {
      const cust:WooDownloadInfo[] = res.content;
      console.log('chosen billing '+ JSON.stringify(cust));
      
      if (!cust) {
        alert('No match for '+this.searchString);
      } else {
        for (const c of cust) {
          const res = await firstValueFrom(this.wooService.loadOrdersById(c.order_id));
          const cust = custFromPerms(c, res.content);
          this.customers.push(cust);
          if (!uniques.includes(c.user_email)) {
            uniques.push(c.user_email);
            uniqueCustomers.push(cust);
          }
          
        }
        const dRef = this.dialog.open(SelectCustomerDialogComponent, {
          width: '75%',
          height: '75%',
          data: {customers: uniqueCustomers}
        });
    
        this.subscriptions.push( dRef.afterClosed()
        .subscribe(  (result: WooCustomer[]) => {
          console.log('Selected billing Customers is ' + JSON.stringify(result));
          if (result) {
            const returns = [];
            for (const c of result) {
              for (const cust of this.customers) {
                if (c.email == cust.email) {
                  returns.push(cust);
                }
              }
            }
            this.customers$ = of(returns);
            this.send = returns;

          }
    
        }))
      }
    }))

  }

  

  onClickView($event: any) {
    this.operation = "Order"
    this.event = $event;
    this.lastPane = this.currentPane;
    this.currentPane = 'right';
  }


}

function custFromPerms(c: WooDownloadInfo, o: WooOrder) : WooCustomer {

  const res: WooCustomer = {
    id	: c.permission_id, // Ouch hack.
    date_created	: c.access_granted,
    date_created_gmt	: '',
    date_modified	: '',
    date_modified_gmt	: '',
    email	: c.user_email,
    first_name	: '',
    last_name	: '',
    role	: '',
    username	: c.user_email,
    password	: '',
    billing	: null,
    shipping	: null,
    is_paying_customer	:true,
    avatar_url	: '',
    meta_data	: [],
    download_info: c,
    order_info: o,
    selected: false
  }

  return res;

}
