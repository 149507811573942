import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { resultOK } from '../../../../bo9-shared/models/message/message.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person, mkEmptyPerson} from '../../../../bo9-shared/models/person/person.model';
import { Difference } from '../../../../bo9-shared/models/person/differences.model';
import { validationMessages } from 'src/helpers/form.helper';

import { Student,  Enrolled } from '../../../../bo9-shared/models/institute/student.model';

import { CourseTrack, Course } from '../../../../bo9-shared/models/institute/course.model';

import { WPUser } from '../../../../bo9-shared/models/person/wpuser.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';

import { SearchService } from 'src/services/search/search.service';

import { EventEmitterService } from 'src/services/events/events.service';

import { MatDialog } from '@angular/material/dialog';
import { SelectPersonDialogComponent } from 'src/displayComponents/dialogs/selectPersonDialog/selectPersonDialog.component';
import { formPerson } from '../../../../bo9-shared/models/person/formPerson.model';


import { contactFullName } from '../../../../bo9-shared/models/contact/contact.model';
import { dateFormating, DateToYYMMDDhhmmss } from '../../../../bo9-shared/helpers/time.helper';
import { InsitututeService } from 'src/services/instituteService/institute.service';
import { SystemVariablesService } from 'src/services/systemvariables/systemvariables.service';
import { Activity } from '../../../../bo9-shared/models/activity/activity.model';
import { MemberService } from 'src/services/memberService/member.service';

@Component({
    selector: 'app-students',
    templateUrl: './students.component.html',
    styleUrls: ['./students.component.scss'],
    standalone: false
})
export class StudentsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  messagesLoading$: Observable<boolean>;
  people$: Observable<Person[]>;
  totalFound: number=0;
  totalFound$: Observable<number>;
  pageNum$: Observable<number>;
  totalPages$: Observable<number>;
  pageSize$: Observable<number>;
  pageNum: number;
  totalPages: number;
  pageSize: number;
  student: Person;
  currentPane = 'center';
  valid: false;
  noAttendees = [];

  origp: Person;
  newp: Person;

  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;
  students: Person[];

  searchString: string;

  public displayedColumns = ['enroll','name','email','courses' ]; // No delete available?
  public dataSource = new MatTableDataSource<Person>();

  public insdisplayedColumns = ['name','email','courses' ]; // No delete available?
  public insdataSource = new MatTableDataSource<Person>();




  RelateForm: FormGroup;

  relatingPersonName: string;
  totalEvents: number;
  eventNum = 0;
  event: Event;
  origP: Person;
  loading: boolean;
  newP: Person;
  difference: Difference;
  disableBtn = true;
  operation="Merge";
  lastPane: string;
  user:Person;
  validationMessages = validationMessages;
  events: Event[];
  updated= false;
  productsForStudents: string[];
  subscriptions: Subscription[] = [];

  tracks: CourseTrack[]= [];;
  courses: Course[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private studentService: InsitututeService,
    private arangoService: ArangoService,
    private searchService: SearchService,
    private affiliateService: MemberService,
    private auth: AuthService,
    private sysVariableService: SystemVariablesService,
    private dialog: MatDialog,
    private router: Router,
    private emitterService: EventEmitterService
    ) {
    this.makeForm();
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'studentsComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #015'); 
      this.router.navigate(['login'] );
    }  

     this.subscriptions.push(this.sysVariableService.getSystemVariableArray("Products for Students")
    .subscribe( async (res)=> {
      this.productsForStudents =res;
      
      const tracks = await firstValueFrom(this.studentService.getInstituteTrack());

      if (tracks.content && tracks.content.length >0) {
        this.tracks = this.tracks.concat(...tracks.content);
      }

      const courses = await firstValueFrom(this.studentService.getInstituteCourse());

      if (courses.content && courses.content.length >0) {
        if (courses.content) {
          const allCourses: Course[] = courses.content;
          for(const course of allCourses) {
            if (course.tracks && course.tracks.length> 0) {
              if (this.tracks.find( e => course.tracks.includes(e.term_id))) {
                this.courses.push(course);
              }
            }
          }
        }
        console.log(this.courses);


      }
    }));
    this.loadstudents();
    
  }

  get emailControl() { return this.RelateForm.controls.email; }
  get nameControl() { return this.RelateForm.controls.name; }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  makeForm() {

    this.RelateForm = this.formBuilder.group({
      name: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([ Validators.email])),
      Invite: new FormControl('', Validators.compose([])),
      pearlmodule: new FormControl('', Validators.compose([])),
    });
  }

  dateFormating(date: any, kind: string) {
		return dateFormating(date, kind);
	}

  loadstudents() {
    console.log("load students");
    let timeFrom;
    this.disableBtn = true;
    this.loading = true;
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
   
    this.subscriptions.push( this.studentService.getOrders()
    .subscribe( async (orders) => {
        let students: Person[] = [];
        let insstudents: Person[] = [];
        let person: Person;
        const lfs = await firstValueFrom(this.studentService.getInstituteStudent());
        if (lfs && lfs.content && lfs.content.length) { // ALl students in system (lifterlms)
          for (const wp of lfs.content) {

            const wpUser:any = await firstValueFrom(this.affiliateService.getwpuserbyid(wp.id));
            if (wpUser.content && wpUser.content.length > 0) {
             
              const persons: Person[] = await firstValueFrom(this.searchService.searchEmail(wpUser.content[0].user_email, false));
              if (persons && persons.length > 0 ) { // In backend
                person = persons[0];
                if (!person.student) {
                  let student: Student ={
                    date_enrolled: DateToYYMMDDhhmmss(), // Force it to now
                    courses: [],
                    email: wpUser.content[0].user_email,
                    name: wpUser.content[0].display_name?wpUser.content[0].display_name:wpUser.content[0].user_nicename,
                    
                  }; 
                  person.student = student;
                } else {
                  if (person.student.date_enrolled == '') {
                    person.student.date_enrolled= DateToYYMMDDhhmmss() // Force it to now
                  }
                  if (person.student.courses && person.student.courses.length>0) {
                    const cleaned: Enrolled[] = [];
                    for( const program of person.student.courses) {
                      if (program.program_id) {
                        if (program.track && (!program.track.courses || program.track.courses.length ==0)) {
                          program.track.courses=[];
                          for (const course of this.courses) {
                              if (course.tracks.includes(program.track.term_id)) {
                                program.track.courses.push(Object.assign({}, course));
                              }
                          }
                        }
                        cleaned.push(program);
                      }
                    }
                    person.student.courses = cleaned; // Remove old junk ie entries without an program_id (line item id)
                    
                  }
                 
                }
                this.addCourseToStudent(orders.content, person)
                insstudents.push(person);
                const res1 = await firstValueFrom(this.arangoService.updatePerson(person));
              }
            }
          }
          this.insdataSource.data = insstudents;
        }

        for (const order of orders.content) {  // Not enrolled at the institute yet
         
          if (!insstudents.find( e => e.primaryEmail == order.billing.email)) { // Ignore already enrolled

            if (!students.find( e => e.primaryEmail == order.billing.email)) {
              const persons: Person[] = await firstValueFrom(this.searchService.searchEmail(order.billing.email, false));
              if (persons && persons.length > 0) {
                person = persons[0];
                person.student ={
                  date_enrolled: '',
                  courses: [],
                  email: order.billing.email,
                  name: ''
                }; 
                this.addCourseToStudent(orders.content, person)
                students.push(person);
              }
            } 
          }
          this.dataSource.data = students;

        }
        
      this.disableBtn = false;
      this.students = students;
      this.loading =false;
      }))
    }


  ngOnInit() {

  }

  

  onRefreshClick() {
    console.log('Refresh click ');
        this.loadstudents()// refreash
  }

  onClickEmail() {
    this.currentPane='left';
  }





  onChangePage(event){
    console.log(event);
    this.pageNum = event.pageIndex
    this.pageSize = event.pageSize;
  }

  onCloseFormClick() {
    this.currentPane = 'center';
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.loadstudents(); // refreash
    }
  }

  onCloseClick(where:string) {
    console.log('pane is  '+ where);
    if (this.currentPane == 'left' && where==='left') {
      this.currentPane = 'center';
    } else {
      this.currentPane = where;
    }
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.loadstudents(); // refreash
    }

  }

  onClickManualAdd() {
    this.operation = 'Edit';
    this.currentPane="right";
  }

 
  async onClickenroll(p:Person) {
   
    if (p) {
      this.student = p;
      const result = await firstValueFrom(this.studentService.enrollStudent(p));
      p.student.date_enrolled = DateToYYMMDDhhmmss();
      const res1 = await firstValueFrom(this.arangoService.updatePerson(p));
      if (this.user.preferences && this.user.preferences.autoRefresh) {
        this.loadstudents();
        this.loading = false;
      }
    } else {
      alert("No Person to enroll");
    }

  }

  onClickEdit(p:Person) {
    this.student = p;
    this.operation = "Edit";
    this.lastPane = 'center';
    this.currentPane = 'right';
  }

  isJourneyman(p: Person) {
    if (p && p.systemRoles && p.systemRoles.includes('Journeyman')) {
         return "Enabled";

    } else {
      return "Disabled";
    }

  }

  addCourseToStudent(orders: any, person: Person) : Person {

        for (const order of orders) {
          if (person.primaryEmail == order.billing.email && order.line_items) { // Found one
            for (const item of order.line_items) { //check each line item in the order

              if (this.productsForStudents.includes(item.product_id)) { // The order has a institute product

                if (!person.student) {

                }
                if (!person.student.courses.find(e => item.id === e.program_id )) { // not already there, add it
                  const course: Enrolled = {
                    name: item.name,
                    date_registered: '',
                    date_purchased: order.date_completed,
                    hours_taken: 0,
                    certificate_url:'',
                    program_id: item.id
                  }
                  person.student.courses.push(course)
                }
                /*
              let hours =0;
                const attended = await firstValueFrom(this.arangoService.getActivity(student._key,null,'Event Attended'));
                if (attended && attended.content) {
                  for (const e of attended.content as Activity[]) {
                    if (e.value) {
                      hours = +e.value; // Count hours the student has attended !Any meetings! TODO - make it specific
                    }
                  }
                }
                */
                // Add the purchased course to the students course list.           
               
              }
            }
          }
      }
    return person;
  }






}
