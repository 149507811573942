import { Component, OnInit, OnDestroy, Input, Inject, NgModule } from '@angular/core';

import { AuthService } from 'src/services/auth/auth.service';
import { formGroup, GroupMembership } from '../../../../../bo9-shared/models/person/group.model';

import { Router } from '@angular/router';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person } from '../../../../../bo9-shared/models/person/person.model';

import { IdentificationMethod, ProgramNotification } from '../../../../../bo9-shared/models/program/program.model';
import { MatTableDataSource} from '@angular/material/table';

import { Subscription } from 'rxjs';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { EventParams } from '../../../../../bo9-shared/models/event/event.model';


@Component({
    selector: 'identification-dialog-template',
    templateUrl: './selectIdentificationDialog.component.html',
    styleUrls: ['./selectIdentificationDialog.component.scss'],
    standalone: false
})

export class SelectIdentificationDialogComponent  {
  user: Person;
  disableBtn = false;
  package: ProgramNotification;
  public displayedColumns = ['name','quantity','used','remaining','select'];
  public dataSource = new MatTableDataSource<ProgramNotification>();
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private arangoService: ArangoService,
    private router: Router,
    private dialogRef: MatDialogRef<SelectIdentificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: { packages: ProgramNotification[], members: GroupMembership[], form: any } ,

   ) {
     this.dataSource.data = [];
     if (this.data.packages) {
      for (const d of this.data.packages) {
        console.log ('Init template d ' + JSON.stringify(d.idMethod));
        if (d.idMethod === IdentificationMethod.Identification) {
          if (this.data.members) {
            for (const mem of this.data.members) {
              if (d.order.orderId === mem.eventParams.order.orderId) { // EP from same order, same package
                const ep: EventParams = mem.eventParams;
                if (ep.usedDate) {
                  if (d.used) {
                    d.used++
                  } else {
                    d.used =1;
                  }
                  
                }
                
              }
            }
            if (d.used) {
              d.available = d.quantity - d.used;
            } else {
              d.used=0;
            }
          }  else {
            d.used = 0;
            d.available = d.quantity;
          }
          this.dataSource.data.push(d);
        }
          
      }
    }
      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'SelectIdentificationDialogComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {
       
      }})); 
     
     }


  onSelect(p: ProgramNotification) {
    this.dialogRef.close(p);
  }

  close() {
    this.dialogRef.close(null);
  }
}
