import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { Credit, mkMTCredit } from '../../../../bo9-shared/models/institute/credits.model';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';


import { ArangoService } from 'src/services/arangoservice/arango.service';
import { validationMessages } from 'src/helpers/form.helper';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { Person } from '../../../../bo9-shared/models/person/person.model';
import { EventEmitterService } from 'src/services/events/events.service';

@Component({
    selector: 'app-credits',
    templateUrl: './credits.component.html',
    styleUrls: ['./credits.component.scss'],
    standalone: false
})
export class CreditsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedCredit$: Observable<Credit>;
  messagesLoading$: Observable<boolean>;


  currentPane = 'center';

  public displayedColumns = ['edit','creditId', 'eventCode','BO9Hours', 'MTHours', 'ICFHours', 'ICFResourcePercentage','delete'];
  public dataSource = new MatTableDataSource<Credit>();



  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;
  pageSize: number; 
  Credits: Credit[];
  pageNum: number;

  relatingPersonName: string;

  validationMessages = validationMessages;
  user:Person;
  creditSize: number;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private router: Router,
    private arangoService: ArangoService,
    private emitterService: EventEmitterService
    ) {
      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'CreditComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
      if (!this.user) {
        console.error('User Not logged in ');
        alert('EMail support&#64;bodyof9.com - Permission  Error #025'); 
        this.router.navigate(['login'] );
      }  

     }

  loadCredits() {
    if (this.user.preferences) {
      this.creditSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
    
    this.arangoService.getCredits()
    .subscribe( (result) => {
      console.log("Loaded ");
      let creds: Credit[] = [];
      if (result.content.credit && result.content.credit.length > 0 ) {
        for( const c of result.content.credit) {
          creds.push(c);
        }
      }
     
      this.dataSource.data =creds;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      
    });
  }
  ngAfterViewInit(): void {


  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChangePage(event) {
    this.pageNum = event.pageIndex
    this.pageSize = event.pageSize;
  }


  onClickDelete($event: Credit) {
    console.log('Delete click '+JSON.stringify($event));
    if (confirm("Are you sure you want to delete this Credit? "+ $event.eventCode)) {

      this.arangoService.deleteCredit($event._key)
      .subscribe ( (result) => {

        console.log('Save result  is: ' + JSON.stringify(result.content));
        this.emitterService.emitter.emit('Message','Credit   deleted');
        if (this.user.preferences && this.user.preferences.autoRefresh) {
          this.loadCredits(); // refreash
        }

      })
    }


  }

  ngOnInit() {
    this.loadCredits();
  }
  onCloseClick() {
    this.currentPane = 'center';
  }
  tabChanged(event : any) {
    
  }

  addCreditClick() {
    this.selectedCredit$ = of(mkMTCredit());
    this.currentPane = 'left';
  }

  onClickEdit(p:Credit) {
    console.log('Expand Credit  Contact '+ JSON.stringify(p.eventCode));
    this.selectedCredit$ = of(p);

    this.currentPane = 'left';
  }

  onRefreshClick() {
    console.log('Refresh click ');
    this.loadCredits();

  }

  CreditSubmittedHandler($event: any) {
    console.log(' Credit  submitted '+ JSON.stringify($event));
    this.loadCredits();
    this.currentPane = 'center';

  }
  

}
