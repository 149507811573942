      <form [formGroup]="CreditForm" (ngSubmit)="submitCreditForm()" class="Page-form" novalidate >
        <button mat-button type="submit"  [disabled]="disableBtn"class="btn btn-save mat-submit-button">Submit</button>
        <div >
          <mat-form-field appearance="outline"  class="full-width">
            <mat-label>CreditId</mat-label>
            <input matInput  formControlName="creditId"  />
           
          </mat-form-field>
        </div>
        <div >
          <mat-form-field appearance="outline" class="full-width" >
            <mat-label>Event Code</mat-label>
            <input matInput  formControlName="eventCode" required />
          
          </mat-form-field>
        </div>
        <div>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>HTML</mat-label>
              <qeditor
                formControlName="html"
                placeholder="Type layout here..."
                required
              ></qeditor>
             </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline"  class="full-width">
            <mat-label>BO9 Hours</mat-label>
            <input matInput  formControlName="bo9Hours"  />
           
          </mat-form-field>
          <mat-form-field appearance="outline"  class="full-width">
            <mat-label>MT Hours</mat-label>
            <input matInput  formControlName="mtHours"  />
           
          </mat-form-field>
          <mat-form-field appearance="outline"  class="full-width">
            <mat-label>ICF Hours</mat-label>
            <input matInput  formControlName="icfHours"  />
           
          </mat-form-field>
          <mat-form-field appearance="outline"  class="full-width">
            <mat-label>ICR Resource %</mat-label>
            <input matInput  formControlName="icfResourcePercent"  />
           
          </mat-form-field>
 
              <mat-form-field>
                <ngx-mat-file-input2 [formControl]="fileControl">
                </ngx-mat-file-input2>
             </mat-form-field>
            </div>       
            <button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>    
            <div>
            <img *ngIf="image" src="{{image}}" alt="Credit Image">    
          </div>
      </form>

   