import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Observable, of, Subscription } from 'rxjs';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { Invite } from '../../../../../bo9-shared/models/person/invite.model';
import { Page, PageType } from '../../../../../bo9-shared/models/page/page.model';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { Meta } from '@angular/platform-browser';
import { UserService } from 'src/services/auth/session.service';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import {  MaxSizeValidator } from '../../../validators/max-size.validators';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';

@Component({
    selector: 'page-form',
    templateUrl: './pageForm.component.html',
    styleUrls: ['./pageForm.component.scss'],
    standalone: false
})
export class PageFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() page: Page;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  messagesLoading$: Observable<boolean>;

  disableBtn = false;
  valid: false;
  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;
  currentP$: Observable<Page>;
  PageForm: FormGroup;

  validationMessages = validationMessages;

  invites$: Observable<Invite[]>;
  PearlModuleTypes$: Observable<string[]>;
  pageT: string[];
  nnSource: string[];

  isLoading = false;
  user: Person;
  files: any ;
  fileControl: FormControl;
  image: string;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private arangoService: ArangoService
    ) 
  {
    
    this.pageT = Object.keys(PageType).map(key => PageType[key]).filter(value => typeof value === 'string') as string[];

    this.PageForm = this.formBuilder.group({
      _key:  new FormControl('', Validators.compose([])),
      title: new FormControl('', Validators.compose([Validators.required])),
      accessKey: new FormControl('', Validators.compose([])),
      content: new FormControl('', Validators.compose([ Validators.required])),
      pageType: new FormControl('', Validators.compose([ Validators.required])),
      
    });
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'PageFormComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
  }

  ngOnInit() {
    this.fileControl = new  FormControl(this.files,[]);
    this.fileControl.valueChanges.subscribe((files: any) => {
      console.log('File ' + JSON.stringify(files.name))
      if (!Array.isArray(files)) {
        this.files= [files];
      } else {
        this.files = files;
      }
    })
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  get accessKeyControl() { return this.PageForm.controls.accessKeyControl; }
  get titleControl() { return this.PageForm.controls.title; }
  get contentControl() { return this.PageForm.controls.content; }
  get pageTypeControl() { return this.PageForm.controls.theirNN;}



  onPageChange(event){
    console.log(event);
  }

  optionSelected(event: any) {
    console.log('Selected  ' + JSON.stringify(event.source.value));
  }

 

  ngOnChanges() {
  
    const p:Page = this.page
    this.PageForm.reset();
    console.log('Got Page form ' + JSON.stringify(this.page));
  //  console.log('Got Page img ' + JSON.stringify(this.page.fileInfo[0].Location));
    if (this.page && this.page.fileInfo && this.page.fileInfo.length > 0) {
      this.image = this.page.fileInfo[0].Location;
    }
    this.PageForm.patchValue({
      _key: p? p._key : '',
      title: p? p.title : '',
      content: p? p.content : '',
      pageType: p?p.pageType:  '',
      accessKey: p? (p.accessKey? p.accessKey: ''): ''
    });

  }

  submitPageForm() {
    console.log('Submit Pages form ' + JSON.stringify(this.PageForm.value));
    if (this.PageForm.valid) {
      console.log('Submit Page form - valid');
      
      const p: Page = {
        _key: this.PageForm.value._key? this.PageForm.value._key: createUUID(),
        imageURL: '',
        title: this.PageForm.value.title,
        pageType: this.PageForm.value.pageType,
        content: this.PageForm.value.content,
        accessKey: this.PageForm.value.accessKey,
        moderated: null,
        reviews: [],
        timeStamp: DateToYYMMDDhhmmss(new Date),
        meta: updateMeta(this.userService.get())
      }
      
      this.subscriptions.push( this.arangoService.addPage(p, this.files? this.files[0]: [])
      .subscribe( (res) => {
        console.log('Page add response ' + JSON.stringify(res));
        
        this.notifyParent.emit('Page Saved');
      }))

    } else {
      console.log('Submit Page form - Not valid');
      alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.PageForm)));

    }
  }
  
}
