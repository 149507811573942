import { EventAttendee } from "../event/event.model";

export interface Slot {
    appointmentTime: string;
    resourceId: string;
    attendeeKey: string; // AttendeeId
}

export interface Slots {
    eventId: string;
    eventDate: string;
    resources: string[];
    appointments: Slot[];
    _id: string;
    _key: string;
}

export function updateSlots(day:string, time:string, key: string, slotList:Slots[]): Slots | null{

    for (const slots of slotList) {
        if (slots.eventDate == day) {
            for (const slot of slots.appointments) {
                if (slot.appointmentTime == time) {
                    slot.attendeeKey = key;
                    return slots
                }
            }
        }
    }
    return null


}

export function updateSlotsAttendee(day:string, key: string, slotList:Slots[]): Slots | null{

    for (const slots of slotList) {
        if (slots.eventDate == day) {
            for (const slot of slots.appointments) {
                if (slot.attendeeKey == key) {
                    slot.attendeeKey = "";
                }
            }
            return slots
        }
    }
    return null


}