import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Observable, of, Subscription } from 'rxjs';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { Invite } from '../../../../../bo9-shared/models/person/invite.model';
import { Agreement, AgreementVersions} from '../../../../../bo9-shared/models/person/agreements.model';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { UserService } from 'src/services/auth/session.service';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'agreement-form',
    templateUrl: './agreementForm.component.html',
    styleUrls: ['./agreementForm.component.scss'],
    standalone: false
})
export class agreementFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() agreement: Agreement;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  messagesLoading$: Observable<boolean>;

  disableBtn = false;
  valid: false;
  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;
  currentP$: Observable<Agreement>;
  agreementForm: FormGroup;

  validationMessages = validationMessages;
  public dataSource = new MatTableDataSource<AgreementVersions>();
  invites$: Observable<Invite[]>;
  PearlModuleTypes$: Observable<string[]>;
  agreementT: string[];
  nnSource: string[];
  public displayedColumns = ['version', 'created','file','delete'];

  isLoading = false;
  user: Person;
  files: any ;
  fileControl: FormControl;
  image: string;
  subscriptions: Subscription[] = [];
  capabilityList = ["Apprentice", "Journeyman", "SeniorJ", "Master"];
  constructor(
    private auth: AuthService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private arangoService: ArangoService
    ) 
  {
    
    this.agreementForm = this.formBuilder.group({
      title: new FormControl('', Validators.compose([Validators.required])),
      capabilities:  new FormControl('', Validators.compose([Validators.required])),
      
    });

   
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'agreementFormComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
  }

  ngOnInit() {
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onagreementChange(event){
    console.log(event);
  }

  optionSelected(event: any) {
    console.log('Selected  ' + JSON.stringify(event.source.value));
  }

  addVersionClick() {
    this.agreement.versions.push({
      version: "",
      dateCreated: new Date(),
      _key: createUUID()
    })
    this.dataSource.data = this.agreement.versions

  }

  uploadFile(i: number, $event) {
    this.agreement.versions[i].file = $event.target.files[0];

  }

  onClickDelete(i: number) {
    console.log('Delete click ');
    if (confirm("Are you sure you want to delete this Version? " + this.agreement.versions[i].version +" (Submit to finish)")) {
      this.agreement.versions.splice(i, 1);
      this.dataSource.data = this.agreement.versions;
    }

  }
 

  ngOnChanges() {
  
    const p:Agreement = this.agreement
    this.agreementForm.reset();
    console.log('Got agreement form ' + JSON.stringify(this.agreement));

    this.agreementForm.patchValue({
      title: p ? p.title : '',
      capabilities: p? p.capabilities: []
    });
    this.dataSource.data = p? this.agreement.versions:[]

  }

  submitagreementForm() {
    console.log('Submit agreements form ' + JSON.stringify(this.agreementForm.value));
    if (this.agreementForm.valid) {
      console.log('Submit agreement form - valid');
      
      this.agreement._key = this.agreement._key ? this.agreement._key : createUUID(),
      this.agreement.title = this.agreementForm.value.title;
      this.agreement.capabilities = this.agreementForm.value.capabilities;

     this.agreement.meta = updateMeta(this.userService.get())

      
      this.subscriptions.push( this.arangoService.addAgreement(this.agreement)
      .subscribe( (res) => {
        console.log('agreement add response ' + JSON.stringify(res));
        
        this.notifyParent.emit('agreement Saved');
      }))

    } else {
      console.log('Submit agreement form - Not valid');
      alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.agreementForm)));

    }
  }
  
}
