import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';

import { firstValueFrom, Observable, of, Subscription } from 'rxjs';

import { AuthService } from 'src/services/auth/auth.service';
import { Person } from '../../../../bo9-shared/models/person/person.model';

import { ChatRoom } from '../../../../bo9-shared/models/chat/chatroom.model';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { BO9Container, DisplayMessage } from '../../../../bo9-shared/models/containers.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { PCount } from '../../../../bo9-shared/models/pearl/pearl.model';
import { ProductsService } from 'src/services/products/products.service';
import { getProductFromSKU, Product } from '../../../../bo9-shared/models/product/product.model';
import { SystemVariable } from '../../../../bo9-shared/models/BO9_base/systemvariables.model';
import { WooCommerceService } from 'src/services/woocommerce/woocommerce.service';
import { fieldValue, tagValue } from '../../../../bo9-shared/models/mailCampaign/activeCampaign.model';

import { GroupsService } from 'src/services/groups/groups.service';
import { contactFullName } from '../../../../bo9-shared/models/contact/contact.model';
import { formGroup, Group } from '../../../../bo9-shared/models/person/group.model';
import { createUUID } from '../../../../bo9-shared/uuid.service';
import { updateMeta } from '../../../../bo9-shared/models/BO9_base/meta.model';
import { createProgramsEntry, IdentificationMethod, productIsProgram, ProgramDefinition, programFromSKU, ProgramNotification } from '../../../../bo9-shared/models/program/program.model';
import { WooDownload } from '../../../../bo9-shared/models/woocommerce/woodownload.model';
import { getTupleFromArray, SystemVariablesService, SysTuple } from 'src/services/systemvariables/systemvariables.service';
import { _isNumberValue } from '@angular/cdk/coercion';
import { Event, EventColor, EventCounts } from '../../../../bo9-shared/models/event/event.model';
import { MemberService } from '../../services/memberService/member.service';
import { WooCoupon } from '../../../../bo9-shared/models/woocommerce/woocoupon.model';
import { addDays, DateToYYMMDD } from '../../../../bo9-shared/helpers/time.helper';
import { getEventsBetweenDates, getMyEvents } from 'src/services/events/makeEventLists.service';
import { EventDBService } from 'src/services/events/eventdb.service';
import { SearchService } from 'src/services/search/search.service';
import { userIsAdmin } from '../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { DeviceDetectorService} from 'ngx-device-detector'

@Component({
    selector: 'guild',
    templateUrl: './guild.component.html',
    styleUrls: ['./guild.component.scss'],
    standalone: false
})
export class GuildComponent implements OnInit, OnDestroy, OnChanges, OnDestroy {
  @Input() person: Person;
  chatrooms: ChatRoom[];
  chatroom$: Observable<ChatRoom[]>;
  loading = false;

  nineECounts: Observable<any>;
  arangoCounts: any;
  arangoNumbers: Observable<any>;

  messages$: Observable<DisplayMessage[]>
  user: Person;

  numberToXfer: number;
  numberToXferActivity: number;
  searchString: string;

  currentP$: Observable<Person>;
  dataSource: SystemVariable[];
  dataSourceTags: tagValue[];
  dataSourceFields: fieldValue[];

  pearlCount: PCount[];
  clientGroup$: Observable<formGroup>;
  prepaidGroup: formGroup;
  eventCounts: EventCounts;
  products: Product[] = [];
  programLists: ProgramDefinition[]=[];
  navSubscription;
  messagesLoading$: Observable<boolean>;
  PersonEditForm: FormGroup;
  operation='edit';
  identificationsPurchased= 0;
  packages: ProgramNotification[] = [];
  idAppSKUs: string [] = [];
  idIdentificationSKUs: SysTuple[] = [];

  canMEA = false;

  allEvents: Event[] = [];
  notMyEvents: Event[] = [];
  registeredEvents: Event[]=[];
  attendedEvents: Event[]=[];
  allEvents$: Observable<Event[]>;
  notMyEvents$: Observable<Event[]>;
  attendedEvents$: Observable<Event[]>;
  registeredEvents$: Observable<Event[]>;

  searchToString: string;
  searchFromString: string;
  coupons: WooCoupon;

  identifiers: string[] = [];

  myEvents: Event[] = [];


  public displayedColumns = ['name','value'];
  public displayedColumnsF = ['name','value'];
  public displayedColumnsT = ['name','value'];
  
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private router: Router,
    private groupService: GroupsService,
    private arangoService: ArangoService,
    private wooServices: WooCommerceService,
    private eventDBService: EventDBService,
    private productService: ProductsService,
    private searchService: SearchService,
    private systemVariablesService: SystemVariablesService,
    private deviceService: DeviceDetectorService,
    ) {
    this.products = [];
    this.initializeWidgets();
    this.user = this.auth.getAuthUser();

    this.idAppSKUs = [];
    this.idIdentificationSKUs = [];
    if (!this.person) {
      this.person = this.user;
    }

    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'GuildComponent',
      visitor: this.user._key
    }).subscribe(() => { }));

 
    this.searchFromString = DateToYYMMDD(addDays(new Date(), +1));
    if (userIsAdmin(this.user) && this.user.preferences && this.user.preferences.eventHistory && this.user.preferences.eventFutureDays) {
      this.searchFromString = DateToYYMMDD(addDays(new Date(), -this.user.preferences.eventHistory));
      this.searchToString = DateToYYMMDD(addDays(new Date(), +this.user.preferences.eventFutureDays));
    } else {
      this.searchFromString = DateToYYMMDD(addDays(new Date(), -60));
      this.searchToString = DateToYYMMDD(addDays(new Date(), +90));
    }

    
     }

  isMobile(): boolean {
      const im = this.deviceService.isMobile()
      return im
    }

  ngOnChanges() {

  }

  

  datesHandler(newMon) { // newMon is null when event registered
    console.log("Parent dates were  "+JSON.stringify(this.searchToString));
    let newDate: Date;
    let currentDate = new Date(this.searchToString);  
    if (newMon) {
      console.log("Parent dates " + JSON.stringify(newMon));
      const monStart = new Date(newMon.year, newMon.month, 1);
      if (monStart < currentDate) { // Going backwards      
        this.searchFromString = DateToYYMMDD(monStart);
      } 
      newDate = addDays(new Date(newMon.year, newMon.month, 1),32);      
      this.searchToString = DateToYYMMDD(newDate);

     
    }
    console.log("Date from  " + JSON.stringify(this.searchFromString));
    console.log("Date to  "+JSON.stringify(this.searchToString));
    this.loadEvents()
    .then ( () => {});
    
  }

  ngOnInit() {
    this.loading = true;
    this.setPrograms()
    .then ( (result) => {
        this.loadEvents()
        .then ( () => {});

    })
  }

  eventCountHandler($event: any) {
    if ($event) {
      this.eventCounts= $event;
    }
  }

  eventsHandler($event: any) {
    console.log('events found '+ JSON.stringify($event))
    this.allEvents = this.allEvents.concat(...$event);
  }

  loadEvents(): Promise<any> {
    this.loading = true;
    this.registeredEvents = [];
    this.attendedEvents = [];
    return new Promise( async (resolve, reject) => {
      if (this.person && this.person.primaryEmail) {

   

  

      const res = getEventsBetweenDates(this.searchFromString, this.searchToString, 
        this.person,this.eventDBService, this.searchService, this.systemVariablesService, this.arangoService)
      .then ( async (eList) => {
          this.allEvents = eList;
          if (this.person && this.person.primaryEmail) {
            this.myEvents = await getMyEvents(this.person.primaryEmail,this.eventDBService, this.searchService);
            this.allEvents = this.allEvents.concat(...this.myEvents);
          }
  
          this.allEvents.forEach(val => this.notMyEvents.push(Object.assign({}, val)));
          for (const e of this.myEvents) {
            if (e.color && e.color == EventColor.registered) {
              this.registeredEvents.push(e);
            } else if (e.color && (e.color == EventColor.attended || e.color == EventColor.missed)) {
              this.attendedEvents.push(e);
            }
            this.notMyEvents = this.notMyEvents.filter(oj => oj._key != e._key )
          }
          this.allEvents$ = of(this.allEvents);
          this.notMyEvents$ = of(this.notMyEvents);
          this.registeredEvents$ = of(this.registeredEvents);
          this.attendedEvents$ = of (this.attendedEvents);
          this.loading = false;
          resolve(null);
        })
      } else {
        resolve(null);
      }
    })
  }

  setPrograms(): Promise<any> {

    return new Promise( async (resolve, reject) => {
      const rest = await firstValueFrom(this.arangoService.getPrograms());
      this.programLists = rest.content;


      const res = await firstValueFrom(this.productService.loadProducts());
      console.log('products'+ JSON.stringify(res))
      for (const p of res.content as Product[]) {
      //console.log('product'+ JSON.stringify(p))

        p.program = productIsProgram(p, this.programLists);
        if (p.program && p.program !== '') {
          this.products.push(p);
        }
      }

    if (this.person) {
    this.identificationsPurchased=0;
      if (this.idAppSKUs.length === 0) {
      this.idAppSKUs = await firstValueFrom(this.systemVariablesService.getSystemVariableArray('Identification App SKUs'));

      this.idIdentificationSKUs = await firstValueFrom(this.systemVariablesService.getSystemVariableTuples('Identification SKUs'));
      }
      
        this.loading = true;
        if (this.person && !this.person.purchases) {
          this.person.purchases = [];
        }
        const purchases = this.person.purchases;
        let updated = 0;
        this.packages = [];
        if (purchases  && purchases.length > 0) {
          let downloads;
          if (purchases[0].order.customerid  && typeof (purchases[0].order.customerid) === "number" && purchases[0].order.customerid ===  purchases[0].order.customerid) {
            console.log('partner purchases '+ JSON.stringify(purchases[0]))
            downloads = await firstValueFrom(this.wooServices.loadDownload(+purchases[0].order.customerid));
            console.log('downlaods '+ JSON.stringify(downloads))
          }
         
          for (const purchase of purchases) {
            for (const product of purchase.order.orderProducts) {  // for every ordered product
              const idTuple: SysTuple =getTupleFromArray (product.sku,this.idIdentificationSKUs);
              if (idTuple) { // Is an identification
                const progNotify: ProgramNotification = {
                  programName: product.name,
                  order: purchase.order,
                  idMethod: IdentificationMethod.Identification,
                  product: product,
                  quantity: 0
                }
                for (const li of purchase.order.orderProducts) {
                  progNotify.quantity += (li.quantity * idTuple.value as number);
                }
                progNotify.used = 0;
                progNotify.available =  progNotify.quantity;
                this.packages.push(progNotify);
                this.identificationsPurchased += progNotify.quantity;
              } else  if (this.idAppSKUs.includes(product.sku)) {  // Identification  app
                const progNotify: ProgramNotification = {
                  programName: product.name,
                  order: purchase.order,
                  product: product,
                  quantity: 0,
                  idMethod: IdentificationMethod['Manual Application']
                }
                for (const li of purchase.order.orderProducts) {
                  progNotify.quantity += li.quantity;
                }
                progNotify.used = 0;
                progNotify.available =  progNotify.quantity;
                this.packages.push(progNotify);
                this.canMEA = true;
              }
            
              const matchedOrder = getProductFromSKU( product.sku, this.products);
              if (matchedOrder) {
                let found = false;
                              
                const program: ProgramDefinition = programFromSKU(product.sku, this.programLists)
                if (program) {
                  if (downloads && downloads.content && downloads.content.length  > 0) {
                    for (const download of (downloads.content as WooDownload[])) {
                      if (download.order_id = +purchase.orderId) {
                        program.download = download;
                        purchase.order.download = download;
                      }
                    }
                  }
                  program.purchaseDate = purchase.timestamp;
                  program.orderId = purchase.orderId;
                 
                  if (this.person.programs && this.person.programs.length > 0) {
    
                    for (const p of this.person.programs ) {
                      if (p.program._id === program._id) { 
                        found=true; // don't add twice
                        p.program = program; // Replace with updated if required, leave status
                       break; // done
                      }
                    }
                    if (!found) {
                      this.person.programs.push(createProgramsEntry(product, program, purchase));
                      updated++;
                    } 
                  } else {
                    this.person.programs = [createProgramsEntry(product, program, purchase)]
                    updated++;
                  }
                }
              }
            }
          }
          if (updated >0) {
            this.subscriptions.push( await firstValueFrom(this.arangoService.updatePerson(this.person)));
           
          }
       
      }
        
      console.log(' Loadgroups    ');
      this.groupService.getGroups(this.person._key, 'createdByKey', 'prepaid')  // TODO not do it twice if this.from is set
      .then(  (result: Group[]) => {

        console.log('Got groups '+JSON.stringify(result));

        if (result && result.length > 0) {
          const fg: formGroup = {
            _key: result[0]._key,
            createdBy: result[0].createdBy,
            group: result[0],
            members: [],
            meta: result[0].meta
          }
          this.prepaidGroup = fg
        } else { // Create it
          const gKey =  createUUID();

          const group: Group = {
            _key: gKey,
            groupName: contactFullName(this.person.contact)+' Pre-purchased',
            groupType:  'prepaid',
            createdBy: contactFullName(this.person.contact),
            meta: updateMeta(this.person._key)
          }
          console.log('Group is: ' + JSON.stringify(group));
    
          this.subscriptions.push( this.groupService.updateGroup(group)
          .subscribe( (result) => {
            console.log('Update Group Result : ' + JSON.stringify(result));
            const fg: formGroup = {
              _key: gKey,
              createdBy: contactFullName(this.person.contact),
              group: result,
              members: [],
              meta: updateMeta(this.person._key)
            }
            this.prepaidGroup = fg
          }))
        }
        
      })
      this.groupService.getGroups(this.person._key, 'createdByKey', 'client')  // TODO not do it twice if this.from is set
      .then(  (result: Group[]) => {

        console.log('Got groups '+JSON.stringify(result));

        if (result && result.length > 0) {
          const fg: formGroup = {
            _key: result[0]._key,
            createdBy: result[0].createdBy,
            group: result[0],
            members: [],
            meta: result[0].meta
          }
          this.clientGroup$ = of(fg);
        } else { // Create it
          const gKey =  createUUID();

          const group: Group = {
            _key: gKey,
            groupName: contactFullName(this.person.contact)+' Clients',
            groupType:  'client',
            createdBy: contactFullName(this.person.contact),
            meta: updateMeta(this.person._key)
          }
          console.log('Group is: ' + JSON.stringify(group));
    
          this.subscriptions.push( this.groupService.updateGroup(group)
          .subscribe( (result) => {
            console.log('Update Group Result : ' + JSON.stringify(result));
            const fg: formGroup = {
              _key: gKey,
              createdBy: contactFullName(this.person.contact),
              group: result,
              members: [],
              meta: updateMeta(this.person._key)
            }
            this.clientGroup$ = of(fg);
          }))
        }
        
        
      })
    } else {
      // console.error("No person to loag programs")
    }
    resolve(null);
    })
  }

 


  loadHome() {
  }

  onRefreshClick() {
    console.log('Refresh click ');
    this.loadHome();

  }

  makeForm() {

  }

  initializeWidgets() {
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());

  }

}
