import { Component,  OnInit, Input, Output, EventEmitter, OnChanges, forwardRef, ChangeDetectorRef} from '@angular/core';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { EventEmitterService } from 'src/services/events/events.service';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { AuthService } from 'src/services/auth/auth.service';

import { MatTableDataSource} from '@angular/material/table';
import { Order, OrderTrack, Purchase } from '../../../../../bo9-shared/models/purchase/purchase.model';
import { Product } from '../../../../../bo9-shared/models/product/product.model';
import {  Subscription } from 'rxjs';
import { userIsAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { Router } from '@angular/router';
import { ProductsService } from 'src/services/products/products.service';



@Component({
    selector: 'purchaseitems-list',
    templateUrl: './purchaseItemsList.component.html',
    styleUrls: ['./purchaseItemsList.component.scss'],
    standalone: false
})
 
export class PurchaseItemsListComponent implements OnInit {
  @Input() order: Order;
  @Input() tracking: OrderTrack[];
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();

  edit: boolean=false;
  partner: boolean=false;
  admin: boolean=false;
  user:Person;
  pageSize: number;
  products: Product[] =[];
  changed = false;
  notAdminColumns = ['title', 'sku','quantity','remaining'];
  adminColumns = ['title', 'sku','quantity','remaining','reset'];
  public displayedColumns =  ['title', 'sku','quantity'];
  public dataSource = new MatTableDataSource<Product>();
  systemRoles: string[] = [];
  subscriptions: Subscription[] = [];
  constructor(    private router: Router,
    private arangoService: ArangoService,   
    private auth: AuthService, 
    private productService: ProductsService,
        private emitterService: EventEmitterService) 
  {
    console.log('Prodcts');
    this.user = this.auth.getAuthUser();
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'PurchaseItemsListComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 

    if (userIsAdmin(this.user)) {
      this.displayedColumns = this.adminColumns;
    } else {
      this.displayedColumns = this.notAdminColumns;
    }

  }

  showKey (key: String) {
	  alert(key);
  }

  isAdmin(): boolean {
    return(userIsAdmin(this.user));
  }

 ngOnInit() {
  if (this.user.preferences) {
    this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
  }

 }

 onSubmit() {
   

 }



onClickReset(i: number) {
  console.log("Index i "+i)
  if (i != null) {
    const p = this.order.orderProducts[i];
    const orderTrack: OrderTrack = {
      personId: p._key,
      orderId: this.order.orderId,
      productId: this.order.orderProducts[i]._key,
      meta: updateMeta(),
      quantityUsed: 0,
      resetCount: true,
      notes: "Admin Quantity Reset",
      appliedTo: p
    }
    this.arangoService.addOrderTrack(orderTrack)
    .subscribe(  () => {
      this.ngOnChanges(); // Reload
    });
  }
}

  ngOnChanges() {
    console.log("CHANGE PF");
    
    this.dataSource.data = [];
    
    if (this.order) {
      for(const p of this.order.orderProducts) {
        p.used = 0;
        if (this.tracking) {
          for (const t of this.tracking) {
            if (t.quantityUsed) {
              p.used += t.quantityUsed;
            } else if (t.resetCount) {
              p.used = 0;
            }
          }
        }
       
        this.dataSource.data.push(p);
      }
    }

  }
  
}