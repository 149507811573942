import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss'],
    standalone: false
})
export class SupportComponent implements OnInit {

  constructor(
    ) { }

  ngOnInit() {

  }

  tabChanged(event : any) {
    
  }
}
