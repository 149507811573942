
      <form [formGroup]="PersonForm" (ngSubmit)="submitPersonForm()" class="personal-form">
        <span *ngIf="person">User Key: {{ person._key }}, Last Updated {{ person.meta.lastModified }} </span>

        <button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>
      
       <!--
        <span *ngIf="isSuperAdmin()">    
          <mat-form-field  appearance="outline" >
          <mat-label>User ID</mat-label>
          <input matInput 
            formControlName="userUID"
            id="userUID"
          />

        </mat-form-field></span>
        -->
        <div>
          <contact-form formControlName="contact"></contact-form>
  
      </div>
      <div>
        <nn-form formControlName="natnum" [self]="false"></nn-form>
      </div>
      <div>
        <purchase-list [person]="person"></purchase-list>
      </div>


         
        <div >
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Notes</mat-label>
           <textarea matInput  formControlName="notes" ></textarea>

          </mat-form-field>
        </div>
        <div>
         
          
        <mat-form-field  appearance="outline" >
          <mat-label>Gender</mat-label>
          <mat-select matNativeControl   formControlName="gender">
            <mat-option *ngFor="let nnI of genderTypes" [value]="nnI">
              {{ nnI }}
            </mat-option>
          </mat-select>
    

        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Biography</mat-label>
          <textarea matInput  formControlName="bio" ></textarea>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Website URL</mat-label>
          <input matInput  formControlName="websiteUrl" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>BirthOrder on Mother's Side</mat-label>
          <input matInput  formControlName="birthOrder" />
        </mat-form-field>
        <mat-form-field  appearance="outline" >
          <mat-label>Referral Source</mat-label>
            <mat-select formControlName="source">
            <mat-option *ngFor="let nns of referrals" [value]="nns">
              {{ nns }}
            </mat-option>
          </mat-select> 
        </mat-form-field>
        <mat-form-field  appearance="outline" >
          <mat-label>Person Or Other</mat-label>
          <input matInput 
            formControlName="personOrOther"
          />
        </mat-form-field>
        <h4>Photo</h4>
        <mat-form-field>
          <ngx-mat-file-input2 formControlName="fileControl" ></ngx-mat-file-input2> 
          </mat-form-field> 
         <div *ngIf="image  && image !=''">
        <img  src="{{image}}" alt="Person Image">    <button mat-stroked-button (click)="delImage()">Delete Img</button> 
         </div> 
        <div *ngIf="dataSource.data.length > 0">
        <h3>Deals</h3>
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="group">
            <th mat-header-cell *matHeaderCellDef> Group </th>
              <td mat-cell *matCellDef="let element"> {{ element.group}} </td>
          </ng-container>
          <ng-container matColumnDef="stage">
            <th mat-header-cell *matHeaderCellDef> Stage </th>
              <td mat-cell *matCellDef="let element"> {{ element.stage }} </td>
          </ng-container>
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Title </th>
              <td mat-cell *matCellDef="let element"> {{ element.title }} </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
              <td mat-cell *matCellDef="let element"> {{ element.description }} </td>
          </ng-container>
          <ng-container matColumnDef="modified">
            <th mat-header-cell *matHeaderCellDef> Modified </th>
              <td mat-cell *matCellDef="let element"> {{ element.mdate }} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
          </table>
          <h3>Tags</h3>
          <div *ngFor="let tag of actCamp.tags">{{tag.id}}:{{tag.associated_id}}:{{ tag.tag }}</div>
        </div>
      </div>

        <button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>
    </form>