import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { GenderTypes, getGender, mergePerson, Person, personFromPersonForm } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { canContact, contactFullName } from '../../../../../bo9-shared/models/contact/contact.model';
import { Invite } from '../../../../../bo9-shared/models/person/invite.model';
import { mostAccurateNaturalNumber, NaturalNumber} from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { EventEmitterService } from 'src/services/events/events.service';
import { DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { createUUID, responseEncode } from '../../../../../bo9-shared/uuid.service';
import { addEmail, EmailContactItem, EmailContactType, existingEmail} from '../../../../../bo9-shared/models/contact/email.model';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { userIsAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { LoginService } from 'src/services/loginservice/login.service';
import { EmailService } from 'src/services/email/email.service';
import { Activity } from '../../../../../bo9-shared/models/activity/activity.model';
import { ActivityService } from 'src/services/activity/activity.service';
import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';
import { setPreferences } from '../../../../../bo9-shared/models/person/preferences.model';
import { SearchService } from 'src/services/search/search.service';
import { MatTableDataSource } from '@angular/material/table';
import { Agreement } from '../../../../../bo9-shared/models/person/agreements.model';




@Component({
    selector: 'profile-form',
    templateUrl: './profileFormTabs.component.html',
    styleUrls: ['./profileForm.component.scss'],
    encapsulation: ViewEncapsulation.None
    // make fade in animation available to this component
    ,
    standalone: false
})
export class ProfileFormComponent implements OnChanges, OnInit {

  @Input() person: Person;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  messagesLoading$: Observable<boolean>;


  valid: false;

  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;
  disableBtn = false;
  name: string;
  first: string;
  user: Person;
  ProfileForm: FormGroup;
  nnIcon: string;
  submitting = false;
  relatingPersonName: string;
  image: string;
  validationMessages = validationMessages;
  subscriptions: Subscription[] = [];
  Invites$: Observable<Invite[]>;
  PearlModuleTypes$: Observable<string[]>;
  nnSource;
  files: any = [];
  fileControl: FormControl;
  key: string;
  emailForm: FormGroup;
  nn: NaturalNumber;
    
  genderTypes = GenderTypes;
  public dataSource = new MatTableDataSource<Agreement>();
  public displayedColumns = ['title', 'created'];
  public versionColumns = ['versions'];
  constructor(
    private auth: AuthService,
    private router: Router,
    private emailService: EmailService,
    private loginService: LoginService,
    private activityService: ActivityService,
    private searchService: SearchService,
        private activeCampaignService: ActiveCampaignService,
    private formBuilder: FormBuilder,
    private arangoService: ArangoService,
    private emitterService: EventEmitterService
    )
  {

    this.makeForm();
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'ProfileFormComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    if (this.person) {
      this.nn = mostAccurateNaturalNumber(this.person.naturalNumbers).naturalNumber;
    } else {
      this.person = this.user;
      this.nn = mostAccurateNaturalNumber(this.user.naturalNumbers).naturalNumber;
    }
   

  }

  delImage() {
    // files =[]; // Dont delete files, if they are set one was selected this time around, don't clear it
    this.image = null;
    if (this.person) {
      this.person.imageURL = null;
      this.person.fileInfo = null;
    }
  }

  get firstControl() { return this.ProfileForm.controls.first; }
  get lastControl() { return this.ProfileForm.controls.last; }
  get emailControl() { return this.ProfileForm.controls.email; }
  get bioControl() { return this.ProfileForm.controls.bio; }
  get genderControl() { return this.ProfileForm.controls.gender; }

  get newemailControl() { return this.emailForm.controls.newemail; }


  makeForm() {

    this.ProfileForm = this.formBuilder.group({
      key: new FormControl('', Validators.compose([])),
      natnum: [],
      contact: [],
      gender: new FormControl('', Validators.compose([])),
      bio: new FormControl('', Validators.compose([])),
      eventHistory: new FormControl('', Validators.compose([])),
      eventFutureDays: new FormControl('', Validators.compose([])),
      rowsPerPage: new FormControl('',Validators.compose([])),
      birthOrder: new FormControl('', Validators.compose([])),
      websiteUrl:  new FormControl('', Validators.compose([]))
    });
    this.emailForm = this.formBuilder.group({
      newemail: new FormControl('', Validators.compose([]))
    });
  }

  isAdmin(): boolean {
    return(userIsAdmin(this.user));
  }

  submitProfileForm() {
    const emailToValidate: EmailContactItem[]=[];
    console.log('Submit person form ' + JSON.stringify(this.ProfileForm.value));
    if (this.ProfileForm.valid) {
      this.submitting = true;
      console.log('Submit person form - valid');
      const newP = personFromPersonForm(this.ProfileForm.value);
     // const mergedP = mergePerson(this.person, newP, this.user._key)
      if (newP.contact.email.items && newP.contact.email.items.length > 0) { // check to see it there are new unvalidated emails 
        for (let i=0; i < newP.contact.email.items.length; i++) {
          if (newP.contact.email.items[i].toValidate && !newP.contact.email.items[i].confirmed) { // Hasn't been sent to validate yet
            newP.contact.email.items[i].confirmed = responseEncode(this.person._key,"C");
            newP.contact.email.items[i].unconfirmed= responseEncode(this.person._key,"U");
            emailToValidate.push(newP.contact.email.items[i]);
          }
        }
      }
      newP.preferences.autoRefresh = this.person.preferences.autoRefresh;
      console.log('update person '+ JSON.stringify(newP));
      this.subscriptions.push( this.arangoService.updatePerson(newP, true) // Saves the new confirm/notconfirm so user can't beat the db setup
      .subscribe ( async (result) => {
        if (this.files && this.files.length > 0) {
          const active = await  firstValueFrom(this.arangoService.updatePersonsImage( newP, this.files[0]))
        }
        const act: Activity = {
          _key :createUUID(),
          from : this.person._key,
          to : this.person._key,
          kind : 'User updated data',
          note : 'User data changed...',
          date : DateToYYMMDDhhmmss(),
          by : contactFullName(this.person.contact),
          meta : updateMeta()
        }

        console.log('Email to validate '+JSON.stringify(emailToValidate));

        const active = await  firstValueFrom(this.activityService.addActivity( act, 'person'))
        if (canContact(newP.contact)) {
          const actc = await firstValueFrom(this.activeCampaignService.syncContact(newP));
        }
        for (const email of emailToValidate) {
          console.log('Email to validate '+JSON.stringify(email));
          const res = await firstValueFrom(this.emailService.validateEMail(email, this.person));
        }
        console.log('Save result  is: ' + JSON.stringify(result.content));
        this.emitterService.emitter.emit('Message','Person ' + contactFullName(this.person.contact) + ' saved');
        
        this.notifyParent.emit('person Saved');
        this.submitting = false;
      }))
    } else {
      alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.ProfileForm)));
    }
  }

  submitEForm() {

  }


  onPageChange(event){
    console.log(event);
  }

  ngOnInit() {
    this.user = this.auth.getAuthUser();
    this.fileControl = new  FormControl('',[]);
    this.fileControl.valueChanges.subscribe((files: any) => {
      console.log('File ' + JSON.stringify(files.name))
      if (!Array.isArray(files)) {
        this.files= [files];
      } else {
        this.files = files;
      }
    })
  }

  onClickChangeEmail() {
      if (this.emailForm.value.newemail!== '') {
        if (confirm('Are you sure you wish to change your login email '+ this.user.primaryEmail + ' to ' + this.emailForm.value.newemail + " - this will not automatically change your emails inside this system!")) {
          this.loginService.changeEmail(this.emailForm.value.newemail)
          .then ( (user: any) => {
              console.log('Got user email change >' + JSON.stringify( user) + '<');
              alert('New Login Email changed to: '+this.emailForm.value.newemail);
          })
          .catch( (error) =>  {
              console.log('Other response from Change Email ' + JSON.stringify(error));
              alert('New Login Email change Failed '+ JSON.stringify(error));
            })
      } 
    }else {
      alert('No Email given');
    }
    
  }


  ngOnChanges() {
    this.ProfileForm.reset();
    this.image = '';
    const person =  this.searchService.searchPersonKey(this.person._key,true) 
    .then ( (p: Person) => {
      if (p) {
        this.person = p;
        if (!this.person.preferences) {
          this.person.preferences = setPreferences()
        }
        if (this.person && this.person.imageURL) {
          this.image = this.person.imageURL;
        } else {
          this.image = null;
          this.files = [];
        }
        this.name = contactFullName(this.person.contact);
        console.log('Expand Person '+ JSON.stringify(p));
        this.key = p._key;
        this.first = p.contact.names.name.first ;
        // this.contact = semiContact;
        const nn = mostAccurateNaturalNumber(p.naturalNumbers);
        this.nnIcon = (Number(nn.naturalNumber) > 0) ? "filter_" + nn.naturalNumber : "grid_on";
        this.ProfileForm.patchValue({
          key: p._key,
          primaryEmail: p.primaryEmail,
          contact: p.contact,
          bio: p.bio,
          notes: p.notes,
          natnum: p.naturalNumbers,
          source: p.source,
          personOrOther: p.personOrOtherReferredBy,
          gender: getGender(p.details? p.details.gender: ''),
          birthOrder: p.details? (p.details.momBirthOrder? p.details.momBirthOrder: 0) : 0,
          eventHistory: p.preferences.eventHistory? p.preferences.eventHistory: 60,
          eventFutureDays: p.preferences.eventFutureDays? p.preferences.eventFutureDays: 180,
          rowsPerPage: p.preferences.rowsPerPage? +p.preferences.rowsPerPage: 15,
          websiteUrl:  p.details? (p.details.websiteUrl? p.details.websiteUrl: '') : ''
        });
        this.dataSource.data = p.agreements as Agreement[];
      }
    })
   

  }

  async resetDefaults() {
    this.person.preferences = setPreferences();

   
    const p = this.person;
    this.ProfileForm.patchValue({
      key: p._key,
      primaryEmail: p.primaryEmail,
      contact: p.contact,
      bio: p.bio,
      notes: p.notes,
      natnum: p.naturalNumbers,
      source: p.source,
      personOrOther: p.personOrOtherReferredBy,
      gender: p.details.gender? p.details.gender: '',
      birthOrder: p.details? (p.details.momBirthOrder? p.details.momBirthOrder: 0) : 0,
      eventHistory: p.preferences.eventHistory? p.preferences.eventHistory: 60,
      eventFutureDays: p.preferences.eventFutureDays? p.preferences.eventFutureDays: 180,
      rowsPerPage: p.preferences.rowsPerPage? +p.preferences.rowsPerPage: 15,
      websiteUrl:  p.details? (p.details.websiteUrl? p.details.websiteUrl: '') : ''
    });
    const update= await firstValueFrom(this.arangoService.updatePerson(this.person, true));
  }

  selectAutoRefresh() {

      if (this.person) {
        if (this.person.preferences.autoRefresh == null || this.person.preferences.autoRefresh == undefined) {
          this.person.preferences.autoRefresh = false;
        }
        this.person.preferences = {
          autoRefresh: !this.person.preferences.autoRefresh,
          eventHistory: this.person.preferences.eventHistory,
          eventFutureDays: this.person.preferences.eventFutureDays,
          rowsPerPage: this.person.preferences.rowsPerPage
        }
      }

  }


}
