import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';

import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import {  mergePersonWithOrigForm, Person } from '../../../../../bo9-shared/models/person/person.model';
import { Difference } from '../../../../../bo9-shared/models/person/differences.model';
import { validationMessages } from 'src/helpers/form.helper';
import { canContact, contactFullName, makeContactFromForm } from '../../../../../bo9-shared/models/contact/contact.model';
import { Invite } from '../../../../../bo9-shared/models/person/invite.model';
import {  NaturalNumber } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';

import { addEmail, mergeEmail } from '../../../../../bo9-shared/models/contact/email.model';

import { EventEmitterService } from 'src/services/events/events.service';

import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';
import { Activity } from '../../../../../bo9-shared/models/activity/activity.model';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { ActivityService } from 'src/services/activity/activity.service';

@Component({
    selector: 'merge-person-form',
    templateUrl: './mergePersonForm.component.html',
    styleUrls: ['./mergePersonForm.component.scss'],
    standalone: false
})
export class MergePersonFormComponent implements OnChanges {

  @Input() origPerson: Person;
  @Input() newPerson: Person;
  @Input() differences: Difference[];
  @Input() source: string;
  @Input() extSource: string;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  messagesLoading$: Observable<boolean>;

 
  valid: false;
  disableBtn = false;
  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  MergePersonForm: FormGroup;

  relatingPersonName: string;

  validationMessages = validationMessages;
  user: Person;
  Invites$: Observable<Invite[]>;
  PearlModuleTypes$: Observable<string[]>;
  nnKeys;
  nnSource;
  referrals: string[] =[];
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private arangoService: ArangoService,
    private emitterService: EventEmitterService,
    private activeCampaignService: ActiveCampaignService,
    private router: Router,
    private activityService: ActivityService
    ) 
  {
    this.nnKeys = Object.keys(NaturalNumber).map(key => NaturalNumber[key]).filter(value => typeof value === 'string') as string[];

    this.makeForm();
    this.user = this.auth.getAuthUser();
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'MergePersonFormComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    this.subscriptions.push( this.arangoService.getReferrals()
    .subscribe( (res) => {
      if (res.content) {
        for (const r of res.content) {
          this.referrals.push(r.Name);
        }
      }
      
    }))


  }

  get origprimaryEmailControl() { return this.MergePersonForm.controls.origprimaryEmail; }
  get origbioControl() { return this.MergePersonForm.controls.origbio; }
  get orignotesControl() { return this.MergePersonForm.controls.orignotes; }


  makeForm() {
    this.MergePersonForm = this.formBuilder.group({
      userUID: new FormControl('', Validators.compose([])),
      actContactId: new FormControl('', Validators.compose([])),
      origkey: new FormControl('', Validators.compose([])),
      newkey: new FormControl('', Validators.compose([])),
      origcontact: [],
      newcontact:[],
      origdetails: [],
      newdetail: [],
      origprimaryEmail: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      newprimaryEmail: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      orignatnum: [],
      newnatnum: [],
      origbio: new FormControl('', Validators.compose([])),
      newbio: new FormControl('', Validators.compose([])),
      orignotes: new FormControl('', Validators.compose([])),
      newnotes: new FormControl('', Validators.compose([])),
      origpersonOrOther: new FormControl('', Validators.compose([])),
      newpersonOrOther: new FormControl('', Validators.compose([])),
      origsource: new FormControl('', Validators.compose([])),
      newsource: new FormControl('', Validators.compose([])),
      origBirthOrder: new FormControl('', Validators.compose([])),
      newBirthOrder: new FormControl('', Validators.compose([])),
    });

  }

  onClickAcceptDifferences() { // Only get here if there ARE differences that are new
    const newP = mergePersonWithOrigForm(this.origPerson, this.MergePersonForm.value);

    newP.differences = [{
      source : this.extSource,
      acceptedAlready: true,
      differences: this.differences
    }]
    console.log('Submit person form - valid' + JSON.stringify(newP));
    this.subscriptions.push( this.arangoService.updatePerson( newP ) 
    .subscribe ( (result) => {
      console.log('Accpeted Diff result  is: ' + JSON.stringify(result.content));
      this.emitterService.emitter.emit('Message','Accepted Differences ' + contactFullName(newP.contact) + ' done');
      
      this.notifyParent.emit('merge person Saved');
    }))
  }

  submitMergePersonForm() {

    if (this.MergePersonForm.valid) {
      console.log('Submit person form - valid' );
      const newP =  mergePersonWithOrigForm(this.origPerson,this.MergePersonForm.value);
     
      this.subscriptions.push( this.arangoService.updatePerson( newP, true )  // does the merge
      .subscribe (async (result) => {
        const act: Activity = {
          _key :createUUID(),
          from : newP._key,
          to : newP._key,
          kind : 'Admin Merged data',
          note : 'User data changed...',
          date : DateToYYMMDDhhmmss(),
          by : contactFullName(this.user.contact),
          meta : updateMeta()
        }

        const active = await  firstValueFrom(this.activityService.addActivity( act, 'person'))
        if (canContact(newP.contact)) {
        const added = await firstValueFrom(this.activeCampaignService.syncContact(newP)); // Update actcam too
        }
        console.log('Merge result  is: ' + JSON.stringify(result.content));
        this.emitterService.emitter.emit('Message','Merged ' + contactFullName(newP.contact) + ' merged');
        
        this.notifyParent.emit('merge person Saved');
      }))
 
    } else {
      alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.MergePersonForm)));
    }
    //this.MergePersonForm.reset();
  }

  


  onPageChange(event){
    console.log(event);
  }

  

  onClickOverwriteDB($event: any) {
   // console.log('Replace ' + JSON.stringify($event));
    const formV = this.MergePersonForm.value;
    
    
    console.log('Replace ' + JSON.stringify(formV));
    switch ($event) {
      case 'contact':
        formV.origcontact = makeContactFromForm( formV.newcontact);
        console.log('new contact '+ JSON.stringify( formV.origcontact));
        break;
      case 'natnum':
        formV.orignatnum= formV.newnatnum;
        formV.orignatnum.identified.setBy = {
          name: formV.newnatnum.identified.setBy
        }
        formV.orignatnum.asserted.setBy = {
          name: formV.newnatnum.asserted.setBy
        }
        formV.orignatnum.invited.setBy = {
          name: formV.newnatnum.invited.setBy
        }
        break;
      case 'primaryemail': //chnage primaryEmail and add to history
        const added = addEmail(formV.origcontact.email, formV.origcontact.email.DNC,formV.newprimaryEmail )
        formV.origprimaryEmail = formV.newprimaryEmail;
        formV.origcontact.email = mergeEmail(formV.origcontact.email, added)
        break;
      case 'notes':
        formV.orignotes = formV.newnotes;
        break;
      case 'bio':
        formV.origbio = formV.newbio;
        break;
      case 'source':
        formV.origsource = formV.newsource;
        formV.origpersonOrOther = formV.newpersonOrOther;
        formV.origBirthOrder = formV.newBirthOrder;
        break;
      default:
        console.error('Unknown Replace request '+JSON.stringify($event))
    }
    console.log('Replaced ' + JSON.stringify(formV.orignatnum));
    this.origPerson = {
      userUID: formV.userUID,
      contact: formV.origcontact,
      primaryEmail: formV.origprimaryEmail,
      naturalNumbers: formV.orignatnum,
      _key: formV.origkey,
      details: formV.details,
      bio: formV.origbio,
      notes: formV.orignotes,
      source: formV.origsource,
      referralSource: formV.origsource,
      personOrOtherReferredBy: formV.origpersonOrOther,
      appEntered: 'Admin',
      externalIds: {
        activeCampaign:'',
      },
      agreements:[],
    }
    this.MergePersonForm.patchValue({
      origkey: formV.origkey,
      origprimaryEmail: formV.origprimaryEmail,
      origcontact: formV.origcontact,
      orignatnum: formV.orignatnum,
      details: formV.details,
      origbio: formV.origbio,
      orignotes: formV.orignotes,
      origsource: formV.origsource,
      origpersonOrOther: formV.origpersonOrOther
    })
  }


  ngOnChanges() {
    console.log('OnChange ');
    this.MergePersonForm.reset();
    if (this.origPerson && this.newPerson) {
      const p: Person = this.origPerson
      const np: Person = this.newPerson
      console.log('Expand Person '+ JSON.stringify(p));


    // this.contact = semiContact;
      this.MergePersonForm.patchValue({
        origkey: p._key,
        origprimaryEmail: p.primaryEmail,
        origcontact: p.contact,
        origbio: p.bio,
        orignotes: p.notes,
        orignatnum: p.naturalNumbers,
        newprimaryEmail: np.primaryEmail,
        newcontact: np.contact,
        newbio: np.bio,
        newnotes: np.notes,
        newnatnum: np.naturalNumbers,
        origsource: p.source,
        newsource: np.source,
        origpersonOrOther: p.personOrOtherReferredBy,
        newpersonOrOther: np.personOrOtherReferredBy,
      });
    }

  }

}
