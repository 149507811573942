
import { Component, forwardRef, ChangeDetectionStrategy, OnDestroy, OnInit, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { validEvents } from '@tinymce/tinymce-angular/editor/Events';
import { truncate } from 'fs';
import { Observable, of, Subscription } from 'rxjs';
import { EmailCheckDialogComponent } from 'src/displayComponents/dialogs/emailCheckDialog/emailCheckDialog.component';
import { validationMessages } from 'src/helpers/form.helper';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { AuthService } from 'src/services/auth/auth.service';
import { EmailService } from 'src/services/email/email.service';
import { SearchService } from 'src/services/search/search.service';
import { SystemVariablesService } from 'src/services/systemvariables/systemvariables.service';
import { EmailContactItem, EmailContactType } from '../../../../../bo9-shared/models/contact/email.model';
import { userIsAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { responseEncode } from '../../../../../bo9-shared/uuid.service';

// todo replace this with BO9Contact

export interface EmailFormValues {
  DNC: string;
  items: EmailContactItem[]
}

@Component({
    selector: 'email-form',
    templateUrl: './emailForm.component.html',
    styleUrls: ['./emailForm.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EmailFormComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => EmailFormComponent),
            multi: true,
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
  export class EmailFormComponent implements OnInit ,ControlValueAccessor, OnDestroy {
    loading = false;
    form: FormGroup = this.formBuilder.group({
      DNC: ['', Validators.compose([Validators.required])],
      items: this.formBuilder.array([])
    });
    subscriptions: Subscription[] = [];
    validationMessages = validationMessages;
    disableBtn = false;
    contactTypes;
    user: Person;
    totalToValidate= 0;
    maxVerifications = 0;


    constructor(private formBuilder: FormBuilder,
      private cdr: ChangeDetectorRef,
       private dialog: MatDialog,
       private router: Router,
       private arangoService: ArangoService,
        private auth: AuthService,
        private systemVariablesService: SystemVariablesService,
        ) {
      console.log('email Init '+ JSON.stringify(this.value));
      this.user = this.auth.getAuthUser();

      this.contactTypes = Object.keys(EmailContactType).map(key => EmailContactType[key]).filter(value => typeof value === 'string') as string[];
      this.subscriptions.push( this.systemVariablesService.getSystemVariable('MaxOpenEmailValidations')
      .subscribe( ( answer) => {
        this.maxVerifications = +answer;
      }))
      this.subscriptions.push(
        this.form.valueChanges.subscribe( (value: EmailFormValues) => {
          //console.log('email valuechange '+ JSON.stringify(value));
          this.onChange(value);
          this.onTouched();
        }))

      this.subscriptions.push( this.arangoService.recordVisit({
        page: this.router.url, component: 'EmailFormComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}}))
    }

    checkCanAdd() {
      if (!this.form.value.DNC || this.totalToValidate <= this.maxVerifications) {
        return true;
      }
      return false;
    }
    checkUnique(index: number) {
      console.log('check email change '+ JSON.stringify(index));
      const val = this.items.at(index);

      if (val.value.value !== val.value.origValue) { // Changed Email
        const dRef = this.dialog.open(EmailCheckDialogComponent, {
          width: '75%',
          height: '75%',
          data: {email: val.value}
        });
    
        this.subscriptions.push( dRef.afterClosed()
        .subscribe(  (result: any) => {
          if (result && result.reset) {
            this.removeEmailItem(index);
          }  else if (result) {
            console.log('check set item '+ JSON.stringify(result.item));
            val.patchValue(result.item);
            this.items.setControl(index,val);
          }          
          
        }))
      }
    }

    ngOnInit() {
      this.totalToValidate= 0;
    }


  
    get value(): EmailFormValues {
      console.log('email get '+ JSON.stringify(this.form.value));
      return this.form.value;
    }
  
    set value(value: EmailFormValues) {
      console.log('email set '+ JSON.stringify(value));
      console.log('email length '+ JSON.stringify(this.items.length));
      this.items.clear();

      for(const item of value.items) {
        console.log('email push '+ JSON.stringify(item));
        item.origValue = item.value;
        if (value.items.length == 1) {
          item.primary = true; // If there's only is HAS to be primary
        }
        
        if (!this.form.value.DNC && item.toValidate) {
          this.totalToValidate++;
        }

          this.items.push( this.newEmailItem());



      }

      this.form.patchValue(value);
      console.log('email length after '+ JSON.stringify(this.items.length));
      this.cdr.detectChanges();
    }

    get DNCControl() { return this.form.controls.DNC;}  
    items: FormArray = this.form.get('items') as FormArray;
  
    newEmailItem() : FormGroup {
      return this.formBuilder.group( {
        primary: [false, Validators.compose([])],
        type: ['', Validators.compose([])],
        status: ['', Validators.compose([])],
        deleted: ['', Validators.compose([])],
        value: ['', Validators.compose([Validators.required])],
        toValidate: [''],
        origValue: ['']
      })
    }

    selectPrimary(element: any) {
      console.log('num items '+ JSON.stringify(this.items.length));
        if (this.items.length > 0) {
          let p: boolean = false;
          for (let i=0; i<this.items.length; i++ ) {
            const val = this.items.at(i);
            if (element === i) {
              p = val.value.primary; // The original value
            }
          }
          
          for (let i=0; i<this.items.length; i++ ) {
            const val = this.items.at(i);
            if (p) { // Was originally primary, make it not, but don't affect any others
              if (element === i) {
                val.patchValue({primary: !p});
              } 
            } else { // Was orignally not primary, make it primary, clear all others
              if (element === i) {
                val.patchValue({primary: !p});
              } else {
                val.patchValue({primary: false});
              }
            }

            
            this.items.setControl(i, val);
          }
        }
  
    }

    selectDNC() {
      //console.log('dnc '+ JSON.stringify(this.form.value.DNC));
      this.form.controls.DNC.setValue(!this.form.value.DNC);
      // console.log('dnc '+ JSON.stringify(this.form.value.DNC));
    }


    addEmailItem() {
      if (!this.form.value.DNC) {
        this.totalToValidate++;
      }

      this.items.push( this.newEmailItem());
      console.log('Add email item '+ this.totalToValidate);
     
    }

    removeEmailItem(i: number) {
      if (confirm('Please confirm delete')) {
        if (this.items.length !== 1) {
          if (this.totalToValidate > 0) { // Admin can just set it
            this.totalToValidate--;
          }
          this.items.removeAt(i);
        }
        console.log('Remove email item '+ this.totalToValidate);
      }

    }

    ngOnDestroy() {
      console.log('email destroy ');
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  
    onChange: any = () => {

    };
    onTouched: any = () => {};
  
    registerOnChange(fn) {
      this.onChange = fn;
    }
  
    writeValue(value) {
      console.log('email write value ' + JSON.stringify(value));
      if (value) {
        this.value = value;
      }
  
      if (value === null) {
        this.form.reset();
      }
    }
  
    registerOnTouched(fn) {
      this.onTouched = fn;
    }
  
    validate(_: FormControl) {
      return this.form.valid ? true : false;
    }
  
    reset() {
      this.form.reset();
    }
  }