  <mat-card class="bo9mat" *ngIf="operation=='relations'">
    <button mat-stroked-button (click)="onCloseClick()" >Close</button>
    <relation-list 
      [attendees]="attendees"  
      [from]="fromF$ | async" 
      [name]="fromName"
      (notifyParent)="relationSubmittedHandler($event)" 
      [canAll]=false ></relation-list>
  </mat-card>

  <mat-card class="bo9mat" *ngIf="operation=='singleemail'">
    <button mat-stroked-button (click)="onCloseClick()" >Close</button>
    <dupemail-list 
      [attendees]="attendees"  
      [emailList]="emailList$ | async" 
      (notifyParent)="emailHandler($event)" ></dupemail-list>
  </mat-card>
  <mat-card class="bo9mat" *ngIf="operation=='editattendee'" >
    <button mat-stroked-button (click)="onCloseClick()"  >Close</button>
      <attendee-form [event]="event" [attendees]="attendees" (notifyParent)="attendeeSubmittedHandler($event)" [attendee]="attendee"></attendee-form>
  </mat-card>
  <mat-card class="bo9mat" *ngIf="operation=='subscriptions'">
    <button mat-stroked-button (click)="onCloseClick()">Close</button>
    <subscription-list [from]="fromF$ | async" ></subscription-list>
  </mat-card>
  <mat-card class="bo9mat">
    
   
    <span  *ngIf="event?.source!='calendly'"><button mat-button [cdkCopyToClipboard]="'https://bodyof9.com/event-registration/?ee='+event?._key"><mat-icon >content_copy</mat-icon></button><a href="https://bodyof9.com/event-registration/?ee={{event?._key}}" target="_blank">Register Link</a>	</span>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin'> </mat-progress-spinner>
    <h3 #table>{{ event?.name}}: {{ event?._key}} : {{pageTitle}}</h3>  
    <button mat-stroked-button (click)="onClickAddAttendee()">Add {{pageTitle}}<mat-icon>add_circle</mat-icon></button>	

   <button mat-stroked-button (click)="onRefreshClick()"  [disabled]="disableBtn" >Refresh<mat-icon>refresh</mat-icon></button> 

   <span *ngIf="!isMobile()"><button mat-stroked-button (click)="onPreview()">Print Roster<mat-icon>print</mat-icon></button>	</span>

        <table mat-table [dataSource]="dataSource" matSort class="full_width">
          <ng-container matColumnDef="controls">
            <th mat-header-cell *matHeaderCellDef> Controls </th>
            <td mat-cell *matCellDef="let element; let i = index">

              <span *ngIf="i==0" >
              <span><button type="button" (click)="onClickEdit(element)" matTooltip="Edit Attendee"> <mat-icon>edit</mat-icon></button></span>
              <span *ngIf="element.person!=null"><button type="button" (click)="onClickView(element)" matTooltip="Edit Contact"> <mat-icon>preview</mat-icon></button></span>
              <span *ngIf="element.person!=null"> <button type="button" (click)="onClickRelate(element)" matTooltip="Relations"> <mat-icon>family_restroom</mat-icon></button></span>
              <span *ngIf="element.person!=null"> <button type="button" (click)="onClickSubscriptions(element)" matTooltip="Subscriptions"> <mat-icon>subscriptions</mat-icon></button></span>
              <span *ngIf="element.person!=null && (!element.diffAccepted && (element.difference && element.difference.length > 0))"> <button  type="button" (click)="onClickMerge(element)" matTooltip="Merge"> <mat-icon>merge_type</mat-icon></button></span>
            </span>
            <span *ngIf="i!=0" >
              <span ><button type="button" (click)="onClickEdit(element)" > <mat-icon>edit</mat-icon></button></span>
              <span *ngIf="element.person!=null"><button type="button" (click)="onClickView(element)" > <mat-icon>preview</mat-icon></button></span>
              <span *ngIf="element.person!=null"> <button type="button" (click)="onClickRelate(element)" > <mat-icon>family_restroom</mat-icon></button></span>
              <span *ngIf="element.person!=null"> <button type="button" (click)="onClickSubscriptions(element)"> <mat-icon>subscriptions</mat-icon></button></span>
              <span *ngIf="element.person!=null && (!element.diffAccepted && (element.difference && element.difference.length > 0))"> <button type="button" (click)="onClickMerge(element)"> <mat-icon>merge_type</mat-icon></button></span>
            </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef> Edit </th>
            <td mat-cell *matCellDef="let element">
              <span ><button type="button" (click)="onClickEdit(element)" > <mat-icon>edit</mat-icon></button></span><br>
              <span *ngIf="element.person!=null"> <button type="button" (click)="onClickRelate(element)" > <mat-icon>family_restroom</mat-icon></button></span>
            </td>
          </ng-container>

          <ng-container matColumnDef="who">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Who </th>
            <td mat-cell *matCellDef="let element">  {{element.first}} {{element.last}} <br> {{element.email}}   </td>
          </ng-container>
          <ng-container matColumnDef="key">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Att.id </th>
            <td mat-cell *matCellDef="let element"> {{element._key}} </td>
          </ng-container>
          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Appt.</th>
            <td mat-cell *matCellDef="let element"> {{element.start_date}} <br />{{element.event_time}} </td>
          </ng-container>
          <ng-container matColumnDef="first">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First </th>
            <td mat-cell *matCellDef="let element" matTooltip="{{element._key}} ">
              {{element.first}} 
              <span *ngIf="element.first != element.dbFirst" style="background-color: red"><br/>{{ element.dbFirst }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="last">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last </th>
            <td mat-cell *matCellDef="let element" matTooltip="{{element._key}} "> {{element.last}} 
              <span *ngIf="element.last != element.dbLast" style="background-color: red"><br/>{{ element.dbLast}}</span> </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let element">
            <span *ngIf="element.email " > <button mat-button [cdkCopyToClipboard]="element.email"><mat-icon >content_copy</mat-icon></button> {{element.email}}  </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="dnc">
            <th mat-header-cell *matHeaderCellDef class="td-center-text"> DNC </th>
            <td mat-cell *matCellDef="let element" class="attended-col, td-center-text"> 
          <span *ngIf="element.person"> <mat-checkbox (change)="selectDNC(element)" [checked]="element.person.contact.email.DNC"></mat-checkbox></span>  </td>
          </ng-container>
          <ng-container matColumnDef="guild">
            <th mat-header-cell *matHeaderCellDef class="td-center-text"> Guild </th>
            <td mat-cell *matCellDef="let element" class="attended-col, td-center-text"> 
          {{element.guild }} </td>
          </ng-container>
          <ng-container matColumnDef="idBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="td-center-text panelClass" disableOptionCentering > Id By </th>
            <td mat-cell *matCellDef="let element">  
               <mat-select matNativeControl multiple [(ngModel)]="element.idBy" (selectionChange)="byIdSelected($event, element)">
                <mat-option *ngFor="let nns of idList" [value]="nns">
                  {{ nns }}
                </mat-option>
              </mat-select> </td>
          </ng-container>
          <ng-container matColumnDef="assBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="td-center-text panelClass" disableOptionCentering > Ass By </th>
            <td mat-cell *matCellDef="let element">  
               <mat-select matNativeControl multiple [(ngModel)]="element.assBy" (selectionChange)="byAssSelected($event, element)">
                <mat-option *ngFor="let nns of idList" [value]="nns">
                  {{ nns }}
                </mat-option>
              </mat-select> </td>
          </ng-container>
          <ng-container matColumnDef="ass">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ass. </th>
            <td mat-cell *matCellDef="let element; let i = index" class="attended-col, td-center-text">  
              <mat-select matNativeControl [(ngModel)]="element.ass" (selectionChange)="nnAsserted($event, element, i)" [disabled]="!element.idBy">
                <mat-option *ngFor="let nns of nnKeys" [value]="nns">
                  {{ nns }}
                </mat-option>
              </mat-select> 
               <!-- <span *ngIf="element.nnIssue">!!</span> <span class='othernn'>/ {{ element.sourceType }}</span>-->
              </td>
          </ng-container>
          <ng-container matColumnDef="nn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> NN </th>
            <td mat-cell *matCellDef="let element; let i = index" class="attended-col, td-center-text">  
              <mat-select matNativeControl [(ngModel)]="element.nn" (selectionChange)="nnSelected($event, element, i)" [disabled]="!element.idBy">
                <mat-option *ngFor="let nns of nnKeys" [value]="nns">
                  {{ nns }}
                </mat-option>
              </mat-select> 
               <!-- <span *ngIf="element.nnIssue">!!</span> <span class='othernn'>/ {{ element.sourceType }}</span>-->
              </td>
          </ng-container>

          <ng-container matColumnDef="attended">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="td-center-text"> (Hours) Attended </th>
            <td mat-cell *matCellDef="let element; let i = index" class="attended-col">
              <span *ngIf="isTraining(element)"><mat-form-field class="hourinput"><input class="hours" matInput [(ngModel)]="hours[i]" value="hours[i]"/></mat-form-field></span>
              <mat-checkbox (change)="clickAttended($event,element, i)" [(ngModel)]="attended[i]" [checked]="attended[i]"></mat-checkbox></td>
          </ng-container>
       
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> City </th>
            <td mat-cell *matCellDef="let element"> {{element.city}} </td>
          </ng-container>
          <ng-container matColumnDef="zip">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Zip </th>
            <td mat-cell *matCellDef="let element"> {{element.zip}} </td>
          </ng-container>
          <ng-container matColumnDef="paid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Paid </th>
            <td mat-cell *matCellDef="let element; let i = index">
               <mat-form-field class="paidinput" matTooltip="{{ element.transaction_details?.description }}"><input class="paid" matInput [(ngModel)]="paid[i]" value="paid[i]"/></mat-form-field>
              <span *ngIf="element.transaction_details?.charges.data.length == 0" (click)="changePaid(element,i)" matTooltip="Click to accept changes" ><mat-icon>currency_exchange</mat-icon></span>

          </ng-container>
          <ng-container matColumnDef="erase">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete / Erase </th>
            <td mat-cell *matCellDef="let element"> <button mat-button type="button" (click)="onClickDelete(element)"  [disabled]="disableBtn" > <mat-icon>delete</mat-icon></button>
            <span *ngIf="event && event.eventCode!='Calendly'">  <button mat-button type="button" (click)="onClickErase(element)"  [disabled]="disableBtn" > <mat-icon>auto_fix_normal</mat-icon></button></span></td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete / Erase </th>
            <td mat-cell *matCellDef="let element"> <button mat-button type="button" (click)="onClickDelete(element)"  [disabled]="disableBtn" > <mat-icon>delete</mat-icon></button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="colList"></tr>
          <tr mat-row *matRowDef="let row; columns: colList;"></tr>
        
          </table>
          <mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]">
          </mat-paginator>
  
</mat-card>
