import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild,OnDestroy, ChangeDetectorRef, AfterViewInit, HostListener } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';

import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person, mkEmptyPerson} from '../../../../bo9-shared/models/person/person.model';
import { Difference } from '../../../../bo9-shared/models/person/differences.model';
import { validationMessages } from 'src/helpers/form.helper';

import { EventDBService } from 'src/services/events/eventdb.service';
import { Event, eventInList, mkMTEvent } from '../../../../bo9-shared/models/event/event.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';

import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';
import { TransportNative } from '../../../../bo9-shared/models/mailCampaign/mailCampaign.model';
import { EventEmitterService } from 'src/services/events/events.service';

import { EspressoEventAttendeeCount, EspressoEventCategory, makeCategoryList } from '../../../../bo9-shared/models/event/event9e.model';
import { addDays, DateToYYMMDD,  showTZ, UTCTimeFromDateandtTimezone } from '../../../../bo9-shared/helpers/time.helper';
import { php_serialize, php_unserialize} from '../../../../bo9-shared/helpers/php.helper';
import { SearchService } from 'src/services/search/search.service';
import { getTagId } from '../../../../bo9-shared/models/mailCampaign/activeCampaign.model';

import { getMyEvents } from 'src/services/events/makeEventLists.service';
import { userIsAdmin } from '../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { DeviceDetectorService} from 'ngx-device-detector'

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss'],
    standalone: false
})
export class EventsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  resizeTimeout: any;
  disableBtn = false;
  messagesLoading$: Observable<boolean>;
  people$: Observable<Person[]>;
  totalFound: number=0;
  totalFound$: Observable<number>;
  pageNum$: Observable<number>;
  totalPages$: Observable<number>;
  pageNum: number;
  totalPages: number;

  people: Person[];
  currentPane = 'center';
  valid: false;
  noAttendees = [];

  origp: Person;
  newp: Person;

  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchToString: string;
  searchFromString: string;
  search: string;

  public displayedColumns = ['view', 'viewatt', 'name', 'startDate', 'timezone', 'categories', 'attendees', 'source', 'status', 'hasVideo', 'delete'];

  public displayedNarrowColumns = ['smallatt',  'name', 'startDate','attendees'];

  public colList: string[] = [];
  public dataSource = new MatTableDataSource<Event>();

  currentP$: Observable<Person>;
  currentEvent$: Observable<Event>
  currentPerson: Person;
  RelateForm: FormGroup;
  newCode: string[];
  relatingPersonName: string;
  totalEvents: number;
  eventNum = 0;
  event: Event;
  origP: Person;
  loading: boolean;
  newP: Person;
  difference: Difference;
  operation="Merge";
  lastPane: string;
  tagList: TransportNative;
  user:Person;

  validationMessages = validationMessages;
  events: Event[];
  pageSize: number=15;
  catListDB: string[] = [];
  fullCategories: EspressoEventCategory[] = [];
  updated= false;
  onlyWordpress = false;
  onlyCalendly = false;
  subscriptions: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private eventdbService: EventDBService,
    private arangoService: ArangoService,
    private searchService: SearchService,
    private activeCampaignService: ActiveCampaignService,
    private auth: AuthService,
    private router: Router,    
    private deviceService: DeviceDetectorService,
    private emitterService: EventEmitterService
    )
  {

    this.makeForm();
    this.user = this.auth.getAuthUser();
   this.newCode = [];
  
      if (this.isMobile()) {
        this.colList = this.displayedNarrowColumns;
      } else {
        this.colList = this.displayedColumns;
      }
    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #018'); 
      this.router.navigate(['login'] );
    } 
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'EventsComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: async  () => {
      const result = await firstValueFrom(this.activeCampaignService.getContactTagFieldValues(false));
      this.tagList = result;
      if (this.user.preferences && this.user.preferences.eventHistory && this.user.preferences.eventFutureDays) {
        this.searchFromString = DateToYYMMDD(addDays(new Date(), -this.user.preferences.eventHistory));
        this.searchToString = DateToYYMMDD(addDays(new Date(), +this.user.preferences.eventFutureDays));
      } else {
        this.searchFromString = DateToYYMMDD(addDays(new Date(), -60));
        this.searchToString = DateToYYMMDD(addDays(new Date(), +15));
  
      }
      if (this.user.preferences) {
        this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
      }
  
      this.search = '';
      await this.loadEvents(); // refreash
      this.subscriptions.push(this.eventdbService.getCategories()
      .subscribe( (res) => {
        console.log('get categories ' + JSON.stringify(res));
        this.fullCategories = res.content;
        for (const cat of res.content as EspressoEventCategory[]) {
          this.catListDB.push(cat.category_name);
        }
        
      }));
      
  
    }})); 
  }
  fixEvent(i, newE) {
    console.log("Fix event");

    let nm;
    if (newE.event_meta) {
       nm = php_unserialize(newE.event_meta)
    } else {
      nm={};
    }
    nm.event_code = this.newCode[i];
    const newEM = php_serialize(nm);
    console.log("new serialize "+newEM);
    newE.event_meta = newEM;
    this.arangoService.updateEventMeta(newE._key, newEM)
    .subscribe( (result) => {
      alert("Event meta updated");
    })
  }

  isMobile(): boolean {
    return this.deviceService.isMobile()
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
}

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onClickOnlyCalendly() {
    this.onlyCalendly = !this.onlyCalendly;
  }

  onClickOnlyWordpress() {
    this.onlyWordpress = !this.onlyWordpress;
  }
  get emailControl() { return this.RelateForm.controls.email; }
  get nameControl() { return this.RelateForm.controls.name; }



  makeForm() {
    this.RelateForm = this.formBuilder.group({
      name: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([ Validators.email])),
      Invite: new FormControl('', Validators.compose([])),
      pearlmodule: new FormControl('', Validators.compose([])),
    });
  }


  async loadEvents(all?: boolean) {
    this.disableBtn = true;
    console.log("Time From "+JSON.stringify(this.searchFromString));
    console.log("Time To "+JSON.stringify(this.searchToString));
    this.loading = true;
    this.dataSource.data = [];
    const eList: Event[] = [];
    console.log("Prefs "+JSON.stringify(this.user.preferences));

    if (!this.onlyCalendly) {
      const myEvents = await getMyEvents(this.user.primaryEmail,this.eventdbService, this.searchService);
      const result = await firstValueFrom(this.eventdbService.getEvents(this.searchFromString, this.searchToString, this.search,all));
      if (result.content) {
        const e = result.content.events as Event[];
        const ats = result.content.attendees as EspressoEventAttendeeCount[];
        const cats = result.content.categories as EspressoEventCategory[];

        for (let i =0; i < e.length; i++) {
          if (e[i].event_status !== 'D') {


            this.newCode.push('');
          /*
            if (e[i].event_meta) {
              e[i].event_meta_object = php_unserialize(e[i].event_meta);

             if (e[i].event_meta_object  && e[i].event_meta_object.event_code) {
               e[i].eventCode = e[i].event_meta_object.event_code;
             } else {
              e[i].eventCode = '';
             }
            }
            if ( e[i].eventCode) {
              const credres:any = await firstValueFrom(this.arangoService.getCredit(e[i].eventCode));
              console.log("CREDIT "+JSON.stringify(credres));
              if (credres && credres.content && credres.content.credit && credres.content.credit.length > 0) {
                  e[i].registerHours = addRegisterHours(credres.content.credit[0].hours,  e[i].registerHours );
              }
            } */
            e[i].eventCode = 'Wordpress';
            e[i].startTimeUTC = UTCTimeFromDateandtTimezone(e[i].startTime, e[i].startDate, e[i].timezone);
            e[i].startTime =  e[i].startTimeUTC.getHours()+":"+ e[i].startTimeUTC.getMinutes().toString().padEnd(2,"0");
            const dbEvent = await firstValueFrom(this.arangoService.getEventById(e[i]._key));
            if (dbEvent && dbEvent.content && dbEvent.content.events && dbEvent.content.events.length > 0) {
              e[i].event_status = dbEvent.content.events[0].event_status? dbEvent.content.events[0].event_status : 'Open';
              e[i].recording_link = dbEvent.content.events[0].recording_link? dbEvent.content.events[0].recording_link : '';
              e[i].identifiers = dbEvent.content.events[0].identifiers? dbEvent.content.events[0].identifiers: ['Susan Fisher','Martin Fisher'];
            

            }
            if (e[i].event_status && (e[i].event_status=='Closed'  || e[i].event_status =='D')) {
              // Nothing
            } else {
               e[i].event_status = "Open";
            }
         
          e[i].tagId = getTagId('EE'+e[i]._key, this.tagList.tags);
          for (let j=0; j< ats.length; j++ ) {
            if (ats[j].event_id === e[i]._key.toString()) {
              e[i].attendees = ats[j].attendees
              break;
            }
          }
            if (userIsAdmin(this.user) || eventInList(e[i]._key, myEvents) || this.user.primaryEmail == e[i].user_email) {  // Add event creator to allowed
              e[i].editable = true;
            } else {
              e[i].editable = false;
            }
          e[i].categories = [];
          e[i].categories = makeCategoryList(e[i]._key, cats);

            //console.log('Events search ' + JSON.stringify(res.event[0]));

          eList.push(e[i]);
        }
        }
      }
    }
    
    if (!this.onlyWordpress) {
      const arangoEvents = await  this.eventdbService.getCalendlyEvents(this.searchFromString, this.searchToString,this.search, all);
      if (arangoEvents) {
        for (const cal of arangoEvents.events) {
          cal.startTimeUTC = UTCTimeFromDateandtTimezone(cal.startTime, cal.startDate, cal.timezone);
          const attendees = await  firstValueFrom(this.arangoService.getEventAttendees(cal._key));
          cal.attendees = attendees.content.total[0];
          if (userIsAdmin(this.user)) {
            cal.editable = true;
          } else {
            cal.editable = false;
          }
          if (cal.event_status && (cal.event_status=='Closed'  || cal.event_status =='D')) {
            // Nothing
          } else {
             cal.event_status = "Open";
          }
          cal.eventCode = 'Calendly';
          eList.push(cal);
        }
      }
    }
    


      eList.sort ( (first,second) => {
        const firstDate = new Date(first.startDate);
        const secondDate = new Date(second.startDate);
        if (first.event_status == 'Closed' && second.event_status == 'Open') {
          return 1;
        } else if (second.event_status == 'Closed' && first.event_status == 'Open') {
          return -1;
        } else {
          if (firstDate && secondDate) {
            if (firstDate > secondDate) return -1;
            if (firstDate < secondDate) return 1;
            return 0;
          } else {
            console.error("date sort fail no date" +JSON.stringify(first.startDate) + ' ' + JSON.stringify(second.startDate));
            if (firstDate) return -1;
            if (secondDate) return 1;
            return 0;
          }
        }
      })

      this.dataSource.data = eList;
        
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
     
      this.loading = false;
      this.updated = false;
      this.disableBtn = false;
      console.log('Events loaded');
    }


  ngOnInit() {
    console.log("Time From default "+JSON.stringify(this.searchFromString));

      
  }

  isAdmin(): boolean {
    return(userIsAdmin(this.user));
  }

  onRefreshClick() {
    console.log('Refresh click ');
    this.activeCampaignService.tagAndFields.customFields = []; // force reload
    this.subscriptions.push( this.activeCampaignService.getContactTagFieldValues(false)
    .subscribe( (res) => {
        this.tagList = res;

        this.loadEvents(); // refreash
        
    }))

  }

  onDelete($event) {
    console.log('Delete click '+JSON.stringify($event));
    if (confirm("Are you sure you want to delete this Event? "+ $event.name + '(ID: '+$event._key+ ')')) {
      this.disableBtn=true;
      this.subscriptions.push( this.arangoService.deleteEvent($event)
      .subscribe ( (result) => {
        this.updated= true;
        console.log('Save result  is: ' + JSON.stringify(result.content));
        this.emitterService.emitter.emit('Message','Event ' +  $event.name + ' deleted');
        this.disableBtn=false;
        if (this.user.preferences && this.user.preferences.autoRefresh) {
          this.loadEvents(); // refreash
        }
        

      }))
    }


  }

  onClickCreateTag($event) {
    console.log('create Tag '+JSON.stringify($event));
    this.activeCampaignService.createTag('EE'+$event._key)
    .subscribe( (res) => {
      alert ('create Tag '+JSON.stringify(res));
      this.activeCampaignService.tagAndFields.customFields = []; // force reload
      this.activeCampaignService.getContactTagFieldValues(false)
      .subscribe( (res) => {
        this.tagList = res;


    })
    });

  }

  onClickClose(e: Event) {
    this.disableBtn = true;
    this.searchService.searchEventKey(e._key, true)
    .then( async (result) => {
     console.log('Event DB '+ JSON.stringify(result));
     if (result && result.event && result.event.length > 0) { // Pulled in from the DB
        const dbEvent: Event = result.event[0]
        e.identifiers= dbEvent.identifiers;
        e.recording_link = dbEvent.recording_link;
        e.categories = dbEvent.categories;
     }
      e.event_status = 'Closed';
      e.registration_end = '2000-01-01';
      let catList;
      let catIDs: string[] =[];
      if (e.categories) {
        catList = e.categories;
        for (const cl of catList) {
          for (const c of this.fullCategories) {
            if (c.category_name === cl) {
              catIDs.push(c.event_id.toString());
            }
          }
        }
      }

      console.log('Close '+JSON.stringify(e));
      const resultu = await firstValueFrom(this.arangoService.updateEvent(e,  catIDs, catList));
      console.log('Update result  is: ' + JSON.stringify(resultu.content));
      this.disableBtn = false;

    });
   
  }


  onChangePage(event){
    console.log(event);
    this.pageNum = event.pageIndex
    this.pageSize = event.pageSize;
  }

  showKey (key: String) {
	  alert(key);
  }

  onCloseClick(where:string) {
    console.log('pane is  '+ where);

    if (this.currentPane == 'left' && where==='left') {
      this.currentPane = 'center';
    } else {
      this.currentPane = where;
    }

    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.loadEvents(); // refreash
    }

  }

  onClickAdd(p:Event) {
    console.log('Add Attendee '+ JSON.stringify(p.name));
    this.event = p;
    this.operation = "AddAttendee";
    this.lastPane = 'center';
    this.currentPane = 'right';
  }


  onClickAsserted() {


    this.operation = "Asserted";
    this.lastPane = 'center';
    this.currentPane = 'right';
  }


  onClickExpand(p:Event) {
    console.log('Expand Event '+ JSON.stringify(p.name));
    this.event = p;
    this.currentEvent$ = of(p);
    this.lastPane = this.currentPane;
    this.currentPane = 'left';
  }

  personSubmittedHandler($event: any) {
    console.log(' Person  submitted '+ JSON.stringify($event));
    this.lastPane = this.currentPane;
    this.currentPane = 'left';
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.loadEvents(); // refreash
    }
  }


  eventSubmittedHandler($event: any) {
    console.log(' Event  submitted '+ JSON.stringify($event));
    this.lastPane = this.currentPane;
    this.currentPane = 'center';
   // alert('prefs'+JSON.stringify(this.user.preferences));
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.loadEvents(); // refreash
    }
  }

  onClickSearchSome() {
    this.currentPane = 'center'
    this.loadEvents(false);
  }


  onClickSearchAll() {
    this.currentPane = 'center'
    this.loadEvents(true);
  }

  onClickToday() {
    this.currentPane = 'center'
    this.searchFromString = DateToYYMMDD(addDays(new Date(), -5));
    this.searchToString = DateToYYMMDD(addDays(new Date(), +1));
    this.loadEvents(false);
  }

  mergePerson($event: any) {
    console.log(' Merge  from Attendees '+ JSON.stringify($event));
    if ($event.new) { // Merge
      this.operation = "Merge"
      this.newP = $event.new;
      this.difference = $event.difference;

    } else {
      this.operation = "View"
    }
    this.origP = $event.orig;
    this.lastPane = this.currentPane;
    this.currentPane = 'right';
  }

  onClickView($event: any) {
    this.operation = "Event"
    this.event = $event;
    this.lastPane = this.currentPane;
    this.currentPane = 'right';
  }


  onClickAddEvent() {
    this.operation = "Event"
    this.event = null;
    this.lastPane = this.currentPane;
    this.currentPane = 'right';
  }

  attendeeSubmittedHandler($event: any) {
    console.log(' Attendee  submitted '+ JSON.stringify($event));
    this.lastPane = this.currentPane;
    this.currentPane = 'left';
    this.updated = true;
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.loadEvents(); // refreash
    }
  }

  editPerson($event: any) {
    console.log(' Attendee  submitted '+ JSON.stringify($event));
    this.currentP$ = of($event)
    this.lastPane = this.currentPane;
    this.currentPane = 'right';
  }

	showTZ(tz: string){
		return showTZ(tz);
	}


}
