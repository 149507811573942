import { Component, OnInit, ChangeDetectionStrategy, Input,  ViewChild, OnChanges, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { LoginService } from 'src/services/loginservice/login.service';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { contactFullName } from '../../../../../bo9-shared/models/contact/contact.model';
import { FormRelation, Relation} from '../../../../../bo9-shared/models/person/relation.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { BO9UUID } from '../../../../../bo9-shared/models/UUID.model';
import { RelationDialogComponent } from '../../dialogs/relationDialog/relationDialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from 'src/services/search/search.service';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { RelationsService } from 'src/services/relations/relations.service';
import { mkMTPearl, Pearl, PearlSearch } from '../../../../../bo9-shared/models/pearl/pearl.model';
import { PearlDialogComponent } from 'src/displayComponents/dialogs/pearlDialog/pearlDialog.component';
import { EventEmitterService } from 'src/services/events/events.service';
import { ParseTreeResult } from '@angular/compiler';
import { NaturalNumber, NaturalNumberTypes } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { SelectsService } from 'src/services/selects/selects.service';
import { DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';

@Component({
    selector: 'pearl-list',
    templateUrl: './pearlList.component.html',
    styleUrls: ['./pearlList.component.scss'],
    standalone: false
})
export class PearlListComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() pList: Pearl[];
  @Input() searchable: boolean;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() notifyParent: EventEmitter<Pearl> = new EventEmitter<Pearl>();
  public displayedColumns = ['edit', 'title', 'myNN', 'theirNN','relationship', 'pearlmodule', 'approved','timeStamp','delete'];
  public dataSource = new MatTableDataSource<Pearl>();


  messagesLoading$: Observable<boolean>;

  currentPane = 'center';
  valid: false;

  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;

  currentI$: Observable<FormRelation>;
  currentPerson: Person;
  loading = false;
  fieldValues = 0;
  relations: FormRelation[] =[];
  SearchForm: FormGroup;
  validationMessages = validationMessages;
  pearlCount = 0;
  selectedPearl$: Observable<Pearl>;

  PearlModuleTypes$: Observable<string[]>;
  PearlModuleTypes: string[]
  nnKeys: string[];
  nnSource: string[];
  invites: string[];
  onlyApproved: boolean = null;
  onlyNotApproved: boolean = null;
  allPearls: boolean = true;
  pageSize: number;
  disableBtn = false;
  pearlSearch: PearlSearch = null;
  user: Person;
  currentPearlPane = 'center';
  subscriptions: Subscription[] = [];
  constructor(

    private router: Router,
    private arangoService: ArangoService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private emitterService: EventEmitterService,
    private selectsService: SelectsService,
    )
  {
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'PearlListComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    this.currentPane = 'center';
    if (this.pList) {
      this.dataSource.data = this.pList;
    }
    this.subscriptions.push( this.selectsService.getSelects()
    .subscribe( (result) => {
      this.PearlModuleTypes = this.selectsService.selects.pearlmoduletypes;
      this.invites = this.selectsService.selects.invitetypes;
      
    }))
    this.nnKeys = Object.keys(NaturalNumber).map(key => NaturalNumber[key]).filter(value => typeof value === 'string') as string[];
    this.nnSource = Object.keys(NaturalNumberTypes).map(key => NaturalNumberTypes[key]).filter(value => typeof value === 'string') as string[];
    this.SearchForm = this.formBuilder.group({
      content: new FormControl('', Validators.compose([])),
      myNN: new FormControl('', Validators.compose([])),
      theirNN: new FormControl('', Validators.compose([])),
      relationship: new FormControl('', Validators.compose([])),
      pearlmodule: new FormControl('', Validators.compose([])),
      startDate: new FormControl('', Validators.compose([])),
      endDate: new FormControl('', Validators.compose([])),
    });

  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  clickApproved () {
    this.onlyApproved = true;
    this.onlyNotApproved = false;
    this.allPearls = false;

  }
  clickNotApproved () {
    this.onlyApproved = false;
    this.onlyNotApproved = true;
    this.allPearls = false;

  }
  clickAll () {
    this.onlyApproved = null;
    this.onlyNotApproved = null;
    this.allPearls = true;

  }

  addPearlClick() {
    this.selectedPearl$ = of(mkMTPearl());
    this.currentPane = 'left';
  }

  ngOnChanges() {
    this.loading = false;
    this.loadPearls(this.pearlSearch);
  }

  loadPearls(p: PearlSearch) {
    this.loading = true;
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
      this.subscriptions.push( this.arangoService.getPearls(p)
      .subscribe( (result1) => {
        if (result1) {
          const e = result1.content;
          this.dataSource.data = e as Pearl[];
          this.pearlSearch = null; // clean it out always TODO add clear button
          this.loading = false;
        }
        
      }))

  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }


  onClickEdit(p:Pearl) {

    //console.log('Expand Invite  '+ JSON.stringify(p));

      console.log('Expand Pearl  Contact '+ JSON.stringify(p));
      this.notifyParent.emit(p);

  }



  onClickPearl() {
    this.currentPane = 'left';


  }

  makeForm() {

  }


  ngOnInit() {
    //this.loadRelations();
  }


  onRefreshClick() {
    console.log("refresh");
    this.onlyApproved = null;
    this.onlyNotApproved = null;
    this.pearlSearch = null;
    this.SearchForm.reset();
    this.loadPearls(this.pearlSearch);
  }
  onPageChange(event){
    console.log(event);
  }

  onCloseClick() {
    this.currentPane = 'center';
  }


  personSubmittedHandler($event: any) {
    console.log(' Person  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';
  }

  relateSubmittedHandler($event: any) {
    console.log(' Relate  submitted '+ JSON.stringify($event));
    this.loadPearls(this.pearlSearch);
    this.currentPane = 'center';
  }

  onClickDelete(p: Pearl) {
    const dRef = this.dialog.open(PearlDialogComponent, {
      width: '55%',
      height: '25%',
      data: {pearl: p}
    });

    this.subscriptions.push( dRef.afterClosed()
    .subscribe( async (result: Pearl) => {
      console.log('Selected result is ' + JSON.stringify(result));
      if (result) {
        const res = await firstValueFrom(this.arangoService.deletePearl(result));

          console.log('Save result  is: ' + JSON.stringify(res.content));
          this.emitterService.emitter.emit('Message','Pearl Deleted ' + result.title+ ' saved');
          if (this.user.preferences && this.user.preferences.autoRefresh) {
            this.loadPearls(this.pearlSearch);
          }
      }
      

    }))
  }

  myNNSelected($event: any, element: any) {
    console.log("myNN");
    this.submitChange(element,'My NN');
  }

  theirNNSelected($event: any, element: any) {
    console.log("theirNN");
    this.submitChange(element,'Their NN');
  }

  relationshipSelected($event: any, element: any) {
    console.log("rel");
    this.submitChange(element,'Relationships');
  }


  pearlModuleSelected($event: any, element: any) {
    console.log("pealrM");
    this.submitChange(element,'PearlModule');
  }

  submitChange(p:Pearl, where:string) {
    p.meta = updateMeta(p._key, p.meta);
    p.timeStamp = DateToYYMMDDhhmmss(new Date);
    this.subscriptions.push( this.arangoService.addPearl(p, null) // Cant change files this way TODO
    .subscribe( (res) => {
      console.log('Pearl change response ' + JSON.stringify(res));
      this.emitterService.emitter.emit('Message','Pearl '+where+ ' Updated ');
      if (this.user.preferences && this.user.preferences.autoRefresh) {
        this.loadPearls(this.pearlSearch);
      }
      
    }))
  }

  selectApproved(element: Pearl) {

    if (element.moderated) {
      element.moderated = {
        approved: !element.moderated.approved,
        whenRated: DateToYYMMDDhhmmss(),
        reason: 'Approved By Admin',
        meta: updateMeta('', element.moderated.meta)
      }
    } else {
      element.moderated = {
        approved: true,
        whenRated: DateToYYMMDDhhmmss(),
        reason: 'Approved By Admin',
        meta: updateMeta()
      }
    }
    console.log('Pearl Approved response ' + JSON.stringify(element));
    this.submitChange(element, 'Approved')
  }

  pearlSubmittedHandler($event: any) {
    console.log(' Pearl  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.loadPearls(this.pearlSearch);
    }
  }

   submitSearchForm() {
    console.log('Search Pearls form ' + JSON.stringify(this.SearchForm.value));
    if (this.SearchForm.valid) {
      console.log('Search Pearl form - valid');
      let dStart ='';
      let dEnd = '';

      if ( this.SearchForm.value.startDate)  {
        dStart = DateToYYMMDDhhmmss(this.SearchForm.value.startDate._d);
      }

      if (this.SearchForm.value.endDate) {
        dEnd = DateToYYMMDDhhmmss(this.SearchForm.value.endDate._d)
      }
      let approved = null;

      if (this.onlyNotApproved) {
        approved = false;
      } else if (this.onlyApproved) {
        approved = true;
      } // otherwise leave null

      this.pearlSearch = {
        search: this.SearchForm.value.content,
        myNN: this.SearchForm.value.myNN,
        theirNN: this.SearchForm.value.theirNN,
        relationships: this.SearchForm.value.relationship,
        pearlModule: this.SearchForm.value.pearlmodule,
        startDate: dStart,
        endDate: dEnd,
        random: false,
        approved: approved
      }

        this.loadPearls(this.pearlSearch);


    } else {
      console.log('Submit Pearl form - Not valid');
      alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.SearchForm)));

    }
  }


}
