import { Component, OnInit, OnDestroy, Input, Inject, NgModule } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { AuthService } from 'src/services/auth/auth.service';

import { Router } from '@angular/router';

import { forkJoin, Subscription } from 'rxjs';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { MatCardModule as MatCardModule } from '@angular/material/card';
import { EmailContactItem } from '../../../../../bo9-shared/models/contact/email.model';
import { SearchService } from 'src/services/search/search.service';
import { userIsAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';


@Component({
    selector: 'emailCheck-dialog-activity',
    templateUrl: './emailCheckDialog.component.html',
    styleUrls: ['./emailCheckDialog.component.scss'],
    standalone: false
})

export class EmailCheckDialogComponent implements OnInit  {
  disableBtn = false;
  allOk = false;
  emailErrors: string[] = [];
  user: Person;
  emailItem: EmailContactItem  = this.data.email;
  reset: false;
  subscriptions: Subscription[] = [];
  constructor(
    private dialogRef: MatDialogRef<EmailCheckDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: { email: EmailContactItem } ,

    private route: ActivatedRoute,
    private auth: AuthService,
    private arangoService: ArangoService,
    private searchServer: SearchService,
    private router: Router,



   ) {
      console.log ('Init activity ' + JSON.stringify(data));
      this.emailErrors= [];
      this.user = this.auth.getAuthUser();
      this.subscriptions.forEach(s => s.unsubscribe());
      this.subscriptions.push( this.arangoService.recordVisit({
        page: this.router.url, component: 'EmailCheckDialogComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})) 
     }

  ngOnInit() {
    this.user = this.auth.getAuthUser();
    if (!this.user) {
      console.error('User Not logged in ');
      alert('Permission Error'); 
      this.router.navigate(['login'] );
    }
    console.log ('Init activity init ' + JSON.stringify(this.data));
    this.emailItem = this.data.email;
    if ( this.emailItem.value.includes('gamil.com')) {
      this.emailItem.value.replace('gamil.com','gmail.com');
      this.emailErrors.push(`Email mistyped - updated ${this.emailItem.value}`);
    }
    this.subscriptions.push(  this.searchServer.search(this.emailItem.value)
    .subscribe({next: (matches: any) => {
      let reset = false;
      console.log('email matches '+ JSON.stringify(matches));
      if (matches.content && matches.content.persons  && matches.content.persons.length> 0) {
        this.emailErrors.push('Email '+this.emailItem.value+ ' is already in use');
        this.emailItem.value = this.emailItem.origValue; 
        reset = true;
      } else {
        this.allOk = true;
      }
      
    }}))
  }

  notAdmin() {
    return (!userIsAdmin(this.user));
  }

  close() {
    if (!userIsAdmin(this.user)) {
      this.emailItem.toValidate = true;
    }
    this.dialogRef.close({reset: this.reset, item: this.emailItem});
  }

  cancel() {
    this.emailItem.toValidate = null;
    this.emailItem.value = '';
    this.dialogRef.close({reset: true, item: null});
    
  }


  completed() {
    this.emailItem.toValidate = null;
    this.dialogRef.close({reset: false, item: this.emailItem});
    
  }
}



 
